import React, { useState, useEffect } from "react";
import sharedDatas from "../../../businessLogic/sharedDatas";
import { useNavigate } from "react-router-dom";

//oneFront SDK
import {
  useSnackbar,
  useAuth,
  useQuery,
  useMutation,
  useGet,
  useTranslation,
} from "@onefront/react-sdk";

import VaporPage from "@vapor/react-custom/VaporPage";

//Vapor Components
import Grid from "@vapor/react-material/Grid";
import Stack from "@vapor/react-material/Stack";
import IconButton from "@vapor/react-material/IconButton";
import Modal from "@vapor/react-material/Modal";
import Box from "@vapor/react-material/Box";
import Button from "@vapor/react-material/Button";
import Divider from "@vapor/react-material/Divider";
import TextField from "@vapor/react-material/TextField";
import FormGroup from "@vapor/react-material/FormGroup";
import FormControlLabel from "@vapor/react-material/FormControlLabel";
import Checkbox from "@vapor/react-material/Checkbox";
import Autocomplete from "@vapor/react-material/Autocomplete";
import Table from "@vapor/react-material/Table";
import TableBody from "@vapor/react-material/TableBody";
import TableCell from "@vapor/react-material/TableCell";
import TableContainer from "@vapor/react-material/TableContainer";
import TableHead from "@vapor/react-material/TableHead";
import TableRow from "@vapor/react-material/TableRow";
import Switch from "@vapor/react-material/Switch";

import ExtendedTypography from "@vapor/react-extended/ExtendedTypography";
import ExtendedTabs from "@vapor/react-extended/ExtendedTabs";
import ExtendedTab from "@vapor/react-extended/ExtendedTab";

import {
  LicenseInfo,
  DataGridPro,
  GridToolbar,
  itIT,
} from "@mui/x-data-grid-pro";

//Icons
import { ArrowForward as DetailIcon } from "@mui/icons-material";

//Custom components
import { Loading } from "../../../components/Loading";
import { NotAuth } from "../../../components/NotAuth";
import { NoData } from "../../../components/NoData";

//Constants, Api and Data Models
import {
  bsaSettingsUser,
  bsaSettingsPermission,
  pathImpUtenteHome,
} from "../../../businessLogic/constants";
import {
  userPermissionModel,
  userModel,
  baseTenantTipoModel,
  baseSecurityAreaModel,
} from "../../../businessLogic/models";
import {
  query_user_permission,
  query_users_byTenantId,
  query_base_tipo_tenant,
  query_base_security_area,
  query_user_permission_byUser,
  query_user_permission_byUserCount,
  query_fascicolo_user_count_byUserId,
} from "../../../businessLogic/query";
import {
  mutation_add_log,
  mutation_add_audit,
  mutation_add_lastActivity_byUserId,
  mutation_delete_lastActivity,
  mutation_update_user_resetRespAntiRic,
  mutation_update_user,
  mutation_update_user_tenant,
  mutation_add_user,
  mutation_add_user_tenant,
  mutation_add_user_permission,
  mutation_update_user_permission,
  mutation_update_user_permission_allRead,
  mutation_update_user_permission_allCreate,
  mutation_update_user_permission_allUpdate,
  mutation_update_user_permission_allDelete,
} from "../../../businessLogic/mutation";

//Style
import { modalStyleLarge } from "../../../businessLogic/styles";

//Other components
import { v4 as uuidv4 } from "uuid";
import postal from "postal";

export const UtenteHome = ({ basePath }) => {
  const tenantId = sharedDatas.getPropertyByName("tenantId");
  const aziendaId = sharedDatas.getPropertyByName("aziendaId");
  const loggedUserId = sharedDatas.getPropertyByName("userId");
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  //Query
  const apiUserPermissions = useQuery(
    query_user_permission,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiUsersByTenantId = useQuery(
    query_users_byTenantId,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseTenantTipo = useQuery(
    query_base_tipo_tenant,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );

  const apiBaseSecurityArea = useQuery(
    query_base_security_area,
    {},
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiPermissionByUser = useQuery(
    query_user_permission_byUser,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiPermissionByUserCount = useQuery(
    query_user_permission_byUserCount,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiFascicoloByUserCount = useQuery(
    query_fascicolo_user_count_byUserId,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );

  //Mutation
  const apiAddLog = useMutation(mutation_add_log, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddAudit = useMutation(mutation_add_audit, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddLastActivity = useMutation(mutation_add_lastActivity_byUserId, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiDeleteLastActivity = useMutation(mutation_delete_lastActivity, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiResetRespAntiRic = useMutation(
    mutation_update_user_resetRespAntiRic,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiUpdateUser = useMutation(mutation_update_user, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiUpdateUserTenant = useMutation(mutation_update_user_tenant, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddUser = useMutation(mutation_add_user, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddUserTenant = useMutation(mutation_add_user_tenant, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });

  const apiAddUserPermission = useMutation(mutation_add_user_permission, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiUpdateUserPermission = useMutation(mutation_update_user_permission, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiUpdateUserPermissionAllRead = useMutation(
    mutation_update_user_permission_allRead,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiUpdateUserPermissionAllCreate = useMutation(
    mutation_update_user_permission_allCreate,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiUpdateUserPermissionAllUpdate = useMutation(
    mutation_update_user_permission_allUpdate,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiUpdateUserPermissionAllDelete = useMutation(
    mutation_update_user_permission_allDelete,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );

  const [backDropOpen, setBackDropOpen] = useState(true); //Loader utilizzato durante il caricamento
  const [isDataLoaded, setIsDataLoaded] = useState(false); //Indica lo stato di caricamento del result Api

  const [isTenantEnabled, setIsTenantEnabled] = useState(false);
  const [userPermissions, setUserPermissions] = useState(
    new userPermissionModel(
      0,
      aziendaId,
      bsaSettingsUser,
      false,
      false,
      false,
      false
    )
  );
  const [userPermissions2, setUserPermissions2] = useState(
    new userPermissionModel(
      0,
      aziendaId,
      bsaSettingsPermission,
      false,
      false,
      false,
      false
    )
  );

  const [baseTenantTipoList, setBaseTenantTipoList] = useState([]);

  const [utentiList, setUtentiList] = useState([]); //Contiene il result degli utenti
  const [dataColumns, setDataColumns] = useState([]); //Contiene le columns della DataGrid
  const [dataRows, setDataRows] = useState([]); //Contiene le rows della DataGrid

  const [utentiAvailableList, setUtentiAvailableList] = useState([]);
  const [dataColumns2, setDataColumns2] = useState([]); //Contiene le columns della DataGrid
  const [dataRows2, setDataRows2] = useState([]); //Contiene le rows della DataGrid

  //User Detail
  const [isAvailableUser, setIsAvailableUser] = useState(false);
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [idToPerform, setIdToPerform] = useState(0); //Contiene l'id del record da visualizzare/aggiornare
  const [selUser, setSelUser] = useState(
    new userModel(
      "",
      tenantId,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      undefined,
      false,
      "",
      false,
      false,
      false,
      null,
      ""
    )
  );
  const [selUserOriginal, setSelUserOriginal] = useState(
    new userModel(
      "",
      tenantId,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      undefined,
      false,
      "",
      false,
      false,
      false,
      null,
      ""
    )
  ); //Used for Audit
  const [chbVal, setChbVal] = useState({ isRespAntiRic: false });
  const [aziendeList, setAziendeList] = useState(
    sharedDatas.getPropertyByName("aziendaModelList")
  );
  const [selAziendaId, setSelAziendaId] = useState(aziendaId);
  const [hasUserFascicoli, setHasUserFascicoli] = useState(false);

  //TS Digital Api
  const [accessToken, setAccessToken] = useState(useAuth().token);
  const tsApiUsers = useGet(
    "aml:user-read-agyo:api://?itemId=" +
      tenantId +
      "&pagination.pageNumber=0&pagination.itemsPerPage=10&unpaged=false",
    { lazy: true }
  );

  //Permessi
  const [bsaList, setBsaList] = useState([]); //Contiene il result delle base security areas
  const [permessiList, setPermessiList] = useState([]); //Contiene il result dei permessi
  const [isPermsLoaded, setIsPermsLoaded] = useState(false);
  const [isPermsAllRead, setIsPermsAllRead] = useState(false);
  const [isPermsAllCreate, setIsPermsAllCreate] = useState(false);
  const [isPermsAllUpdate, setIsPermsAllUpdate] = useState(false);
  const [isPermsAllDelete, setIsPermsAllDelete] = useState(false);

  useEffect(() => {
    loadDatas();
  }, [isDataLoaded]);
  async function loadDatas() {
    try {
      if (
        sharedDatas.getPropertyByName("tenantId") === "" ||
        sharedDatas.getPropertyByName("tenantModel") === null ||
        sharedDatas.getPropertyByName("aziendaId") === 0 ||
        sharedDatas.getPropertyByName("aziendaModelList") === null ||
        sharedDatas.getPropertyByName("userId") === "" ||
        sharedDatas.getPropertyByName("userModel") === null
      ) {
        navigate("/");
      } else {
        setBackDropOpen(true);

        if (!isDataLoaded) {
          //Last Activity
          await apiDeleteLastActivity.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            userId: loggedUserId,
          });
          await apiAddLastActivity.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            path: pathImpUtenteHome,
            entityId: "",
            entityName: "",
          });

          /**************************************/
          //La lista dei soggetti obbligati è sharata con la topBar, che contiene anche una voce "Gestisci soggetti obbligati"
          //In questo contesto la voce di gestione non ci serve, pertanto la elimino
          if (aziendeList.length > 0) {
            let newAziendeList = [];
            for (let i = 0; i < aziendeList.length; i++) {
              const aziendaModel = aziendeList[i];
              if (aziendaModel.id !== 0) {
                newAziendeList.push(aziendaModel);
              }
            }
            setAziendeList(newAziendeList);
          }

          /**************************************/
          //Carico le informazioni relative al Tenant
          const tenantInfos = sharedDatas.getPropertyByName("aziendaModelList");
          const isEnabled =
            !tenantInfos.isReadOnly &&
            !tenantInfos.isDisabled &&
            !tenantInfos.isDeleted;
          setIsTenantEnabled(isEnabled);

          /**************************************/
          //Carico i permessi relativi all'utente
          const userPerms = sharedDatas
            .getPropertyByName("userPermissions")
            .filter((x) => x.area === bsaSettingsUser)[0];
          setUserPermissions(userPerms);

          /**************************************/
          //Carico i permessi relativi all'utente
          setUserPermissions2(
            sharedDatas
              .getPropertyByName("userPermissions")
              .filter((x) => x.area === bsaSettingsPermission)[0]
          );

          /**************************************/
          //Carico la tabella di base
          const tbTenantTypeResponse = await apiBaseTenantTipo.fetch();
          const tbTenantTypeItems = [];
          tbTenantTypeResponse.data.data.base_tipo_tenant.map((item) =>
            tbTenantTypeItems.push(
              new baseTenantTipoModel(item.Id, item.Key, item.Value, item.Order)
            )
          );
          setBaseTenantTipoList(tbTenantTypeItems);

          /**************************************/
          //Carico le Base Security Areas
          const bsaResponse = await apiBaseSecurityArea.fetch();
          const bsaItems = [];
          bsaResponse.data.data.base_security_area.map((item) =>
            bsaItems.push(
              new baseSecurityAreaModel(
                item.Id,
                item.Key,
                item.Value,
                item.Description,
                item.Order
              )
            )
          );
          setBsaList(bsaItems);

          /**************************************/
          //Carico gli utenti
          const usersByTenantResponse = await apiUsersByTenantId.fetch({
            tenantId: tenantId,
          });
          const usersItems = [];

          for (
            let i = 0;
            i < usersByTenantResponse.data.data.user_tenant.length;
            i++
          ) {
            const item = usersByTenantResponse.data.data.user_tenant[i];
            const itemModel = new userModel(
              item.UserId,
              item.TenantId,
              item.user.Username,
              item.user.Email,
              item.user.Name,
              item.user.Surname,
              item.user.Name + " " + item.user.Surname,
              item.Type,
              item.user.Locale,
              item.TenantTipoId,
              item.user.IsRespAntiRic,
              item.base_tipo_tenant.Value,
              item.user.IsActive,
              item.user.IsDeleted,
              item.user.IsBanned,
              item.user.CreatedOn,
              item.user.Cf
            );
            usersItems.push(itemModel);
          }
          setUtentiList(usersItems);
          setDataColumns([
            { field: "userId", headerName: "userId", flex: 0.2 },
            {
              field: "email",
              headerName: t("impostazioni.utenti.form.email"),
              flex: 1,
              editable: false,
            },
            {
              field: "name",
              headerName: t("impostazioni.utenti.form.name"),
              flex: 0.2,
              editable: false,
            },
            {
              field: "surname",
              headerName: t("impostazioni.utenti.form.surname"),
              flex: 0.2,
              editable: false,
            },
            {
              field: "type",
              headerName: t("impostazioni.utenti.form.role"),
              flex: 0.2,
              editable: false,
            },
            {
              field: "tenantTipoS",
              headerName: "Tipologia",
              flex: 0.3,
              editable: false,
            },
            {
              field: "isRespAntiRic",
              headerName: "Responsabile",
              type: "boolean",
              flex: 0.2,
              editable: false,
            },
            {
              field: "actions",
              headerName: "",
              flex: 0.2,
              filterable: false,
              renderCell: (params) =>
                renderDetailsButton(
                  isEnabled,
                  userPerms,
                  params.row,
                  usersItems,
                  bsaItems
                ),
            },
          ]);
          setDataRows(usersItems);

          /**************************************/
          //Carico gli utenti TS Digital
          setDataColumns2([
            { field: "userId", headerName: "userId", flex: 0.2 },
            {
              field: "email",
              headerName: t("impostazioni.utenti.form.email"),
              flex: 1,
              editable: false,
            },
            {
              field: "name",
              headerName: t("impostazioni.utenti.form.name"),
              flex: 0.2,
              editable: false,
            },
            {
              field: "surname",
              headerName: t("impostazioni.utenti.form.surname"),
              flex: 0.2,
              editable: false,
            },
            {
              field: "type",
              headerName: t("impostazioni.utenti.form.role"),
              flex: 0.2,
              editable: false,
            },
            {
              field: "tenantTipoS",
              headerName: "Tipologia",
              flex: 0.3,
              editable: false,
            },
            {
              field: "isRespAntiRic",
              headerName: "Responsabile",
              type: "boolean",
              flex: 0.2,
              editable: false,
            },
            {
              field: "isActive",
              headerName: "Attivo",
              type: "boolean",
              flex: 0.2,
              editable: false,
            },
            {
              field: "isDeleted",
              headerName: "Eliminato",
              type: "boolean",
              flex: 0.2,
              editable: false,
            },
            {
              field: "isBanned",
              headerName: "Bannato",
              type: "boolean",
              flex: 0.2,
              editable: false,
            },
            {
              field: "actions",
              headerName: "",
              flex: 0.2,
              filterable: false,
              renderCell: (params) =>
                renderDetailsButtonAv(isEnabled, userPerms, params.row),
            },
          ]);
          const usersTSResponse = await tsApiUsers.fetch({
            headers: {
              Authorization: "Bearer " + accessToken,
              "X-App-Name": "TS420",
              "X-App-Version": "1.0.0",
              "X-Request-ID": uuidv4(),
              "X-Correlation-ID": uuidv4(),
              "Content-Type": "application/json",
              "X-Item-ID": tenantId,
              "X-User-ID": loggedUserId,
            },
          });

          const usersTSItems = [];
          const users = usersTSResponse.data.users;

          const tenantModel = sharedDatas.getPropertyByName("tenantModel");
          const taxId = tenantModel.identifier;

          for (let i = 0; i < users.length; i++) {
            const user = users[i];
            const roles = user.roles;
            const profile = user.profile;
            const status = user.status;
            const isEligible =
              status.active && !status.deleted && !status.banned;
            let userRole = "";
            let canBeSelected = false;

            //Il profile.tsUserCode è il nostro identifier legato allo user, pertanto se non è valorizzato lo escludiamo a prescindere
            if (profile.tsUserCode !== null) {
              for (let role of roles) {
                if (
                  role.resourceId === taxId &&
                  userRole === "" &&
                  canBeSelected === false
                ) {
                  canBeSelected =
                    role.actionKey === "OWNER" ||
                    (role.actionKey === "ADMIN" && role.appId === "AML");
                  if (canBeSelected) {
                    userRole = role.actionKey;
                  }
                }
              }

              //const mustBeAvoided = profile.type === "TECNICA" || profile.type === "PERSONALE";

              //if (profile.type !== "TECNICA" && profile.type !== "PERSONALE" && isEligible && usersItems.filter(x => x.userId === profile.tsUserCode).length <= 0) {
              //if (mustBeAvoided === false && profile.type !== "PERSONALE" && isEligible && usersItems.filter(x => x.userId === profile.tsUserCode).length <= 0) {
              if (
                canBeSelected &&
                isEligible &&
                usersItems.filter((x) => x.userId === profile.tsUserCode)
                  .length <= 0
              ) {
                //const itemModel = new userModel(profile.tsUserCode, tenantId, profile.email, profile.email, profile.firstName, profile.lastName, profile.firstName + " " + profile.lastName, profile.type, profile.language, 1, false, tbTenantTypeItems.filter(btt => btt.id == 1)[0].value, status.active, status.deleted, status.banned, status.createdAt, "");
                const itemModel = new userModel(
                  profile.tsUserCode,
                  tenantId,
                  profile.email,
                  profile.email,
                  profile.firstName,
                  profile.lastName,
                  profile.firstName + " " + profile.lastName,
                  userRole,
                  profile.language,
                  1,
                  false,
                  tbTenantTypeItems.filter((btt) => btt.id == 1)[0].value,
                  status.active,
                  status.deleted,
                  status.banned,
                  status.createdAt,
                  ""
                );
                usersTSItems.push(itemModel);
              }
            }
          }
          setUtentiAvailableList(usersTSItems);
          setDataRows2(usersTSItems);
        }
      }
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "UtenteHome",
        method: "loadDatas",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
      setIsDataLoaded(true);
    }
  }

  const loadPermessi = async (userId, aziendaId, users, securityList) => {
    try {
      //Verifico se l'utente corrente ha i permessi per poter gestire i permessi per l'azienda selezionata
      const userRole = sharedDatas.getPropertyByName("userModel").type;      
      if (userRole !== "OWNER") {
        const userPermsResponse = await apiUserPermissions.fetch({
          userId: loggedUserId,
          aziendaId: aziendaId,
          area: bsaSettingsPermission,
        });
        const userPerms =
          userPermsResponse.data.data.user_permission.length > 0
            ? userPermsResponse.data.data.user_permission[0]
            : null;
        if (userPerms !== null && userPerms.CanRead) {
          setUserPermissions2(
            new userPermissionModel(
              0,
              aziendaId,
              bsaSettingsPermission,
              userPerms.CanRead,
              userPerms.CanCreate,
              userPerms.CanUpdate,
              userPerms.CanDelete
            )
          );
        }
      } else {
        setUserPermissions2(
          new userPermissionModel(
            0,
            aziendaId,
            bsaSettingsPermission,
            true,
            true,
            true,
            true
          )
        );
      }

      //Verifico se per l'utente selezionato esistono i permessi
      const permsCountResponse = await apiPermissionByUserCount.fetch({
        userId: userId,
        aziendaId: aziendaId,
      });
      const hasUserPermissions =
        permsCountResponse.data.data.user_permission_aggregate.aggregate.count >
        0;

      //Se per l'utente esistono i permessi li recupero, altrimenti glieli imposto di default
      if (hasUserPermissions) {
        const permsResponse = await apiPermissionByUser.fetch({
          userId: userId,
          aziendaId: aziendaId,
        });
        const arrItems = [];
        permsResponse.data.data.user_permission.map((item) =>
          arrItems.push(
            new userPermissionModel(
              item.Id,
              item.AziendaId,
              item.Area,
              item.CanRead,
              item.CanCreate,
              item.CanUpdate,
              item.CanDelete
            )
          )
        );
        setIsPermsLoaded(true);
        setPermessiList(arrItems);

        setIsPermsAllRead(
          arrItems.filter((x) => x.canRead === false).length <= 0
        );
        setIsPermsAllCreate(
          arrItems.filter((x) => x.canCreate === false).length <= 0
        );
        setIsPermsAllUpdate(
          arrItems.filter((x) => x.canUpdate === false).length <= 0
        );
        setIsPermsAllDelete(
          arrItems.filter((x) => x.canDelete === false).length <= 0
        );
      } else {
        let userRole2 = "";

        userRole2 = users.filter((item) => item.userId === userId)[0].type;

        //Se l'utente ha ruolo di Admin (L) di default ha tutti i permessi, altrimenti nessuno in quanto deve essere l'Admin a fornire i permessi
        //const canRead = true;
        const canRead = userRole2 === "OWNER";
        const canCreate = userRole2 === "OWNER";
        const canUpdate = userRole2 === "OWNER";
        const canDelete = userRole2 === "OWNER";

        let count = 0;
        for (let i = 0; i < securityList.length; i++) {
          const item = securityList[i];
          const addPermsResponse = await apiAddUserPermission.fetch({
            userId: userId,
            aziendaId: aziendaId,
            area: item.key,
            canRead: canRead,
            canCreate: canCreate,
            canUpdate: canUpdate,
            canDelete: canDelete,
          });

          //Aggiungo la Audit per tracciare l'azione
          const result = addPermsResponse.data.data.insert_user_permission_one;
          const newValues = new userPermissionModel(
            result.Id,
            result.AziendaId,
            result.Area,
            result.CanRead,
            result.CanCreate,
            result.CanUpdate,
            result.CanDelete
          );
          apiAddAudit.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            area: bsaSettingsPermission,
            operation: "Create",
            entityType: "user_permission",
            entityId: result.Id.toString(),
            oldValues: "",
            newValues: JSON.stringify(newValues),
          });

          count++;
          if (securityList.length === count) {
            await loadPermessi(userId, aziendaId, users);
          }
        }
      }
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "UtenteHome",
        method: "loadPermessi",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    }
  };

  //Grid Button Detail
  const renderDetailsButton = (
    isEnabled,
    userPerms,
    item,
    users,
    securityList
  ) => {
    return (
      <Stack direction="row" spacing={0}>
        {isEnabled && userPerms.canUpdate ? (
          <Stack direction="row" spacing={0}>
            <IconButton
              color="primary"
              onClick={(e) =>
                handleGridBtnDetailClick(e, item, users, securityList)
              }
            >
              <DetailIcon />
            </IconButton>
          </Stack>
        ) : null}
      </Stack>
    );
  };
  const renderDetailsButtonAv = (isEnabled, userPerms, item) => {
    return (
      <Stack direction="row" spacing={0}>
        {isEnabled && userPerms.canCreate ? (
          <IconButton
            color="primary"
            onClick={(e) => handleGridBtnDetailAVClick(e, item)}
          >
            <DetailIcon />
          </IconButton>
        ) : null}
      </Stack>
    );
  };
  const handleGridBtnDetailClick = (event, item, users, securityList) => {
    event.stopPropagation();
    setIsAvailableUser(false);
    showUserDetail(item, users, securityList);
  };
  const handleGridBtnDetailAVClick = (event, item) => {
    event.stopPropagation();
    setIsAvailableUser(true);
    showUserDetailAv(item);
  };
  const showUserDetail = async (item, users, securityList) => {
    //Verifico se ci sono fascicoli collegati a questo user    
    const fascicoliCountResponse = await apiFascicoloByUserCount.fetch({
      tenantId: tenantId,
      aziendaId: aziendaId,
      userId: item.userId,
    });
    const fascicoliCount =
      fascicoliCountResponse.data.data.fascicolo_aggregate.aggregate.count;
    setHasUserFascicoli(fascicoliCount > 0);

    setSelUser(item);
    setSelUserOriginal(item);
    setChbVal({ isRespAntiRic: item.isRespAntiRic });
    setIdToPerform(item.userId);
    await loadPermessi(item.userId, selAziendaId, users, securityList);
    setShowModalDetail(true);
  };
  const showUserDetailAv = async (item) => {
    //Verifico se ci sono fascicoli collegati a questo user
    const fascicoliCountResponse = await apiFascicoloByUserCount.fetch({
      tenantId: tenantId,
      aziendaId: aziendaId,
      userId: item.userId,
    });
    const fascicoliCount =
      fascicoliCountResponse.data.data.fascicolo_aggregate.aggregate.count;
    setHasUserFascicoli(fascicoliCount > 0);

    setSelUser(item);
    setSelUserOriginal(item);
    setChbVal({ isRespAntiRic: item.isRespAntiRic });
    setIdToPerform(item.userId);
    setShowModalDetail(true);
  };

  //Tabs
  const [selectedTab, setSelectedTab] = useState("tabUtenti");
  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
    setIsAvailableUser(newValue === "tabDisponibili");
  };

  const [selectedDetailTab, setSelectedDetailTab] = useState("tabGeneral");
  const handleChangeDetailTab = (event, newValue) => {
    setSelectedDetailTab(newValue);
  };

  //Actions
  const handleChange = async (event, value) => {
    const { id } = event.target;

    if (id.indexOf("ddlTipoTenant-option") !== -1 || id === "ddlTipoTenant") {
      selUser.tenantTipoId = value.id;
    } else if (id === "chkRespAnt") {
      selUser.isRespAntiRic = event.target.checked;
      setChbVal({ isRespAntiRic: event.target.checked });
    } else if (id.indexOf("ddlAzienda-option") !== -1 || id === "ddlAzienda") {
      setSelAziendaId(value.id);
      setIsPermsLoaded(false);
      setPermessiList([]);
      await loadPermessi(selUser.userId, value.id, utentiList, bsaList);
    } else if (id === "txtCf") {
      selUser.cf = event.target.value;
    }

    setSelUser(selUser);
  };
  const handleSwitchChange = async (event, id, type) => {
    try {
      const checked = event.currentTarget.checked;
      const item = permessiList.filter((item) => item.id === id)[0];

      let canRead = type === "read" ? checked : item.canRead;
      let canCreate = type === "create" ? checked : item.canCreate;
      let canUpdate = type === "update" ? checked : item.canUpdate;
      let canDelete = type === "delete" ? checked : item.canDelete;

      switch (type) {
        case "read": {
          if (checked) {
            canRead = true;
          } else {
            canCreate = false;
            canUpdate = false;
            canDelete = false;
          }
          break;
        }
        case "create": {
          if (checked) {
            canRead = true;
            canCreate = true;
            canUpdate = true;
          } else {
            canUpdate = false;
            canDelete = false;
            canUpdate = false;
          }
          break;
        }
        case "delete": {
          if (checked) {
            canRead = true;
            canCreate = true;
            canUpdate = true;
            canDelete = true;
          }
          break;
        }
      }

      const updatePermsResponse = await apiUpdateUserPermission.fetch({
        id: id,
        userId: selUser.userId,
        aziendaId: selAziendaId,
        canRead: canRead,
        canCreate: canCreate,
        canUpdate: canUpdate,
        canDelete: canDelete,
      });
      if (updatePermsResponse.data.errors !== undefined) {
        enqueueSnackbar(t("error.generale"), { variant: "error" });
      } else {
        //Aggiungo la Audit per tracciare l'azione
        const result =
          updatePermsResponse.data.data.update_user_permission.returning[0];
        //item.area = result.Area; //Solo per naming, esempio: invece di "Azienda" voglio salvare nel db il valore originale che è "tenant"
        const newValues = new userPermissionModel(
          result.Id,
          result.AziendaId,
          result.Area,
          result.CanRead,
          result.CanCreate,
          result.CanUpdate,
          result.CanDelete
        );
        apiAddAudit.fetch({
          tenantId: tenantId,
          aziendaId: aziendaId,
          area: bsaSettingsPermission,
          operation: "Update",
          entityType: "user_permission",
          entityId: id.toString(),
          oldValues: JSON.stringify(item),
          newValues: JSON.stringify(newValues),
        });

        await loadPermessi(selUser.userId, selAziendaId);
        enqueueSnackbar(t("message.success"), { variant: "success" });
      }
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "UtenteHome",
        method: "handleSwitchChange",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    }
  };
  const handleSwitchChangeAll = async (event, type) => {
    try {
      const checked = event.currentTarget.checked;

      switch (type) {
        case "read": {
          if (checked) {
            const updatePermsReadResponse =
              await apiUpdateUserPermissionAllRead.fetch({
                userId: selUser.userId,
                aziendaId: selAziendaId,
                canRead: true,
              });
          } else {
            const updatePermsReadResponse =
              await apiUpdateUserPermissionAllRead.fetch({
                userId: selUser.userId,
                aziendaId: selAziendaId,
                canRead: false,
              });
            const updatePermsCreateResponse =
              await apiUpdateUserPermissionAllCreate.fetch({
                userId: selUser.userId,
                aziendaId: selAziendaId,
                canCreate: false,
              });
            const updatePermsUpdateResponse =
              await apiUpdateUserPermissionAllUpdate.fetch({
                userId: selUser.userId,
                aziendaId: selAziendaId,
                canUpdate: false,
              });
            const updatePermsDeleteResponse =
              await apiUpdateUserPermissionAllDelete.fetch({
                userId: selUser.userId,
                aziendaId: selAziendaId,
                canDelete: false,
              });
          }
          enqueueSnackbar(t("message.success"), { variant: "success" });
          break;
        }
        case "create": {
          if (checked) {
            const updatePermsReadResponse =
              await apiUpdateUserPermissionAllRead.fetch({
                userId: selUser.userId,
                aziendaId: selAziendaId,
                canRead: true,
              });
            const updatePermsCreateResponse =
              await apiUpdateUserPermissionAllCreate.fetch({
                userId: selUser.userId,
                aziendaId: selAziendaId,
                canCreate: true,
              });
            const updatePermsUpdateResponse =
              await apiUpdateUserPermissionAllUpdate.fetch({
                userId: selUser.userId,
                aziendaId: selAziendaId,
                canUpdate: true,
              });
          } else {
            const updatePermsCreateResponse =
              await apiUpdateUserPermissionAllCreate.fetch({
                userId: selUser.userId,
                aziendaId: selAziendaId,
                canCreate: false,
              });
            const updatePermsUpdateResponse =
              await apiUpdateUserPermissionAllUpdate.fetch({
                userId: selUser.userId,
                aziendaId: selAziendaId,
                canUpdate: false,
              });
            const updatePermsDeleteResponse =
              await apiUpdateUserPermissionAllDelete.fetch({
                userId: selUser.userId,
                aziendaId: selAziendaId,
                canDelete: false,
              });
          }
          enqueueSnackbar(t("message.success"), { variant: "success" });
          break;
        }
        // case "update": {
        //   if (checked) {
        //     const updatePermsReadResponse = await apiUpdateUserPermissionAllRead.fetch({ userId: selUser.userId, aziendaId: selAziendaId, canRead: true });
        //     const updatePermsCreateResponse = await apiUpdateUserPermissionAllCreate.fetch({ userId: selUser.userId, aziendaId: selAziendaId, canCreate: true });
        //     const updatePermsUpdateResponse = await apiUpdateUserPermissionAllUpdate.fetch({ userId: selUser.userId, aziendaId: selAziendaId, canUpdate: true });
        //   }
        //   else {
        //     const updatePermsUpdateResponse = await apiUpdateUserPermissionAllUpdate.fetch({ userId: selUser.userId, aziendaId: selAziendaId, canUpdate: false });
        //     const updatePermsDeleteResponse = await apiUpdateUserPermissionAllDelete.fetch({ userId: selUser.userId, aziendaId: selAziendaId, canDelete: false });
        //   }
        //   enqueueSnackbar(t("message.success"), { variant: "success" });
        //   break;
        // }
        case "delete": {
          if (checked) {
            const updatePermsReadResponse =
              await apiUpdateUserPermissionAllRead.fetch({
                userId: selUser.userId,
                aziendaId: selAziendaId,
                canRead: true,
              });
            const updatePermsCreateResponse =
              await apiUpdateUserPermissionAllCreate.fetch({
                userId: selUser.userId,
                aziendaId: selAziendaId,
                canCreate: true,
              });
            const updatePermsUpdateResponse =
              await apiUpdateUserPermissionAllUpdate.fetch({
                userId: selUser.userId,
                aziendaId: selAziendaId,
                canUpdate: true,
              });
            const updatePermsDeleteResponse =
              await apiUpdateUserPermissionAllDelete.fetch({
                userId: selUser.userId,
                aziendaId: selAziendaId,
                canDelete: true,
              });
          } else {
            const updatePermsDeleteResponse =
              await apiUpdateUserPermissionAllDelete.fetch({
                userId: selUser.userId,
                aziendaId: selAziendaId,
                canDelete: false,
              });
          }
          enqueueSnackbar(t("message.success"), { variant: "success" });
          break;
        }
        default: {
          break;
        }
      }

      await loadPermessi(selUser.userId, selAziendaId);
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "UtenteHome",
        method: "handleSwitchChangeAll",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    }
  };

  //Modal
  const handleModalClose = () => {
    setChbVal({ isRespAntiRic: false });
    setSelUser(
      new userModel(
        "",
        tenantId,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        undefined,
        false,
        ""
      )
    );
    setSelUserOriginal(
      new userModel(
        "",
        tenantId,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        undefined,
        false,
        ""
      )
    );
    setIdToPerform("");
    setSelectedDetailTab("tabGeneral");
    setShowModalDetail(false);
  };
  const handleBtnUpdateClick = async () => {
    try {
      //Prima setto il flag isRespAntiRic a false per tutti gli utenti, solo se ho il chbVal flaggato
      if (chbVal.isRespAntiRic) {
        const resetRespResponse = await apiResetRespAntiRic.fetch({
          tenantId: tenantId,
        });
        if (resetRespResponse.data.errors !== undefined) {
          enqueueSnackbar(t("error.generale"), { variant: "error" });
        } else {
          const result = resetRespResponse.data.data.update_user.affected_rows;

          //Se sto importando un utente da Agyo
          if (isAvailableUser) {
            const addUserResponse = await apiAddUser.fetch({
              userId: idToPerform,
              username: selUser.email,
              email: selUser.email,
              name: selUser.name,
              surname: selUser.surname,
              locale: selUser.locale,
              isRespAntiRic: selUser.isRespAntiRic,
              isActive: selUser.isActive,
              isDeleted: selUser.isDeleted,
              isBanned: selUser.isBanned,
              createdOn: selUser.createdOn,
              cf: selUser.cf,
            });
            if (addUserResponse.data.errors !== undefined) {
              enqueueSnackbar(t("error.generale"), { variant: "error" });
            } else {
              const result2 = addUserResponse.data.data.insert_user_one;

              //Aggiungo lo user_tenant
              const addUserTenantResponse = await apiAddUserTenant.fetch({
                tenantId: tenantId,
                userId: idToPerform,
                type: selUser.type,
                tenantTipoId: selUser.tenantTipoId,
              });
              const resultUT =
                addUserTenantResponse.data.data.insert_user_tenant_one;

              let userResultModel = new userModel(
                result2.UserId,
                resultUT.TenantId,
                result2.Username,
                result2.Email,
                result2.Name,
                result2.Surname,
                result2.Name + " " + result2.Surname,
                resultUT.Type,
                result2.Locale,
                resultUT.TenantTipoId,
                result2.IsRespAntiRic,
                resultUT.base_tipo_tenant.Value,
                result2.IsActive,
                result2.IsDeleted,
                result2.IsBanned,
                result2.CreatedOn,
                result2.Cf
              );

              //Aggiungo la Audit per tracciare l'azione
              apiAddAudit.fetch({
                tenantId: tenantId,
                aziendaId: aziendaId,
                area: bsaSettingsUser,
                operation: "Create",
                entityType: "user",
                entityId: selUser.userId.toString(),
                oldValues: "",
                newValues: JSON.stringify(userResultModel),
              });

              enqueueSnackbar(t("message.success"), { variant: "success" });
            }
          } else {
            //Aggiorno lo user_tenant
            const updateUserTenantResponse = await apiUpdateUserTenant.fetch({
              tenantId: tenantId,
              userId: idToPerform,
              tenantTipoId: selUser.tenantTipoId,
            });
            const resultUT =
              updateUserTenantResponse.data.data.update_user_tenant
                .returning[0];

            const updateUserResponse = await apiUpdateUser.fetch({
              tenantId: tenantId,
              userId: idToPerform,
              name: selUser.name,
              surname: selUser.surname,
              isRespAntiRic: selUser.isRespAntiRic,
              isActive: selUser.isActive,
              isDeleted: selUser.isDeleted,
              isBanned: selUser.isBanned,
              createdOn: selUser.createdOn,
              cf: selUser.cf,
            });
            if (updateUserResponse.data.errors !== undefined) {
              enqueueSnackbar(t("error.generale"), { variant: "error" });
            } else {
              const result2 =
                updateUserResponse.data.data.update_user.returning[0];
              let userResultModel = new userModel(
                result2.UserId,
                resultUT.TenantId,
                result2.Username,
                result2.Email,
                result2.Name,
                result2.Surname,
                result2.Name + " " + result2.Surname,
                resultUT.Type,
                result2.Locale,
                resultUT.TenantTipoId,
                result2.IsRespAntiRic,
                resultUT.base_tipo_tenant.Value,
                result2.IsActive,
                result2.IsDeleted,
                result2.IsBanned,
                result2.CreatedOn,
                result2.Cf
              );

              //Aggiungo la Audit per tracciare l'azione
              apiAddAudit.fetch({
                tenantId: tenantId,
                aziendaId: aziendaId,
                area: bsaSettingsUser,
                operation: "Update",
                entityType: "user",
                entityId: selUser.userId.toString(),
                oldValues: JSON.stringify(selUserOriginal),
                newValues: JSON.stringify(userResultModel),
              });

              //Se aggiorno l'utente corrente invio un pub message
              if (userResultModel.userId === loggedUserId) {
                sharedDatas.setPropertyValue("userModel", userResultModel);

                postal.publish({
                  channel: "user",
                  topic: "user.updatedLoggedUser",
                  data: {
                    user: userResultModel,
                  },
                });
              }

              enqueueSnackbar(t("message.success"), { variant: "success" });
            }
          }
        }
      } else {
        //Se sto importando un utente da Agyo
        if (isAvailableUser) {
          const addUserResponse = await apiAddUser.fetch({
            userId: idToPerform,
            username: selUser.email,
            email: selUser.email,
            name: selUser.name,
            surname: selUser.surname,
            locale: selUser.locale,
            isRespAntiRic: selUser.isRespAntiRic,
            isActive: selUser.isActive,
            isDeleted: selUser.isDeleted,
            isBanned: selUser.isBanned,
            createdOn: selUser.createdOn,
            cf: selUser.cf,
          });
          if (addUserResponse.data.errors !== undefined) {
            enqueueSnackbar(t("error.generale"), { variant: "error" });
          } else {
            const result2 = addUserResponse.data.data.insert_user_one;

            //Aggiungo lo user_tenant
            const addUserTenantResponse = await apiAddUserTenant.fetch({
              tenantId: tenantId,
              userId: idToPerform,
              type: selUser.type,
              tenantTipoId: selUser.tenantTipoId,
            });
            const resultUT =
              addUserTenantResponse.data.data.insert_user_tenant_one;

            let userResultModel = new userModel(
              result2.UserId,
              resultUT.TenantId,
              result2.Username,
              result2.Email,
              result2.Name,
              result2.Surname,
              result2.Name + " " + result2.Surname,
              resultUT.Type,
              result2.Locale,
              resultUT.TenantTipoId,
              result2.IsRespAntiRic,
              resultUT.base_tipo_tenant.Value,
              result2.IsActive,
              result2.IsDeleted,
              result2.IsBanned,
              result2.CreatedOn,
              result2.Cf
            );

            //Aggiungo la Audit per tracciare l'azione
            apiAddAudit.fetch({
              tenantId: tenantId,
              aziendaId: aziendaId,
              area: bsaSettingsUser,
              operation: "Create",
              entityType: "user",
              entityId: selUser.userId.toString(),
              oldValues: "",
              newValues: JSON.stringify(userResultModel),
            });

            enqueueSnackbar(t("message.success"), { variant: "success" });
          }
        } else {
          //Aggiorno lo user_tenant
          const updateUserTenantResponse = await apiUpdateUserTenant.fetch({
            tenantId: tenantId,
            userId: idToPerform,
            tenantTipoId: selUser.tenantTipoId,
          });
          const resultUT =
            updateUserTenantResponse.data.data.update_user_tenant.returning[0];

          const updateUserResponse = await apiUpdateUser.fetch({
            tenantId: tenantId,
            userId: idToPerform,
            name: selUser.name,
            surname: selUser.surname,
            isRespAntiRic: selUser.isRespAntiRic,
            isActive: selUser.isActive,
            isDeleted: selUser.isDeleted,
            isBanned: selUser.isBanned,
            createdOn: selUser.createdOn,
            cf: selUser.cf,
          });
          if (updateUserResponse.data.errors !== undefined) {
            enqueueSnackbar(t("error.generale"), { variant: "error" });
          } else {
            const result =
              updateUserResponse.data.data.update_user.returning[0];
            let userResultModel = new userModel(
              result.UserId,
              resultUT.TenantId,
              result.Username,
              result.Email,
              result.Name,
              result.Surname,
              result.Name + " " + result.Surname,
              resultUT.Type,
              result.Locale,
              resultUT.TenantTipoId,
              result.IsRespAntiRic,
              resultUT.base_tipo_tenant.Value,
              result.IsActive,
              result.IsDeleted,
              result.IsBanned,
              result.CreatedOn,
              result.Cf
            );

            //Aggiungo la Audit per tracciare l'azione
            apiAddAudit.fetch({
              tenantId: tenantId,
              aziendaId: aziendaId,
              area: bsaSettingsUser,
              operation: "Update",
              entityType: "user",
              entityId: selUser.userId.toString(),
              oldValues: JSON.stringify(selUserOriginal),
              newValues: JSON.stringify(userResultModel),
            });

            //Se aggiorno l'utente corrente invio un pub message
            if (userResultModel.userId === loggedUserId) {
              sharedDatas.setPropertyValue("userModel", userResultModel);

              postal.publish({
                channel: "user",
                topic: "user.updatedLoggedUser",
                data: {
                  user: userResultModel,
                },
              });
            }

            enqueueSnackbar(t("message.success"), { variant: "success" });
          }
        }
      }
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "UtenteHome",
        method: "handleBtnUpdateClick",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setChbVal({ isRespAntiRic: false });
      setSelUser(
        new userModel(
          "",
          tenantId,
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          undefined,
          false,
          "",
          false,
          false,
          false,
          null,
          ""
        )
      );
      setSelUserOriginal(
        new userModel(
          "",
          tenantId,
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          undefined,
          false,
          "",
          false,
          false,
          false,
          null,
          ""
        )
      );
      setIdToPerform("");
      setShowModalDetail(false);
      setIsDataLoaded(false);
    }
  };

  return (
    <VaporPage title={t("nav.utenti")}>
      <Loading open={backDropOpen} />

      {isDataLoaded && !userPermissions.canRead ? <NotAuth /> : null}

      <Modal
        key="addModal"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        open={showModalDetail}
        onClose={handleModalClose}
        onBackdropClick={handleModalClose}
      >
        <Box sx={modalStyleLarge}>
          <Stack
            direction="column"
            spacing={0}
            justifyContent="left"
            alignItems="left"
          >
            <ExtendedTypography
              variant="titleSmall"
              color="primary.main"
              sx={{ p: 2, pl: 3 }}
            >
              {!isAvailableUser ? "Modifica Utente" : "Abilita utente come"}
            </ExtendedTypography>
            <Divider orientation="horizontal" flexItem />
          </Stack>

          <ExtendedTabs
            variant="standard"
            size="small"
            value={selectedDetailTab}
            onChange={handleChangeDetailTab}
            sx={{ mb: 2 }}
          >
            <ExtendedTab value="tabGeneral" label="Informazioni" />
            {!isAvailableUser ? (
              <ExtendedTab value="tabPerms" label="Permessi" />
            ) : null}
          </ExtendedTabs>

          {selectedDetailTab === "tabGeneral" ? (
            <Stack
              direction="column"
              spacing={0}
              justifyContent="left"
              alignItems="left"
              sx={{ height: 270 }}
            >
              <Box sx={{ pl: 3, pr: 3 }}>
                {!apiBaseTenantTipo.loading && baseTenantTipoList.length > 0 ? (
                  <Autocomplete
                    id="ddlTipoTenant"
                    key={uuidv4()}
                    size="small"
                    fullWidth
                    options={baseTenantTipoList}
                    sx={{ width: "100%" }}
                    isOptionEqualToValue={(option, value) =>
                      selUser.tenantTipoId === undefined
                        ? option.id === value.id
                        : option.id === selUser.tenantTipoId
                    }
                    getOptionLabel={(option) => option.value}
                    defaultValue={
                      selUser.tenantTipoId !== undefined
                        ? baseTenantTipoList.filter(
                            (item) => item.id === selUser.tenantTipoId
                          )[0]
                        : null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tipologia Professionista"
                        required
                      />
                    )}
                    onChange={handleChange}
                    disabled={hasUserFascicoli}
                  />
                ) : null}

                <TextField
                  id="txtCf"
                  key={uuidv4()}
                  label="Codice fiscale"
                  size="small"
                  defaultValue={selUser.cf}
                  fullWidth
                  onChange={handleChange}
                  inputProps={{ autocomplete: "nope" }}
                  sx={{ mt: 2 }}
                />

                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="chkRespAnt"
                        checked={chbVal.isRespAntiRic}
                        onChange={handleChange}
                      />
                    }
                    label="Responsabile Antiriciclaggio"
                    sx={{ mt: 2 }}
                  />
                </FormGroup>
              </Box>
            </Stack>
          ) : null}

          {selectedDetailTab === "tabPerms" && !isAvailableUser ? (
            <Box sx={{ pl: 3, pr: 3, overflowY: "scroll", height: 500 }}>
              <Stack
                direction="column"
                spacing={2}
                divider={
                  <Divider
                    orientation="horizontal"
                    flexItem
                    variant="standard"
                  />
                }
              >
                <Autocomplete
                  id="ddlAzienda"
                  key={uuidv4()}
                  size="small"
                  fullWidth
                  options={aziendeList}
                  sx={{ width: "100%" }}
                  isOptionEqualToValue={(option, value) =>
                    selAziendaId === 0
                      ? option.id === value.id
                      : option.id === selAziendaId
                  }
                  getOptionLabel={(option) => option.denominazione}
                  defaultValue={
                    selAziendaId !== 0
                      ? aziendeList.filter(
                          (item) => item.id === selAziendaId
                        )[0]
                      : null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Soggetto obbligato"
                      required
                    />
                  )}
                  onChange={handleChange}
                />

                {isPermsLoaded && permessiList.length > 0 ? (
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left" width="40%">
                            {t("impostazioni.permessi.area")}
                          </TableCell>
                          <TableCell align="center" width="15%">
                            {t("impostazioni.permessi.lettura")}
                          </TableCell>
                          <TableCell align="center" width="30%">
                            {t("impostazioni.utente.cremod")}
                          </TableCell>
                          <TableCell align="center" width="15%">
                            {t("impostazioni.permessi.eliminazione")}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left" width="40%"></TableCell>
                          <TableCell align="center" width="15%">
                            <Switch
                              checked={isPermsAllRead}
                              onChange={(e) => handleSwitchChangeAll(e, "read")}
                              disabled={
                                !isTenantEnabled ||
                                !userPermissions2.canUpdate ||
                                selUser.type === "OWNER"
                              }
                            />
                          </TableCell>
                          <TableCell align="center" width="30%">
                            <Switch
                              checked={isPermsAllCreate}
                              onChange={(e) =>
                                handleSwitchChangeAll(e, "create")
                              }
                              disabled={
                                !isTenantEnabled ||
                                !userPermissions2.canUpdate ||
                                selUser.type === "OWNER"
                              }
                            />
                          </TableCell>
                          <TableCell align="center" width="15%">
                            <Switch
                              checked={isPermsAllDelete}
                              onChange={(e) =>
                                handleSwitchChangeAll(e, "delete")
                              }
                              disabled={
                                !isTenantEnabled ||
                                !userPermissions2.canUpdate ||
                                selUser.type === "OWNER"
                              }
                            />
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {permessiList.map((item) => (
                          <TableRow
                            key={uuidv4()}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                            hover
                          >
                            <TableCell align="left" width="40%">
                              {
                                bsaList.filter((x) => x.key === item.area)[0].value
                              }
                            </TableCell>
                            <TableCell align="center" width="15%">
                              <Switch
                                defaultChecked={item.canRead}
                                onChange={(e) =>
                                  handleSwitchChange(e, item.id, "read")
                                }
                                disabled={
                                  !isTenantEnabled ||
                                  !userPermissions2.canUpdate ||
                                  selUser.type === "OWNER"
                                }
                              />
                            </TableCell>
                            <TableCell align="center" width="30%">
                              <Switch
                                defaultChecked={item.canCreate}
                                onChange={(e) =>
                                  handleSwitchChange(e, item.id, "create")
                                }
                                disabled={
                                  !isTenantEnabled ||
                                  !userPermissions2.canUpdate ||
                                  selUser.type === "OWNER"
                                }
                              />
                            </TableCell>
                            <TableCell align="center" width="15%">
                              <Switch
                                defaultChecked={item.canDelete}
                                onChange={(e) =>
                                  handleSwitchChange(e, item.id, "delete")
                                }
                                disabled={
                                  !isTenantEnabled ||
                                  !userPermissions2.canUpdate ||
                                  selUser.type === "OWNER"
                                }
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : null}
              </Stack>
            </Box>
          ) : null}

          <Stack
            direction="row"
            spacing={1}
            sx={{
              p: 1,
              mt: 1,
              bgcolor: "#F2F5F8",              
              bottom: 0,
              width: "100%",
            }}
            justifyContent="right"
            alignItems="right"
          >
            {selectedDetailTab === "tabGeneral" ? (
              <Button
                variant="outlined"
                size="small"
                onClick={handleModalClose}
              >
                {t("actions.annulla")}
              </Button>
            ) : (
              <Button
                variant="contained"
                size="small"
                onClick={handleModalClose}
              >
                {t("actions.chiudi")}
              </Button>
            )}

            {userPermissions.canUpdate && selectedDetailTab !== "tabPerms" ? (
              <Button
                variant="contained"
                size="small"
                onClick={handleBtnUpdateClick}
              >
                {t("actions.salva")}
              </Button>
            ) : null}
          </Stack>
        </Box>
      </Modal>

      {isDataLoaded && userPermissions.canRead ? (
        <VaporPage.Section>
          <ExtendedTabs
            variant="standard"
            size="small"
            value={selectedTab}
            onChange={handleChangeTab}
            sx={{ mb: 2 }}
          >
            <ExtendedTab value="tabUtenti" label={t("users.attivi")} />
            <ExtendedTab
              value="tabDisponibili"
              label={t("users.disponibili")}
            />
          </ExtendedTabs>

          {selectedTab === "tabUtenti" ? (
            <Grid container>
              <Grid item xs={12} sx={{ width: "100%" }}>
                {dataRows.length > 0 ? (
                  <DataGridPro
                    rows={dataRows}
                    columns={dataColumns}
                    getRowId={(row) => row.userId}
                    slots={{ toolbar: GridToolbar }}
                    autoHeight={true}
                    pageSizeOptions={[25, 50, 100]}
                    localeText={
                      itIT.components.MuiDataGrid.defaultProps.localeText
                    }
                    pagination
                    disableRowSelectionOnClick
                    onRowClick={(item, e) =>
                      handleGridBtnDetailClick(e, item.row, utentiList, bsaList)
                    }
                    initialState={{
                      columns: {
                        columnVisibilityModel: {
                          userId: false,
                        },
                      },
                      pagination: {
                        paginationModel: {
                          pageSize: 25,
                        },
                      },
                    }}
                    slotProps={{
                      toolbar: { printOptions: { disableToolbarButton: true } },
                    }}
                  />
                ) : (
                  <NoData />
                )}
              </Grid>
            </Grid>
          ) : null}

          {selectedTab === "tabDisponibili" ? (
            <Grid container>
              <Grid item xs={12} sx={{ width: "100%" }}>
                {dataRows2.length > 0 ? (
                  <DataGridPro
                    rows={dataRows2}
                    columns={dataColumns2}
                    getRowId={(row) => row.userId}
                    slots={{ toolbar: GridToolbar }}
                    autoHeight={true}
                    pageSizeOptions={[25, 50, 100]}
                    localeText={
                      itIT.components.MuiDataGrid.defaultProps.localeText
                    }
                    pagination
                    disableRowSelectionOnClick
                    onRowClick={(item, e) =>
                      handleGridBtnDetailAVClick(e, item.row)
                    }
                    initialState={{
                      columns: {
                        columnVisibilityModel: {
                          userId: false,
                        },
                      },
                      pagination: {
                        paginationModel: {
                          pageSize: 25,
                        },
                      },
                    }}
                    slotProps={{
                      toolbar: { printOptions: { disableToolbarButton: true } },
                    }}
                  />
                ) : (
                  <NoData />
                )}
              </Grid>
            </Grid>
          ) : null}
        </VaporPage.Section>
      ) : null}
    </VaporPage>
  );
};
