import { NavContent } from "./NavContent";
import { PrestazioneHome } from "./PrestazioneHome";
import { pathPrestazioneHome, anchorOrigin, autoHideDuration, maxSnack } from "../../businessLogic/constants";
import { SnackbarProvider } from "notistack";

export const prestazione = () => [
  {
    target: "$ONE_LAYOUT_NAV_CONTENT",
    handler: {
      component: NavContent,
      props: {
        basePath: pathPrestazioneHome
      }
    }
  },
  {
    target: "$ONE_LAYOUT_ROUTE",
    handler: {
      exact: true,
      path: pathPrestazioneHome,
      element: <SnackbarProvider anchorOrigin={anchorOrigin} autoHideDuration={autoHideDuration} maxSnack={maxSnack}><PrestazioneHome basePath={pathPrestazioneHome} /></SnackbarProvider>
    }
  }
];
