import { NavContent } from "./NavContent";
import { AnagraficaHome } from "./AnagraficaHome";
import { AnagraficaImportDesktop } from "./AnagraficaImportDesktop";
import { pathAnagraficaHome, pathAnagraficaImportDesktop, anchorOrigin, autoHideDuration, maxSnack } from "../../businessLogic/constants";
import { SnackbarProvider } from "notistack";

export const anagrafica = () => [
    {
        target: "$ONE_LAYOUT_NAV_CONTENT",
        handler: {
            component: NavContent,
            props: {
                basePath: pathAnagraficaHome
            }
        }
    },
    {
        target: "$ONE_LAYOUT_ROUTE",
        handler: {
            exact: true,
            path: pathAnagraficaHome,
            element: <SnackbarProvider anchorOrigin={anchorOrigin} autoHideDuration={autoHideDuration} maxSnack={maxSnack}><AnagraficaHome basePath={pathAnagraficaHome} /></SnackbarProvider>
        }
    },
    {
        target: "$ONE_LAYOUT_ROUTE",
        handler: {
            exact: true,
            path: pathAnagraficaImportDesktop,
            element: <SnackbarProvider anchorOrigin={anchorOrigin} autoHideDuration={autoHideDuration} maxSnack={maxSnack}><AnagraficaImportDesktop basePath={pathAnagraficaHome} /></SnackbarProvider>
        }
    }
];
