import React from "react";
import { useTranslation } from "@onefront/react-sdk";

import Grid from "@vapor/react-material/Grid";
import Stack from "@vapor/react-material/Stack";

import ExtendedTypography from "@vapor/react-extended/ExtendedTypography";

export const NoData = (size) => {
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Stack direction="column" justifyContent="center" alignItems="center">
          <ExtendedTypography
            variant={size.size === null ? "titleSmall" : size.size}
            color="primary.main"
          >
            {t("error.noData")}
          </ExtendedTypography>
        </Stack>
      </Grid>
    </Grid>
  );
};
