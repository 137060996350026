//Modal
export const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "400px",
    bgcolor: "#FFFFFF",
    boxShadow: "0px 0px 8px 0px #CCCCCC"
};

export const modalStyleLarge = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "600px",
    // maxHeight: "70%",
    bgcolor: "#FFFFFF",
    boxShadow: "0px 0px 8px 0px #CCCCCC"
};

//Chip
export const chipGreen = {
    "&.MuiChip-root.MuiChip-root": {
        background: "#09822A",
        ":hover": {
            background: "#09822A"
        }
    },
    "&.MuiChip-root.MuiChip-root .MuiChip-label": {
        color: "#FFFFFF"
    },
};

export const chipYellow = {
    "&.MuiChip-root.MuiChip-root": {
        background: "#FDB927",
        ":hover": {
            background: "#FDB927",
            color: "#FFFFFF"
        }
    },
    "&.MuiChip-root.MuiChip-root .MuiChip-label": {
        color: "#FFFFFF"
    },
};

export const chipOrange = {
    "&.MuiChip-root.MuiChip-root": {
        background: "#FC4E3D",
        ":hover": {
            background: "#FC4E3D"
        }
    },
    "&.MuiChip-root.MuiChip-root .MuiChip-label": {
        color: "#FFFFFF"
    },
};

export const chipRed = {
    "&.MuiChip-root.MuiChip-root": {
        background: "#D82829",
        ":hover": {
            background: "#DA2C38"
        }
    },
    "&.MuiChip-root.MuiChip-root .MuiChip-label": {
        color: "#FFFFFF"
    },
};

export const divOrange = {
    background: "#FDB927",
    color: "#FFFFFF",
    borderRadius: 4,
    padding: "4px",
    fontSize: "12px",
    width: "70px",
    textAlign: "center"
};
export const divGreen = {
    background: "#09822A",
    color: "#FFFFFF",
    borderRadius: 4,
    padding: "4px",
    fontSize: "12px",
    width: "70px",
    textAlign: "center"
};
export const divRed = {
    background: "#D82829",
    color: "#FFFFFF",
    borderRadius: 4,
    padding: "4px",
    fontSize: "12px",
    width: "70px",
    textAlign: "center"
};