import React, { useState, useEffect, Fragment } from "react";
import sharedDatas from "../../businessLogic/sharedDatas";
import { useNavigate } from "react-router-dom";

//oneFront SDK
import {
  useSnackbar,
  useAuth,
  useQuery,
  useMutation,
  usePost,
  useGet,
  useTranslation,
} from "@onefront/react-sdk";

import VaporPage from "@vapor/react-custom/VaporPage";
import VaporToolbar from "@vapor/react-custom/VaporToolbar";

//Vapor Components
import Stack from "@vapor/react-material/Stack";
import Button from "@vapor/react-material/Button";
import Divider from "@vapor/react-material/Divider";
import TextField from "@vapor/react-material/TextField";
import Stepper from "@vapor/react-material/Stepper";
import Step from "@vapor/react-material/Step";
import StepLabel from "@vapor/react-material/StepLabel";
import Alert from "@vapor/react-material/Alert";
import AccordionSummary from "@vapor/react-material/AccordionSummary";
import AccordionDetails from "@vapor/react-material/AccordionDetails";
import Grid from "@vapor/react-material/Grid";
import FormGroup from "@vapor/react-material/FormGroup";
import FormControlLabel from "@vapor/react-material/FormControlLabel";
import Switch from "@vapor/react-material/Switch";
import Radio from "@vapor/react-material/Radio";
import ExtendedTypography from "@vapor/react-extended/ExtendedTypography";
import ExtendedAccordion from "@vapor/react-extended/ExtendedAccordion";

import VaporUploader from "@vapor/react-custom/VaporUploader";

//Icons
import {
  Search as SearchIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";

//Custom components
import { Loading } from "../../components/Loading";

//Constants, Api and Data Models
import {
  bsaFascicolo,
  bsaAnagrafica,
  pathFascicoloHome,
  pathFascicoloAdd,
} from "../../businessLogic/constants";
import {
  setLocalStorageSelectedFascicolo,
  getUniqueFileName,
  getDataFromFiscalCode,
  isPIvaValid,  
  isAlphaNumeric,
  isGuid
} from "../../businessLogic/bl";
import {
  userPermissionModel,
  fascicoloModel,
  anagraficaModel,
  baseTipoAnagraficaModel,
  anagraficaIdentificazioneModel,
} from "../../businessLogic/models";

import {
  query_user_permission,
  query_anagrafiche_byCf,
  query_anagrafiche_byCf_PG,
  query_base_tipo_anagrafica,
  query_base_tipo_anagrafica_byKey,
  query_base_paese_byIdent,
  query_base_provincia_byIdent,
  query_base_comune_byNome,
  query_base_genere,
  query_base_comune_byCodBelfiore,
  query_base_paese_byCodAt,
  query_base_provincia_byId,
  query_base_comune_byId,
} from "../../businessLogic/query";

import {
  mutation_add_log,
  mutation_add_audit,
  mutation_add_fascicolo,
  mutation_add_anagrafica,
  mutation_add_anagrafica_fascicolo,
  mutation_add_allegato,
  mutation_upload_allegato_toStorage,
  mutation_update_anagrafica,
} from "../../businessLogic/mutation";

//Other components
import { v4 as uuidv4 } from "uuid";
import { anagrafica } from "../anagrafica";
const pdfjs = window["pdfjs-dist/build/pdf"];

export const FascicoloNew = ({ basePath }) => {
  const tenantId = sharedDatas.getPropertyByName("tenantId");
  const aziendaId = sharedDatas.getPropertyByName("aziendaId");
  const loggedUserId = sharedDatas.getPropertyByName("userId");
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  //Query
  const apiAnagByCf = useQuery(
    query_anagrafiche_byCf,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiAnagByCfPG = useQuery(
    query_anagrafiche_byCf_PG,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseTipoAnagrafica = useQuery(
    query_base_tipo_anagrafica,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseGenere = useQuery(
    query_base_genere,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiTipoAnagraficaByKey = useQuery(
    query_base_tipo_anagrafica_byKey,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiPaeseByIdent = useQuery(
    query_base_paese_byIdent,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBasePaeseByCodAt = useQuery(
    query_base_paese_byCodAt,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiProvinciaByIdent = useQuery(
    query_base_provincia_byIdent,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiProvinciaById = useQuery(
    query_base_provincia_byId,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiComuneByNome = useQuery(
    query_base_comune_byNome,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiComuneById = useQuery(
    query_base_comune_byId,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseComuneByCodBelfiore = useQuery(
    query_base_comune_byCodBelfiore,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );

  //Mutation
  const apiAddLog = useMutation(mutation_add_log, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddAudit = useMutation(mutation_add_audit, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddFascicolo = useMutation(mutation_add_fascicolo, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddAnagrafica = useMutation(mutation_add_anagrafica, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiUpdateAnagrafica = useMutation(mutation_update_anagrafica, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddAnagraficaFascicolo = useMutation(
    mutation_add_anagrafica_fascicolo,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiAddAllegato = useMutation(mutation_add_allegato, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiUploadAllegatoToStorage = useMutation(
    mutation_upload_allegato_toStorage,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );

  const [backDropOpen, setBackDropOpen] = useState(true); //Loader utilizzato durante il caricamento
  const [isDataLoaded, setIsDataLoaded] = useState(false); //Indica lo stato di caricamento del result Api

  const [userPermissions, setUserPermissions] = useState(
    new userPermissionModel(
      0,
      aziendaId,
      bsaFascicolo,
      false,
      false,
      false,
      false
    )
  );

  const [baseTipoAnagList, setBaseTipoAnagList] = useState([]);
  const [currentAnagrafica, setCurrentAnagrafica] = useState(
    new anagraficaModel(
      0,
      0,
      true,
      "",
      "",
      "",
      0,
      "",
      null,
      "",
      "",
      "",
      "",
      "",
      undefined,
      undefined,
      "",
      undefined,
      "",
      "",
      "",
      "",
      undefined,
      undefined,
      "",
      undefined,
      "",
      "",
      "",
      "",
      false,
      false,
      1,
      new Date(),
      false,
      [],
      [],
      "",
      loggedUserId,
      "",
      [],
      null,
      "",
      "",
      "",
      ""    
    )
  ); //Contiene l'anagrafica corrente
  const [anagResultList, setAnagResultList] = useState([]);
  const [anagWSResultList, setAnagWSResultList] = useState([]);
  const [importResultList, setImportResultList] = useState([]);
  const [anagResultFascicolo, setAnagResultFascicolo] = useState(
    new fascicoloModel(
      0,
      tenantId,
      aziendaId,
      "",
      null,
      false,
      null,
      null,
      [],
      "",
      "",
      loggedUserId
    )
  );
  const [showNoAnagFound, setShowNoAnagFound] = useState(false);
  const [anagFound, setAnagFound] = useState(false);
  const [anagWSFound, setAnagWSFound] = useState(false);
  const [fascicoloFound, setFascicoloFound] = useState(false);
  const [isPG, setIsPG] = useState(true);
  const [selectedValue, setSelectedValue] = useState("PG");
  //TS Digital Api
  const [accessToken, setAccessToken] = useState(useAuth().token);

  //const tsApiAntiRic = useGet("aml:ts-anti-ric:api://", { lazy: true });
  const tsApiWorkspace = usePost(
    "aml:workspace-read:api://search",
    { lazy: true }
  );
  const tsApiWorkspaceWrite = usePost(
    "aml:workspace-write:api://",
    {},
    {
      headers: {
        Authorization: "Bearer " + accessToken,
        "X-App-Name": "TS420",
        "X-App-Version": "1.0.0",
        "X-Request-ID": uuidv4(),
        "X-Correlation-ID": uuidv4(),
        "Content-Type": "application/json",
        "X-Item-ID": tenantId,
        "X-User-ID": loggedUserId,
      },
    }
  );

  const tsApiWorkspaceRead = useGet(
    "aml:workspace-read:api//",
    {},
    {
      headers: {
        Authorization: "Bearer " + accessToken,
        "X-App-Name": "TS420",
        "X-App-Version": "1.0.0",
        "X-Request-ID": uuidv4(),
        "X-Correlation-ID": uuidv4(),
        "Content-Type": "application/json",
        "X-Item-ID": tenantId,
        "X-User-ID": loggedUserId,
      },
    }
  )

  useEffect(() => {
    loadDatas();
  }, [isDataLoaded]);
  async function loadDatas() {
    try {
      setBackDropOpen(true);
      if (!isDataLoaded) {
        /**************************************/
        //Carico le tabelle di base
        /*base_tipo_anagrafica*/
        const tbTipoAnagResponse = await apiBaseTipoAnagrafica.fetch({
          tenantId: tenantId,
        });
        const arrTipoAnagItems = [];
        tbTipoAnagResponse.data.data.base_tipo_anagrafica.map((item) =>
          arrTipoAnagItems.push(
            new baseTipoAnagraficaModel(
              item.Id,
              item.Key,
              item.Value,
              item.Order,
              item.IsSystem
            )
          )
        );
        setBaseTipoAnagList(arrTipoAnagItems);
      }
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "FascicoloNew",
        method: "loadDatas",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
      setIsDataLoaded(true);
    }
  }

  //Home Button
  const handleGoHome = () => {
    navigate(pathFascicoloHome);
  };

  //Steps
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const steps = ["Ricerca", "Risultato"];
  const completedSteps = () => {
    return Object.keys(completed).length;
  };
  const totalSteps = () => {
    return steps.length;
  };
  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };
  const isLastStep = () => {
    return activeStep === steps.length - 1;
  };
  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  //Form
  const handleChange = (event, value) => {
    const id =
      event.target.id !== undefined ? event.target.id : event.target.name;
    
    //let chbIsPg = currentAnagrafica.isPg;
    let cf = currentAnagrafica.cf;
    let pIva = currentAnagrafica.pIva;
    let nome = currentAnagrafica.nome;
    let cognome = currentAnagrafica.cognome;
    let denominazione = currentAnagrafica.denominazione;

    if (id === "checkPg") {
      setSelectedValue("PG")
      currentAnagrafica.isPg = true;
      setIsPG(true);
    } 
    else if (id === "checkPf"){
      setSelectedValue("PF")
      currentAnagrafica.isPg = false;
      setIsPG(false);
    }else if (id === "txtSearchCf") {
      cf = event.target.value;
    } else if (id === "txtSearchPIva") {
      pIva = event.target.value;
    } else if (id === "txtSearchNome") {
      nome = event.target.value;
    } else if (id === "txtSearchCognome") {
      cognome = event.target.value;
    } else if (id === "txtSearchDenominazione") {
      denominazione = event.target.value;
    } else if (id === "txtCf") {
      cf = event.target.value;
    } else if (id === "txtPIva") {
      pIva = event.target.value;
    } else if (id === "txtNome") {
      nome = event.target.value;
    } else if (id === "txtCognome") {
      cognome = event.target.value;
    } else if (id === "txtDenominazione") {
      denominazione = event.target.value;
    }

    setCurrentAnagrafica((prevState) => ({
      ...prevState,
      isPg: isPG,
      cf: cf,
      pIva: pIva,
      nome: nome,
      cognome: cognome,
      denominazione: denominazione,
    }));
  };
  const handleBlur = async (event, value) => {
    try {
      const { id } = event.target;
      const txtValue = event.target.value;

      if (id === "txtCf" && txtValue !== "") {
        if (isPG) {
          if (txtValue.length === 16 || txtValue.length === 11) {
          } else {
            enqueueSnackbar("Il Codice Fiscale non sembra essere corretto", {
              variant: "warning",
            });
          }
        } else {
          if (txtValue.length === 16) {
          } else {
            enqueueSnackbar("Il Codice Fiscale non sembra essere corretto", {
              variant: "warning",
            });
          }
        }
      } else if (
        id === "txtPIva" &&
        txtValue !== "" &&
        isPIvaValid(txtValue) !== "" &&
        txtValue.length !== 11
      ) {
        enqueueSnackbar(isPIvaValid(txtValue), { variant: "warning" });
      }
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "FascicoloNew",
        method: "handleBlur",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    }
  };
  const handleSearchClick = async () => {
    try {            
      setBackDropOpen(true);

      setAnagResultList([]);
      setAnagWSResultList([]);
      setAnagResultFascicolo(
        new fascicoloModel(
          0,
          tenantId,
          aziendaId,
          "",
          null,
          false,
          null,
          null,
          [],
          "",
          "",
          loggedUserId
        )
      );
      setShowNoAnagFound(false);
      setAnagFound(false);
      setAnagWSFound(false);
      setFascicoloFound(false);

      let cf = currentAnagrafica.cf;
      let pIva = currentAnagrafica.pIva;
      let nome = currentAnagrafica.nome;
      let cognome = currentAnagrafica.cognome;
      let denominazione = currentAnagrafica.denominazione;

      let canIProceed = false;
      let lengthOk = true;
      if (!isPG) {        
        canIProceed = cf !== "" || cognome !== "" || nome !== "";        
      } else {        
        canIProceed = cf !== "" || pIva !== "" || denominazione !== "";        
      }

      if (canIProceed) {
        cf = "%" + currentAnagrafica.cf + "%";
        pIva = "%" + currentAnagrafica.pIva + "%";
        nome = "%" + currentAnagrafica.nome + "%";
        cognome = "%" + currentAnagrafica.cognome + "%";
        denominazione = "%" + currentAnagrafica.denominazione + "%";

        /**************************************/
        //Cerco nelle anagrafiche
        const arrAnag = [];              
        let anagByCfResponse = null;              
        if (!isPG) {
          anagByCfResponse = await apiAnagByCf.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            cf: cf,
            nome: "%%",
            cognome: "%%",
          });
        } else {
          anagByCfResponse = await apiAnagByCfPG.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            cf: cf,
            pIva: pIva,
            denominazione: "%%",
          });
        }

        if (
          anagByCfResponse !== null &&
          anagByCfResponse.data.data.anagrafica.length > 0
        ) {
          for (
            let i = 0;
            i < anagByCfResponse.data.data.anagrafica.length;
            i++
          ) {
            const item = anagByCfResponse.data.data.anagrafica[i];

            if (arrAnag.filter((x) => x.cf === item.Cf).length <= 0) {
              // Recupero l'identificazione
              let anagIdent = [];
              if (item.anagrafica_identificaziones.length > 0) {
                const identificazione = item.anagrafica_identificaziones[0];                
                anagIdent.push(
                  new anagraficaIdentificazioneModel(
                    identificazione.Id,
                    identificazione.base_tipo_identificazione.Id,
                    identificazione.DocTipoId,
                    identificazione.DocN,
                    identificazione.DocRilDa,
                    identificazione.DocRilData,
                    identificazione.DocScad,
                    identificazione.ApNome,
                    identificazione.ApCf,
                    identificazione.IdentifiedByUser,
                    identificazione.IdentifiedOn,
                    identificazione.CreatedOn,
                    identificazione.ModifiedOn,
                    identificazione.CreatedBy,
                    identificazione.ModifiedBy
                  )
                );
              }

              let provincia = "";
              let comune = "";
              if (item.AddrProvinciaId > 0) {
                const provinciaItem = await apiProvinciaById.fetch({
                  id: item.AddrProvinciaId,
                });
                provincia = provinciaItem.data.data.base_provincia_by_pk.Sigla;
              }

              if (item.AddrComuneId > 0) {
                const comuneItem = await apiComuneById.fetch({
                  id: item.AddrComuneId,
                });
                comune = comuneItem.data.data.base_comune_by_pk.Nome;
              }

              const anagAv = new anagraficaModel(
                item.Id,
                item.ParentId,
                item.IsPg,
                item.Cf,
                item.Nome,
                item.Cognome,
                item.GenereId,
                item.NascitaLuogo,
                item.NascitaData,
                item.Professione,
                item.Denominazione,
                item.PIva,
                item.ReaComune,
                item.ReaN,
                item.AddrPaeseId,
                item.AddrProvinciaId,
                provincia,
                item.AddrComuneId,
                comune,
                item.AddrIndirizzo,
                item.AddrN,
                item.AddrCap,
                item.Addr2PaeseId,
                item.Addr2ProvinciaId,
                item.Addr2Provincia,
                item.Addr2ComuneId,
                item.Addr2Comune,
                item.Addr2Indirizzo,
                item.Addr2N,
                item.Addr2Cap,
                item.IsPep,
                item.IsNotPepMoreThanYear,
                item.VersionN,
                item.VersionDate,
                item.IsDeleted,
                anagIdent,
                [],
                item.PepCarica,
                item.CreatedBy,
                item.ModifiedBy,
                item.anagrafica_fascicolos,
                item.WorkspaceId
              );
              arrAnag.push(anagAv);

              const tipoAnagId1 = baseTipoAnagList.filter(
                (x) => x.key === "tipo_anagrafica_1"
              )[0].id;
              const tipoAnagId2 = baseTipoAnagList.filter(
                (x) => x.key === "tipo_anagrafica_2"
              )[0].id;
              const isCliente =
                item.anagrafica_fascicolos.filter(
                  (x) =>
                    x.base_tipo_anagrafica.Id === tipoAnagId1 ||
                    x.base_tipo_anagrafica.Id === tipoAnagId2
                ).length > 0;

              setFascicoloFound(isCliente);
              if (isCliente) {
                const fascAsCliente = item.anagrafica_fascicolos.filter(
                  (x) =>
                    x.base_tipo_anagrafica.Id === tipoAnagId1 ||
                    x.base_tipo_anagrafica.Id === tipoAnagId2
                )[0];
                setAnagResultFascicolo(
                  new fascicoloModel(
                    fascAsCliente.fascicolo.Id,
                    tenantId,
                    aziendaId,
                    fascAsCliente.fascicolo.Name,
                    fascAsCliente.fascicolo.Date,
                    false,
                    null,
                    null,
                    [],
                    "",
                    "",
                    fascAsCliente.fascicolo.CreatedBy,
                    fascAsCliente.fascicolo.ModifiedBy
                  )
                );
              }
            }
          }
        }
        setAnagResultList(arrAnag);
        setAnagFound(arrAnag.length > 0);

        /**************************************/
        //Cerco nelle anagrafiche da Workspace
        let arrAnagWS = [];
        if (arrAnag.length <= 0) {
          try {
            const anagWSResponse = await tsApiWorkspace.fetch({
              headers: {
                Authorization: "Bearer " + accessToken,
                "X-App-Name": "TS420",
                "X-App-Version": "1.0.0",
                "X-Request-ID": uuidv4(),
                "X-Correlation-ID": uuidv4(),
                "Content-Type": "application/json",
                "X-Item-ID": tenantId,
                "X-User-ID": loggedUserId,
              },
              data: {
                ownerId: tenantId,
                status: "ACTIVE",
                identifier: cf.replace(/%/g, ""),
              },
            });                                    
            let anagAv = null
            anagWSResponse.data.content.forEach((anagrafica) => {
              anagAv = new anagraficaModel(                
                0,
                0,
                anagrafica.classifier === "COMPANY" ? true : false,
                anagrafica.personalData.taxIdentifier,
                anagrafica.personalData.name,
                anagrafica.personalData.surname,
                0,
                anagrafica.personalData.birthplace,
                anagrafica.personalData.birthDate,
                "",
                anagrafica.personalData.businessName,
                anagrafica.personalData.vatIdentifier,
                "",
                "",
                0,
                0,
                "",
                0,
                "",
                "",
                "",
                "",
                0,
                0,
                "",
                0,
                "",
                "",
                "",
                "",
                false,
                false,
                1,
                new Date(),
                false,
                [],
                [],
                "",
                loggedUserId,
                null,
                [],
                [],
                anagrafica.id,                               
              );
              arrAnagWS.push(anagAv);
            });
            
            //Recupero gli office Ids per salvarli a DB
            let offices = await getExistingOfficeFromWorkspace(anagWSResponse)                       
            if(isGuid(offices[0]))
            {
              anagAv.office1Id = offices[0]               
            }              
            if(isGuid(offices[1])){
              anagAv.office2Id = offices[1]
            }
            
            //Recupero aml Id per salvarlo a DB
            let amlId = await getAmlDatasFromWorkSpace(anagWSResponse)
            if(isGuid(amlId)){
              anagAv.amlId = amlId
            }
            
            //Filtro i risultati di workspace secondo la tipologia di anagrafica (Persona fisica o persona giuridica)
            if(isPG){
              arrAnagWS = arrAnagWS.filter(anag=> anag.isPG)  
            } else {
              arrAnagWS = arrAnagWS.filter(anag=> !anag.isPG)
            }
            setAnagWSResultList(arrAnagWS);
            setAnagWSFound(arrAnagWS.length > 0);            
          } catch (e) {
            apiAddLog.fetch({
              tenantId: sharedDatas.getPropertyByName("tenantId"),
              aziendaId: sharedDatas.getPropertyByName("aziendaId"),
              level: "ERROR",
              message: e.message,
              stack: e.stack,
              area: "FascicoloNew",
              method: "handleSearchClick",
              version: sharedDatas.getPropertyByName("version"),
            });
            setAnagWSResultList([]);
            setAnagWSFound(false);
            setShowNoAnagFound(true);
          }
        }            
        handleNext();
      } else {
        if (!lengthOk) {
          enqueueSnackbar("Codice Fiscale o Partita Iva non sono corretti", {
            variant: "error",
          });
        } else {          
          enqueueSnackbar(
            "Per eseguire la ricerca è necessario compilare il campo Codice Fiscale",
            { variant: "info" }
          );
        }
      }
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "FascicoloNew",
        method: "handleSearchClick",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
    }
  };
  const handleNewSearch = () => {
    setCurrentAnagrafica(
      new anagraficaModel(
        0,
        0,
        true,
        "",
        "",
        "",
        0,
        "",
        null,
        "",
        "",
        "",
        "",
        "",
        undefined,
        undefined,
        "",
        undefined,
        "",
        "",
        "",
        "",
        undefined,
        undefined,
        "",
        undefined,
        "",
        "",
        "",
        "",
        false,
        false,
        1,
        new Date(),
        false,
        [],
        [],
        "",
        loggedUserId,
        "",
        [],
        null
      )
    );
    setAnagResultList([]);
    setAnagWSResultList([]);
    setImportResultList([]);
    setAnagResultFascicolo(
      new fascicoloModel(
        0,
        tenantId,
        aziendaId,
        "",
        null,
        false,
        null,
        null,
        [],
        "",
        "",
        loggedUserId
      )
    );
    setShowNoAnagFound(false);
    setAnagFound(false);
    setAnagWSFound(false);
    setFascicoloFound(false);
    //setFiles([]);
    handleBack();
  };
  const handleGoToFascicolo = (fascicoloId, newWindow) => {
    if (fascicoloId < 0) {
      setLocalStorageSelectedFascicolo(anagResultFascicolo.id);
    } else {
      setLocalStorageSelectedFascicolo(parseInt(fascicoloId));
    }

    // if (newWindow) {
    //     window.open(pathFascicoloAdd, "_blank", "noopener,noreferrer");
    // }
    // else {
    //     navigate(pathFascicoloAdd);
    // }
    navigate(pathFascicoloAdd);
  };
  const handleCreateFascicolo = async (anagrafica, newWindow, fileName) => {
    try {      
      setBackDropOpen(true);
      var hasErrors = false;

      //Fascicolo
      if (
        anagrafica.nome === "" &&
        anagrafica.cognome === "" &&
        anagrafica.denominazione === ""
      ) {
        anagrafica.denominazione = anagrafica.cf;
      } else {
        if (!anagrafica.isPg) {
          anagrafica.denominazione = anagrafica.nome + " " + anagrafica.cognome;
        }
      }

      var fascicolo = new fascicoloModel(
        0,
        tenantId,
        aziendaId,
        anagrafica.denominazione,
        new Date(),
        false,
        new Date(),
        null,
        [],
        "",
        "",
        loggedUserId
      );
      const fascicoloResp = await apiAddFascicolo.fetch({
        tenantId: tenantId,
        aziendaId: aziendaId,
        name: fascicolo.name,
        date: fascicolo.date,
        isPrivate: fascicolo.isPrivate,
      });

      if (fascicoloResp.data.errors !== undefined) {
        hasErrors = true;
      } else {
        const result = fascicoloResp.data.data.insert_fascicolo_one;

        let fascicoloResultModel = new fascicoloModel(
          result.Id,
          result.TenantId,
          result.AziendaId,
          result.Name,
          result.Date,
          result.IsPrivate,
          result.CreatedOn,
          result.ModifiedOn,
          [],
          "",
          "",
          result.CreatedBy,
          result.ModifiedBy
        );

        //Aggiungo la Audit per tracciare l'azione
        const newValues = fascicoloResultModel;
        apiAddAudit.fetch({
          tenantId: tenantId,
          aziendaId: aziendaId,
          area: bsaFascicolo,
          operation: "Create",
          entityType: "fascicolo",
          entityId: result.Id.toString(),
          oldValues: "",
          newValues: JSON.stringify(newValues),
        });

        //Anagrafica
        const tipoAnagKey = anagrafica.isPg
          ? "tipo_anagrafica_2"
          : "tipo_anagrafica_1";
        const tipoAnagByKeyResp = await apiTipoAnagraficaByKey.fetch({
          key: tipoAnagKey,
        });
        const tipoAnagId =
          tipoAnagByKeyResp.data.data.base_tipo_anagrafica.length > 0
            ? tipoAnagByKeyResp.data.data.base_tipo_anagrafica[0].Id
            : 0;

        if (anagrafica.id === 0) {
          const cfDatas = getDataFromFiscalCode(anagrafica.cf);
          if (cfDatas !== "") {
            const dataNascita = cfDatas.data.split("/");
            const sesso = cfDatas.sesso;
            const codComune = cfDatas.codComune;
            var luogoNascita = "";

            const comuneResponse = await apiBaseComuneByCodBelfiore.fetch({
              codBelfiore: codComune,
            });
            if (comuneResponse.data.errors !== undefined) {
            } else {
              comuneResponse.data.data.base_comune.forEach((item) => {
                luogoNascita = item.Nome;
              });
            }

            if (luogoNascita === "") {
              const paeseResponse = await apiBasePaeseByCodAt.fetch({
                codiceAt: codComune,
              });
              if (paeseResponse.data.errors !== undefined) {
              } else {
                paeseResponse.data.data.base_paese.forEach((item) => {
                  luogoNascita = item.Nome;
                });
              }
            }

            const nascitaDataD = new Date(
              parseInt(dataNascita[2]),
              parseInt(dataNascita[1]) - 1,
              parseInt(dataNascita[0]),
              1,
              0,
              0
            );
            const nascitaData = new Date(
              Date.UTC(
                nascitaDataD.getFullYear(),
                nascitaDataD.getMonth(),
                nascitaDataD.getDate(),
                1,
                0,
                0
              )
            );

            let genereId = 0;
            const baseGenereListResponse = await apiBaseGenere.fetch({
              tenantId: tenantId,
            });
            if (baseGenereListResponse.data.data.base_genere.length > 0) {
              genereId = baseGenereListResponse.data.data.base_genere.filter(
                (x) => x.Value === sesso
              )[0].Id;
            }

            if (anagrafica.nascitaData === null) {
              anagrafica.nascitaData = nascitaData;
            }
            if (anagrafica.nascitaLuogo === "") {
              anagrafica.nascitaLuogo = luogoNascita;
            }
            if (anagrafica.genereId === 0) {
              anagrafica.genereId = genereId;
            }
          }

          //Salvo le modifiche su Workspace                            
          let officeIds = [null,null];
          try {            
            if(!isGuid(anagrafica.workspaceId)){
              anagrafica.workspaceId = await savePersonalDataOnWorkspace(anagrafica);
            }                       
          } catch (e) {
            apiAddLog.fetch({
              tenantId: sharedDatas.getPropertyByName("tenantId"),
              aziendaId: sharedDatas.getPropertyByName("aziendaId"),
              level: "ERROR",
              message: e.message,
              stack: e.stack,
              area: "FascicoloNew",
              method: "handleCreateFascicolo",
              version: sharedDatas.getPropertyByName("version"),
            });
          }

          if(isGuid(anagrafica.office1Id)){
            officeIds[0]= anagrafica.office1Id
          }          
          if(isGuid(anagrafica.office2Id)){
            officeIds[1]= anagrafica.office2Id
          }      
          if(officeIds.length <= 0){
            officeIds = await saveOfficesOnWorkSpace(anagrafica);            
          }               

          const anagraficaResp = await apiAddAnagrafica.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            parentId: 0,
            isPg: anagrafica.isPg,
            cf: anagrafica.cf,
            nome: anagrafica.nome,
            cognome: anagrafica.cognome,
            nascitaLuogo: anagrafica.nascitaLuogo,
            nascitaData: anagrafica.nascitaData,
            professione: anagrafica.professione,
            denominazione: anagrafica.denominazione,
            pIva: anagrafica.pIva,
            reaComune: anagrafica.reaComune,
            reaN: anagrafica.reaN,
            addrPaeseId: anagrafica.addrPaeseId,
            addrProvinciaId: anagrafica.addrProvinciaId,
            addrProvincia: anagrafica.addrProvincia,
            addrComuneId: anagrafica.addrComuneId,
            addrComune: anagrafica.addrComune,
            addrIndirizzo: anagrafica.addrIndirizzo,
            addrN: anagrafica.addrN,
            addrCap: anagrafica.addrCap,
            addr2PaeseId: anagrafica.addr2PaeseId,
            addr2ProvinciaId: anagrafica.addr2ProvinciaId,
            addr2Provincia: anagrafica.addr2Provincia,
            addr2ComuneId: anagrafica.addr2ComuneId,
            addr2Comune: anagrafica.addr2Comune,
            addr2Indirizzo: anagrafica.addr2Indirizzo,
            addr2N: anagrafica.addr2N,
            addr2Cap: anagrafica.addr2Cap,
            isPep: anagrafica.isPep,
            isNotPepMoreThanYear: anagrafica.isNotPepMoreThanYear,
            pepCarica : anagrafica.pepCarica,
            pepRuolo : "",
            versionN: anagrafica.versionN,
            versionDate: new Date(),
            isDeleted: false,
            genereId: anagrafica.genereId,
            workspaceId: anagrafica.workspaceId,
            AmlId: anagrafica.amlId,
            Office1Id: officeIds[0],
            Office2Id: officeIds[1],
          });

          if (anagraficaResp.data.errors !== undefined) {
            hasErrors = true;
          } else {
            const result2 = anagraficaResp.data.data.insert_anagrafica_one;

            let anagraficaResultModel = new anagraficaModel(
              result2.Id,
              result2.ParentId,
              result2.IsPg,
              result2.Cf,
              result2.Nome,
              result2.Cognome,
              result2.GenereId,
              result2.NascitaLuogo,
              result2.NascitaData,
              result2.Professione,
              result2.Denominazione,
              result2.PIva,
              result2.ReaComune,
              result2.ReaN,
              result2.AddrPaeseId,
              result2.AddrProvinciaId,
              result2.AddrProvincia,
              result2.AddrComuneId,
              result2.AddrComune,
              result2.AddrIndirizzo,
              result2.AddrN,
              result2.AddrCap,
              result2.Addr2PaeseId,
              result2.Addr2ProvinciaId,
              result2.Addr2Provincia,
              result2.Addr2ComuneId,
              result2.Addr2Comune,
              result2.Addr2Indirizzo,
              result2.Addr2N,
              result2.Addr2Cap,
              result2.IsPep,
              result2.IsNotPepMoreThanYear,
              result2.IsDeleted,
              result2.VersionN,
              result2.VersionDate,
              [],
              [],
              result2.PepCarica,
              result2.CreatedBy,
              result2.ModifiedBy,
              result2.anagrafica_fascicolos,
              result2.WorkspaceId
            );

            const addAnagFascicoloResponse =
              await apiAddAnagraficaFascicolo.fetch({
                anagraficaId: result2.Id,
                fascicoloId: fascicoloResultModel.id,
                tipoAnagId: tipoAnagId,
                tipoPartId: null,
                tipoPartDesc: "",
              });
            if (addAnagFascicoloResponse.data.errors !== undefined) {
            } else {
              const result3 =
                addAnagFascicoloResponse.data.data
                  .insert_anagrafica_fascicolo_one;
            }

            //Aggiungo la Audit per tracciare l'azione
            const newValues = anagraficaResultModel;
            apiAddAudit.fetch({
              tenantId: tenantId,
              aziendaId: aziendaId,
              area: bsaAnagrafica,
              operation: "Create",
              entityType: "anagrafica",
              entityId: result.Id.toString(),
              oldValues: "",
              newValues: JSON.stringify(newValues),
            });
          }
        } else {
          let anagByCfResponse = null;
          if (!isPG ) {
            anagByCfResponse = await apiAnagByCf.fetch({
              tenantId: tenantId,
              aziendaId: aziendaId,
              cf: "%" + anagrafica.cf + "%",
              nome: "%%",
              cognome: "%%",
            });
          } else {
            anagByCfResponse = await apiAnagByCfPG.fetch({
              tenantId: tenantId,
              aziendaId: aziendaId,
              cf: "%" + anagrafica.cf + "%",
              pIva: "%" + anagrafica.pIva + "%",
              denominazione: "%%",
            });
          }

          for (let i = 0; i < anagByCfResponse.data.data.anagrafica.length; i++) {
            const anag = anagByCfResponse.data.data.anagrafica[i];
      
            const addAnagFascicoloResponse =
              await apiAddAnagraficaFascicolo.fetch({
                anagraficaId: anag.Id,
                fascicoloId: fascicoloResultModel.id,
                tipoAnagId: tipoAnagId,
                tipoPartId: null,
                tipoPartDesc: "",
              });
            if (addAnagFascicoloResponse.data.errors !== undefined) {
            } else {
              const result3 =
                addAnagFascicoloResponse.data.data
                  .insert_anagrafica_fascicolo_one;
            }
          }
        }

        //Aggiungo gli allegati se ce ne sono
        try {
          if (fileName !== "") {
            const fileArr = files.filter((x) => x.name === fileName);
            if (fileArr.length > 0) {
              const file = fileArr[0];

              const uniqueFileName = getUniqueFileName();
              const displayedFileName = file.name;

              const encFileBase64 = await encodeBase64(file);
              const encFile = encFileBase64[0];

              const encFileBase64String = encFile.encrypt
                .substring(
                  encFile.encrypt.indexOf("base64,"),
                  encFile.encrypt.length
                )
                .split(",")[1];
              const fileJson = {
                file: encFileBase64String,
                contentType: file.type,
              };

              const fileUrl = await apiUploadAllegatoToStorage.fetch({
                data: JSON.stringify(fileJson),
              });

              const response = await apiAddAllegato.fetch({
                tenantId: tenantId,
                entityType: "fascicolo",
                entityId: fascicoloResultModel.id,
                fileName: fileUrl.data.data.uploadAttachment.fileName,
                fileType: file.type,
                fileUrl: fileUrl.data.data.uploadAttachment.path,
                displayedName: displayedFileName,
              });
            }
          }
        } catch (e) {
          apiAddLog.fetch({
            tenantId: sharedDatas.getPropertyByName("tenantId"),
            aziendaId: sharedDatas.getPropertyByName("aziendaId"),
            level: "ERROR",
            message: e.message,
            stack: e.stack,
            area: "FascicoloNew",
            method: "handleCreateFascicolo",
            version: sharedDatas.getPropertyByName("version"),
          });
        }

        handleGoToFascicolo(fascicoloResultModel.id, newWindow);
      }

            if (hasErrors) {
                enqueueSnackbar(t("error.generale"), { variant: "error" });
            }
        }
        catch (e) {
            apiAddLog.fetch({ tenantId: sharedDatas.getPropertyByName("tenantId"), aziendaId: sharedDatas.getPropertyByName("aziendaId"), level: "ERROR", message: e.message, stack: e.stack, area: "FascicoloNew", method: "handleCreateFascicolo", version: sharedDatas.getPropertyByName("version") });
            enqueueSnackbar(t("error.generale"), { variant: "error" });
        }
        finally {
            setBackDropOpen(false);
            setIsDataLoaded(true);
        }
    };
    const savePersonalDataOnWorkspace = async (anagrafica) =>{
        var data = null;
        let wsId = "";        
        let isPfFiscalCode = isAlphaNumeric(anagrafica.cf) && anagrafica.cf.length === 16
        let cfDatas = getDataFromFiscalCode(anagrafica.cf)        
        if (anagrafica.isPg) {
            data = {
                ownerId: tenantId,
                //itemId: "",
                classifier: "COMPANY",
                personalData: {
                    taxIdentifier: anagrafica.cf,
                    vatIdentifier: anagrafica.pIva,
                    businessName: anagrafica.denominazione,
                    countryCode: "IT"
                },
                //validityStart: new Date(),
                baseCurrency: "EUR"
            }
            if(isPfFiscalCode){
              data = {
                ownerId: tenantId,
                //itemId: "",
                classifier: "COMPANY",
                personalData: {
                    taxIdentifier: anagrafica.cf,
                    vatIdentifier: anagrafica.pIva,
                    businessName: anagrafica.denominazione,
                    countryCode: "IT",
                    name : anagrafica.cf.slice(3,6),
                    surname: anagrafica.cf.slice(0,3),
                    gender : cfDatas.sesso,
                    birthDate : new Date(cfDatas.data).toISOString(), 
                    birthplace : cfDatas.codComune
                },
                //validityStart: new Date(),
                baseCurrency: "EUR"                
              }
            }
        }
        else {
            data = {
                ownerId: tenantId,
                //itemId: "",
                classifier: "PERSON",
                personalData: {
                    taxIdentifier: anagrafica.cf,
                    countryCode: "IT",
                    name: anagrafica.nome,
                    surname: anagrafica.cognome,
                    birthDate: anagrafica.nascitaData !== null ? anagrafica.nascitaData : "1900-01-01T01:00:00",
                    birthplace: anagrafica.nascitaLuogo !== "" ? anagrafica.nascitaLuogo : "N.D.",
                    gender: anagrafica.genereId === 1 ? "M":"F",
                },
                //validityStart: new Date(),
                baseCurrency: "EUR"
            }
        }
        const wsResult = await tsApiWorkspaceWrite.fetch({
            headers: {
                "Authorization": "Bearer " + accessToken, "X-App-Name": "TS420", "X-App-Version": "1.0.0", "X-Request-ID": uuidv4(), "X-Correlation-ID": uuidv4(),
                "Content-Type": "application/json", "X-Item-ID": tenantId, "X-User-ID": loggedUserId
            },
            data
        });
        wsId = wsResult.data !== null ? wsResult.data.id : "";
        return wsId;
    } 
    const saveOfficesOnWorkSpace = async (anagrafica) => {        
        //Compongo i dati da postare su workspace 
        var hasOnlyOneAddress = anagrafica.addrIndirizzo === "" || anagrafica.addr2Indirizzo === "";
        var officeDatas = []
        if(hasOnlyOneAddress){
            var officeData = {
                description : "Indirizzo Principale",
                officeType: "MAIN",
                streetNumber: anagrafica.addrN,
                streetName: anagrafica.addrIndirizzo,
                province: anagrafica.addrProvincia,
                city: anagrafica.addrComune,
                district: "",
                zipCode: anagrafica.addrCap,
                region: "",
                country: "",                
                officeClassifier: [],
                customClassifierDecription : null,
                registeredResidenceType: "REGISTERED_RESIDENCE_AND_DOMICILE",                
            } 
            officeDatas.push(officeData) 
        } else {
            var officeData = {
                description : "Indirizzo Principale",
                officeType: "MAIN",
                streetNumber: anagrafica.addrN,
                streetName: anagrafica.addrIndirizzo,
                province: anagrafica.addrProvincia,
                city: anagrafica.addrComune,
                district: "",
                zipCode: anagrafica.addrCap,
                region: "",
                country: "",                
                officeClassifier: [],
                customClassifierDecription : null,
                registeredResidenceType: "REGISTERED_RESIDENCE",                
            } 
            var officeData2 = {
                description : "Indirizzo Secondario",
                officeType: "SECONDARY",
                streetNumber: anagrafica.addr2N,
                streetName: anagrafica.addr2Indirizzo,
                province: anagrafica.addr2Provincia,
                city: anagrafica.addr2Comune,
                district: "",
                zipCode: anagrafica.addr2Cap,
                region: "",
                country: "",                
                officeClassifier: [],
                customClassifierDecription : null,
                registeredResidenceType: "DOMICILE",                
            }
            officeDatas.push(officeData,officeData2)
        }
                
        //Posto i dati su workspace e salvo gli id che tornano
        const officeIds = []
        try{    
            for (let i = 0 ; i < officeDatas.length; i++){ 
                var resp = await fetch(`https://workspace-write-dev.agyo.io/api/v1/workspaces/${anagrafica.workspaceId}/office`,{
                    method : 'POST',
                    headers: {
                        "Authorization": "Bearer " + accessToken, "X-App-Name": "TS420", "X-App-Version": "1.0.0", "X-Request-ID": uuidv4(), "X-Correlation-ID": uuidv4(),
                        "Content-Type": "application/json", "X-Item-ID": tenantId, "X-User-ID": loggedUserId, "X-Workspace-ID" : anagrafica.workspaceId 
                    },
                    body : JSON.stringify(officeDatas[i])
                })                      
                const stringResponse = await resp.json()
                var officeId = stringResponse.id
                officeIds.push(officeId)              
            }         
                                                                                            
            return officeIds; 
        }
        catch (e) {            
            apiAddLog.fetch({ tenantId: sharedDatas.getPropertyByName("tenantId"), aziendaId: sharedDatas.getPropertyByName("aziendaId"), level: "ERROR", message: e.message, stack: e.stack, area: "AnagraficaImportDesktop", method: "saveOfficesOnWorkSpace", version: sharedDatas.getPropertyByName("version") });
        }
    }   
    const getExistingOfficeFromWorkspace = async (anagWSResponse) => {
      let existingAddresses = []
      let anagrafiche = anagWSResponse.data.content
      for (let i = 0; i < anagrafiche.length; i++) {
        let wsId = anagrafiche[i].id
        let officesResp = await tsApiWorkspaceRead.fetch({   
          url : `aml:workspace-read:api//${wsId}/office/`,                      
          headers: {
              "Authorization": "Bearer " + accessToken, "X-App-Name": "TS420", "X-App-Version": "1.0.0", "X-Request-ID": uuidv4(), "X-Correlation-ID": uuidv4(),
              "Content-Type": "application/json", "X-Item-ID": tenantId, "X-User-ID": loggedUserId, "X-Workspace-ID" : wsId 
          }      
        })

        let arrReturn = [null,null]
        for (let i = 0; i < officesResp.data.length; i++) {
          arrReturn[i] = officesResp.data[i].id;
        }          
        return arrReturn;
      }       

      return existingAddresses;
    }
    const getAmlDatasFromWorkSpace = async (anagWSResponse) => {
      let amlDatas = null
      let anagrafiche = anagWSResponse.data.content
      for (let i = 0; i < anagrafiche.length; i++) {
        let wsId = anagrafiche[i].id
        let amlResp = await tsApiWorkspaceRead.fetch({   
          url : `aml:workspace-read:api//${wsId}/antiMoneyLaundering/`,                                                    
          headers: {
              "Authorization": "Bearer " + accessToken, "X-App-Name": "TS420", "X-App-Version": "1.0.0", "X-Request-ID": uuidv4(), "X-Correlation-ID": uuidv4(),
              "Content-Type": "application/json", "X-Item-ID": tenantId, "X-User-ID": loggedUserId, "X-Workspace-ID" : wsId 
          }      
        })

        const stringResponse = amlResp.data !== null ? amlResp.data.id : ""
        return stringResponse
      }       
      return amlDatas;
    }
  //Pdf upload and parsing
  const [files, setFiles] = useState([]);
  var fileBase64 = [];
  const handleUploadeFile = async (file) => {
    try {
      setBackDropOpen(true);
      setShowNoAnagFound(false);

      let newArrFiles = [];
      for (let i = 0; i < file["length"]; i++) {
        if (file[i].type === "application/pdf") {
          newArrFiles.push(file[i]);
        }
      }

      if (newArrFiles.length > 0) {
        var arrFiles = [];
        var encodedPDF;
        // for (var i = 0; i < file["length"]; i++) {
        //     encodedPDF = await encodeBase64(file[i]);
        //     arrFiles.push(file[i]);
        // }
        for (var i = 0; i < newArrFiles.length; i++) {
          encodedPDF = await encodeBase64(newArrFiles[i]);
          arrFiles.push(newArrFiles[i]);
        }
        setFiles(arrFiles);

        const arrAnagItems = [];
        for (var i = 0; i < encodedPDF.length; i++) {
          const parsedPdfText = encodedPDF[i].text;
          const fileName = encodedPDF[i].name;

          //Visura camerale
          if (
            parsedPdfText.includes("VISURA ORDINARIA SOCIETA'") ||
            parsedPdfText.includes("VISURA DI EVASIONE")
          ) {
            var name = "";
            var names = parsedPdfText.match(
              /(?<=Denominazione:)(.*?)(?=Data atto )/
            );
            if (names !== null && names.length > 0) {
              name = names[0].trimStart().trimEnd();
            }

            if (name === "") {
              var names2 = parsedPdfText.match(
                /(?<=Registro Imprese in data \d{2}[./-]\d{2}[./-]\d{4})(.*?)(?=Codice Fiscale )/
              );
              if (names2 !== null && names2.length > 0) {
                name = names2[0].trimStart().trimEnd();
              }
            }

            var cf = "";
            var cfs = parsedPdfText.match(
              /(?<=Codice fiscale e n.iscr. al Registro Imprese)(.*?)(?=Partita IVA)/
            );
            if (cfs !== null && cfs.length > 0) {
              cf = cfs[0].trimStart().trimEnd();
              if (cf.length > 16) {
                cf = cf.substring(0, cf.indexOf(" "));
              }
            }

            if (cf === "") {
              var cfs2 = parsedPdfText.match(
                /(?<=Codice fiscale)(.*?)(?=Partita IVA)/
              );
              if (cfs2 !== null && cfs2.length > 0) {
                cf = cfs2[0].trimStart().trimEnd();
              }
            }

            var pIva = cf;
            var pIvas = parsedPdfText.match(
              /(?<=Partita IVA)(.*?)(?=Forma giuridica)/
            );
            if (pIvas !== null && pIvas.length > 0) {
              pIva = pIvas[0].trimStart().trimEnd();
            }

            var reaN = "";
            var reas = parsedPdfText.match(
              /(?<=Numero REA)(.*?)(?=Codice fiscale)/
            );
            if (reas !== null && reas.length > 0) {
              reaN = reas[0].trimStart().trimEnd();
            }

            var reaComune = "";
            var reaComunes = parsedPdfText.match(
              /(?<=del Registro delle Imprese di)(.*?)(?=Data iscrizione)/
            );
            if (reaComunes !== null && reaComunes.length > 0) {
              reaComune = reaComunes[0].trimStart().trimEnd();
            }

            var address = "";
            var addrPaeseId = 0;
            var addrProvincia = "";
            var addrProvinciaId = 0;
            var addrComune = "";
            var addrComuneId = 0;
            var addrIndirizzo = "";
            var addrN = "";
            var addrCap = "";

            var addresses = parsedPdfText.match(
              /(?<=Indirizzo Sede legale)(.*?)(?=Domicilio digitale)/
            );
            if (addresses !== null && addresses.length > 0) {
              address = addresses[0].trimStart().trimEnd();

              addrProvincia =
                address.match(/(?<=\()(.*?)(?=\))/).length > 0
                  ? address
                      .match(/(?<=\()(.*?)(?=\))/)[0]
                      .trimStart()
                      .trimEnd()
                  : "";
              addrComune =
                address.match(/^[^\(]+/).length > 0
                  ? address
                      .match(/^[^\(]+/)[0]
                      .trimStart()
                      .trimEnd()
                  : "";
              addrCap =
                address.match(/(?<=CAP)(.*)/).length > 0
                  ? address
                      .match(/(?<=CAP)(.*)/)[0]
                      .trimStart()
                      .trimEnd()
                  : "";
              addrIndirizzo =
                address.match(/(?<=\))(.*?)(?=CAP)/).length > 0
                  ? address
                      .match(/(?<=\))(.*?)(?=CAP)/)[0]
                      .trimStart()
                      .trimEnd()
                  : "";
              addrN =
                addrIndirizzo.match(/\s+\S*$/).length > 0
                  ? addrIndirizzo
                      .match(/\s+\S*$/)[0]
                      .trimStart()
                      .trimEnd()
                  : "";
            }

            if (addresses === null) {
              var addresses = parsedPdfText.match(
                /(?<=Indirizzo Sede legale)(.*?)(?=Indirizzo PEC)/
              );
              if (addresses !== null && addresses.length > 0) {
                address = addresses[0].trimStart().trimEnd();

                addrProvincia =
                  address.match(/(?<=\()(.*?)(?=\))/).length > 0
                    ? address
                        .match(/(?<=\()(.*?)(?=\))/)[0]
                        .trimStart()
                        .trimEnd()
                    : "";
                addrComune =
                  address.match(/^[^\(]+/).length > 0
                    ? address
                        .match(/^[^\(]+/)[0]
                        .trimStart()
                        .trimEnd()
                    : "";
                addrCap =
                  address.match(/(?<=CAP)(.*)/).length > 0
                    ? address
                        .match(/(?<=CAP)(.*)/)[0]
                        .trimStart()
                        .trimEnd()
                    : "";
                addrIndirizzo =
                  address.match(/(?<=\))(.*?)(?=CAP)/).length > 0
                    ? address
                        .match(/(?<=\))(.*?)(?=CAP)/)[0]
                        .trimStart()
                        .trimEnd()
                    : "";
                addrN =
                  addrIndirizzo.match(/\s+\S*$/).length > 0
                    ? addrIndirizzo
                        .match(/\s+\S*$/)[0]
                        .trimStart()
                        .trimEnd()
                    : "";
              }
            }

            //COSTRUISCO I MODELS
            //Anagrafica
            var anagraficaPg = new anagraficaModel(
              0,
              0,
              true,
              cf,
              "",
              "",
              0,
              "",
              null,
              "",
              name,
              pIva,
              reaComune,
              reaN,
              addrPaeseId,
              addrProvinciaId,
              addrProvincia,
              addrComuneId,
              addrComune,
              addrIndirizzo,
              addrN,
              addrCap,
              null,
              null,
              "",
              null,
              "",
              "",
              "",
              "",
              false,
              false,
              1,
              new Date(),
              false,
              [],
              [],
              "",
              loggedUserId,
              null,
              [],
              null
            );
            var fascicolo = new fascicoloModel(
              0,
              tenantId,
              aziendaId,
              "",
              null,
              false,
              null,
              null,
              [],
              "",
              "",
              loggedUserId
            );

            //Verifico l'esistenza del CF
            const anagByCfResponse = await apiAnagByCfPG.fetch({
              tenantId: tenantId,
              aziendaId: aziendaId,
              cf: "%" + cf + "%",
              pIva: "%" + pIva + "%",
              denominazione: "%" + name + "%",
            });
            if (anagByCfResponse.data.data.anagrafica.length > 0) {
              anagByCfResponse.data.data.anagrafica.forEach((item) => {
                anagraficaPg = new anagraficaModel(
                  item.Id,
                  item.ParentId,
                  item.IsPg,
                  item.Cf,
                  item.Nome,
                  item.Cognome,
                  item.GenereId,
                  item.NascitaLuogo,
                  item.NascitaData,
                  item.Professione,
                  item.Denominazione,
                  item.PIva,
                  item.ReaComune,
                  item.ReaN,
                  item.AddrPaeseId,
                  item.AddrProvinciaId,
                  item.AddrProvincia,
                  item.AddrComuneId,
                  item.AddrComune,
                  item.AddrIndirizzo,
                  item.AddrN,
                  item.AddrCap,
                  item.Addr2PaeseId,
                  item.Addr2ProvinciaId,
                  item.Addr2Provincia,
                  item.Addr2ComuneId,
                  item.Addr2Comune,
                  item.Addr2Indirizzo,
                  item.Addr2N,
                  item.Addr2Cap,
                  item.IsPep,
                  item.IsNotPepMoreThanYear,
                  item.VersionN,
                  item.VersionDate,
                  item.IsDeleted,
                  [],
                  [],
                  item.PepCarica,
                  item.CreatedBy,
                  item.ModifiedBy,
                  item.anagrafica_fascicolos,
                  item.WorkspaceId
                );

                const tipoAnagId1 = baseTipoAnagList.filter(
                  (x) => x.key === "tipo_anagrafica_1"
                )[0].id;
                const tipoAnagId2 = baseTipoAnagList.filter(
                  (x) => x.key === "tipo_anagrafica_2"
                )[0].id;
                const isCliente =
                  item.anagrafica_fascicolos.filter(
                    (x) =>
                      x.base_tipo_anagrafica.Id === tipoAnagId1 ||
                      x.base_tipo_anagrafica.Id === tipoAnagId2
                  ).length > 0;                

                if (isCliente) {
                  const fascAsCliente = item.anagrafica_fascicolos.filter(
                    (x) =>
                      x.base_tipo_anagrafica.Id === tipoAnagId1 ||
                      x.base_tipo_anagrafica.Id === tipoAnagId2
                  )[0];
                  fascicolo = new fascicoloModel(
                    fascAsCliente.fascicolo.Id,
                    tenantId,
                    aziendaId,
                    fascAsCliente.fascicolo.Name,
                    fascAsCliente.fascicolo.Date,
                    false,
                    null,
                    null,
                    [],
                    "",
                    "",
                    loggedUserId
                  );
                }
              });
            } else {
              const paeseByIdResp = await apiPaeseByIdent.fetch({
                identificativo: "ITA",
              });
              const paeseId =
                paeseByIdResp.data.data.base_paese.length > 0
                  ? paeseByIdResp.data.data.base_paese[0].Id
                  : 0;

              const provinciaByIdResp = await apiProvinciaByIdent.fetch({
                sigla: anagraficaPg.addrProvincia,
              });
              const provinciaId =
                provinciaByIdResp.data.data.base_provincia.length > 0
                  ? provinciaByIdResp.data.data.base_provincia[0].Id
                  : 0;

              const comuneByIdResp = await apiComuneByNome.fetch({
                nome: anagraficaPg.addrComune,
              });
              const comuneId =
                comuneByIdResp.data.data.base_comune.length > 0
                  ? comuneByIdResp.data.data.base_comune[0].Id
                  : 0;

              anagraficaPg.addrPaeseId = paeseId;
              anagraficaPg.addrProvinciaId = provinciaId;
              anagraficaPg.addrComuneId = comuneId;
            }

            if(anagraficaPg.cf && anagraficaPg.cf.length > 50 || anagraficaPg.PIva && anagraficaPg.PIva.length > 50){
              setImportResultList([]);
              enqueueSnackbar("Spiacente ma il file che si sta utilizzando per effettuare l'import dei dati non è compatibile con la procedura", { variant: "error" });
              setBackDropOpen(false);
              return;
            }

            arrAnagItems.push({
              anagrafica: anagraficaPg,
              fascicolo: fascicolo,
              fileName: fileName,
            });
          }

          //Visura catastale
          else if (parsedPdfText.includes("Visura attuale per immobile")) {
            var totaleIntestati = 0;
            var totaleIntestatiL = parsedPdfText.match(
              /(?<=totale intestati:)(.*?)(?=1.)/
            );
            totaleIntestati =
              totaleIntestatiL.length > 0
                ? parseInt(totaleIntestatiL[0].trimStart().trimEnd())
                : 0;

            var prevIntestatario = "";
            if (totaleIntestati > 0) {
              for (var i = 1; i <= totaleIntestati; i++) {
                const current = i;
                const next = i + 1;

                var intestatario = "";
                var intestatarioL = "";
                var expression = "";
                if (i === 1) {
                  //expression = `(?<=totale intestati: ${totaleIntestati}  ${current}. )(.*?)(?=${next}. )`;
                  expression = `(?<=totale intestati: ${totaleIntestati}  ${current}. )(.*?)(?=Diritto )`;
                  intestatarioL = new RegExp(expression, "g").exec(
                    parsedPdfText
                  );
                } else if (i > 1 && i < totaleIntestati) {
                } else if (i === totaleIntestati) {
                  //expression = `(?<=${current}. )(.*?)(?=Diritto )`;
                  expression = `(?<= ${totaleIntestati}. )(.*?)(?=Diritto)`;
                  var intestatarioToParse = parsedPdfText.substring(
                    parsedPdfText.indexOf(prevIntestatario),
                    parsedPdfText.length
                  );
                  intestatarioL = new RegExp(expression, "g").exec(
                    intestatarioToParse
                  );
                }

                intestatario =
                  intestatarioL !== null && intestatarioL.length > 0
                    ? intestatarioL[0]
                    : "";
                prevIntestatario = intestatario;

                if (intestatario !== "") {
                  var isPg = false;
                  var cf = "";
                  var nome = "";
                  var cognome = "";
                  var bornDate = "";
                  var bornLocation = "";
                  var genereId = null;

                  var nomeCompletoL = intestatario.match(/(.*?)(?=\()/);
                  if (nomeCompletoL !== null && nomeCompletoL.length > 0) {
                    const nomeCompleto = nomeCompletoL[0].trimStart().trimEnd();

                    var cognomeArr = nomeCompleto.match(/[A-Z]/g);
                    cognomeArr.forEach((letter) => {
                      cognome = cognome + letter;
                    });

                    cognome = cognome
                      .substring(0, cognome.length - 1)
                      .trimStart()
                      .trimEnd();
                    nome = nomeCompleto
                      .replace(cognome, "")
                      .trimStart()
                      .trimEnd();
                  }

                  var cfL = intestatario.match(/(?<=\(CF )(.*?)(?=\))/);
                  cf =
                    cfL !== null && cfL.length > 0
                      ? cfL[0].trimStart().trimEnd()
                      : "";

                  var bornLocsF = intestatario.match(/(?<=nata a)(.*?)(?=il )/);
                  if (bornLocsF != null && bornLocsF.length > 0) {
                    var bornLocCities = bornLocsF[0]
                      .trimStart()
                      .trimEnd()
                      .match(/(.*?)(?=\()/);
                    bornLocation =
                      bornLocCities.length > 0
                        ? bornLocCities[0].trimStart().trimEnd()
                        : "";
                    const baseGenereListResponse = await apiBaseGenere.fetch({
                      tenantId: tenantId,
                    });
                    if (
                      baseGenereListResponse.data.data.base_genere.length > 0
                    ) {
                      genereId =
                        baseGenereListResponse.data.data.base_genere.filter(
                          (x) => x.Key === "base_genere_2"
                        )[0].Id;
                    }
                  } else {
                    var bornLocsM = intestatario.match(
                      /(?<=nato a)(.*?)(?=il )/
                    );
                    if (bornLocsM !== null && bornLocsM.length > 0) {
                      var bornLocCities = bornLocsM[0]
                        .trimStart()
                        .trimEnd()
                        .match(/(.*?)(?=\()/);
                      bornLocation =
                        bornLocCities !== null && bornLocCities.length > 0
                          ? bornLocCities[0].trimStart().trimEnd()
                          : "";
                      const baseGenereListResponse = await apiBaseGenere.fetch({
                        tenantId: tenantId,
                      });
                      if (
                        baseGenereListResponse.data.data.base_genere.length > 0
                      ) {
                        genereId =
                          baseGenereListResponse.data.data.base_genere.filter(
                            (x) => x.Key === "base_genere_1"
                          )[0].Id;
                      }
                    }
                  }

                  var bornDateL = intestatario.match(
                    "[0-9]{2}([-/ .])[0-9]{2}[-/ .][0-9]{4}"
                  );
                  bornDate =
                    bornDateL !== null && bornDateL.length > 0
                      ? bornDateL[0].trimStart().trimEnd()
                      : "";
                  var bornDateD = null;
                  if (bornDate !== "") {
                    var dateParts = bornDate.split("/");
                    bornDateD = new Date(
                      +dateParts[2],
                      dateParts[1] - 1,
                      +dateParts[0]
                    );
                  }

                  //COSTRUISCO I MODELS
                  //Anagrafica
                  var anagraficaPg = new anagraficaModel(
                    0,
                    0,
                    isPg,
                    cf,
                    nome,
                    cognome,
                    genereId,
                    bornLocation,
                    bornDateD,
                    "",
                    name,
                    pIva,
                    "",
                    "",
                    null,
                    null,
                    "",
                    null,
                    "",
                    "",
                    "",
                    "",
                    null,
                    null,
                    "",
                    null,
                    "",
                    "",
                    "",
                    "",
                    false,
                    false,
                    1,
                    new Date(),
                    false,
                    [],
                    [],
                    "",
                    loggedUserId,
                    null,
                    [],
                    null
                  );
                  var fascicolo = new fascicoloModel(
                    0,
                    tenantId,
                    aziendaId,
                    "",
                    null,
                    false,
                    null,
                    null,
                    [],
                    "",
                    "",
                    loggedUserId
                  );

                  //Verifico l'esistenza del CF
                  const anagByCfResponse = await apiAnagByCf.fetch({
                    tenantId: tenantId,
                    aziendaId: aziendaId,
                    cf: "%" + cf + "%",
                    nome: "%" + nome + "%",
                    cognome: "%" + cognome + "%",
                  });
                  if (anagByCfResponse.data.data.anagrafica.length > 0) {
                    anagByCfResponse.data.data.anagrafica.forEach((item) => {
                      anagraficaPg = new anagraficaModel(
                        item.Id,
                        item.ParentId,
                        item.IsPg,
                        item.Cf,
                        item.Nome,
                        item.Cognome,
                        item.GenereId,
                        item.NascitaLuogo,
                        item.NascitaData,
                        item.Professione,
                        item.Denominazione,
                        item.PIva,
                        item.ReaComune,
                        item.ReaN,
                        item.AddrPaeseId,
                        item.AddrProvinciaId,
                        item.AddrProvincia,
                        item.AddrComuneId,
                        item.AddrComune,
                        item.AddrIndirizzo,
                        item.AddrN,
                        item.AddrCap,
                        item.Addr2PaeseId,
                        item.Addr2ProvinciaId,
                        item.Addr2Provincia,
                        item.Addr2ComuneId,
                        item.Addr2Comune,
                        item.Addr2Indirizzo,
                        item.Addr2N,
                        item.Addr2Cap,
                        item.IsPep,
                        item.IsNotPepMoreThanYear,
                        item.VersionN,
                        item.VersionDate,
                        item.IsDeleted,
                        [],
                        [],
                        item.PepCarica,
                        item.CreatedBy,
                        item.ModifiedBy,
                        item.anagrafica_fascicolos,
                        item.WorkspaceId
                      );

                      const tipoAnagId1 = baseTipoAnagList.filter(
                        (x) => x.key === "tipo_anagrafica_1"
                      )[0].id;
                      const tipoAnagId2 = baseTipoAnagList.filter(
                        (x) => x.key === "tipo_anagrafica_2"
                      )[0].id;
                      const isCliente =
                        item.anagrafica_fascicolos.filter(
                          (x) =>
                            x.base_tipo_anagrafica.Id === tipoAnagId1 ||
                            x.base_tipo_anagrafica.Id === tipoAnagId2
                        ).length > 0;
                      
                      if (isCliente) {
                        const fascAsCliente = item.anagrafica_fascicolos.filter(
                          (x) =>
                            x.base_tipo_anagrafica.Id === tipoAnagId1 ||
                            x.base_tipo_anagrafica.Id === tipoAnagId2
                        )[0];
                        fascicolo = new fascicoloModel(
                          fascAsCliente.fascicolo.Id,
                          tenantId,
                          aziendaId,
                          fascAsCliente.fascicolo.Name,
                          fascAsCliente.fascicolo.Date,
                          false,
                          null,
                          null,
                          [],
                          "",
                          "",
                          fascAsCliente.fascicolo.CreatedBy,
                          fascAsCliente.fascicolo.ModifiedBy
                        );
                      }
                    });
                  }

                  if(anagraficaPg.cf && anagraficaPg.cf.length > 50 || anagraficaPg.PIva && anagraficaPg.PIva.length > 50){
                    setImportResultList([]);
                    enqueueSnackbar("Spiacente ma il file che si sta utilizzando per effettuare l'import dei dati non è compatibile con la procedura", { variant: "error" });
                    setBackDropOpen(false);
                  }

                  arrAnagItems.push({
                    anagrafica: anagraficaPg,
                    fascicolo: fascicolo,
                    fileName: fileName,
                  });
                }
              }
            }
          } else if (parsedPdfText.includes("Visura per soggetto")) {
            var intestatari = parsedPdfText.match(
              /(?<=DIRITTI E ONERI REALI)(.*?)(?=Proprieta` per)/
            );
            var intestatario = intestatari[0];

            var isPg = false;
            var nomeCompleto = intestatario
              .trimStart()
              .trimEnd()
              .match(/(?<=\d)(.*?)(?=nat)/);

            var cognome = "";
            var cognomeArr = nomeCompleto[0].match(/[A-Z]/g);
            cognomeArr.forEach((letter) => {
              cognome = cognome + letter;
            });
            cognome = cognome
              .substring(0, cognome.length - 1)
              .trimStart()
              .trimEnd();

            var nome = "";
            nome = nomeCompleto[0].replace(cognome, "").trimStart().trimEnd();

            var genereId = null;
            var bornLocation = "";
            var bornLocsF = intestatario.match(/(?<=nata a)(.*?)(?=il )/);
            if (bornLocsF != null && bornLocsF.length > 0) {
              var bornLocCities = bornLocsF[0].trimStart().trimEnd();
              bornLocation =
                bornLocCities !== null && bornLocCities.length > 0
                  ? bornLocCities.trimStart().trimEnd()
                  : "";
              const baseGenereListResponse = await apiBaseGenere.fetch({
                tenantId: tenantId,
              });
              if (baseGenereListResponse.data.data.base_genere.length > 0) {
                genereId = baseGenereListResponse.data.data.base_genere.filter(
                  (x) => x.Key === "base_genere_2"
                )[0].Id;
              }
            } else {
              var bornLocsM = intestatario.match(/(?<=nato a)(.*?)(?=il )/);
              if (bornLocsM !== null && bornLocsM.length > 0) {
                var bornLocCities = bornLocsM[0].trimStart().trimEnd();
                bornLocation =
                  bornLocCities !== null && bornLocCities.length > 0
                    ? bornLocCities.trimStart().trimEnd()
                    : "";
                const baseGenereListResponse = await apiBaseGenere.fetch({
                  tenantId: tenantId,
                });
                if (baseGenereListResponse.data.data.base_genere.length > 0) {
                  genereId =
                    baseGenereListResponse.data.data.base_genere.filter(
                      (x) => x.Key === "base_genere_1"
                    )[0].Id;
                }
              }
            }

            var bornDate = "";
            var bornDateL = intestatario.match(
              "[0-9]{2}([-/ .])[0-9]{2}[-/ .][0-9]{4}"
            );
            bornDate =
              bornDateL !== null && bornDateL.length > 0
                ? bornDateL[0].trimStart().trimEnd()
                : "";
            var bornDateD = null;
            if (bornDate !== "") {
              var dateParts = bornDate.split("/");
              bornDateD = new Date(
                +dateParts[2],
                dateParts[1] - 1,
                +dateParts[0]
              );
            }

            var cf = "";
            var cfL = intestatario.match(
              /(?<=[0-9]{2}([\-/ \.])[0-9]{2}[\-/ \.][0-9]{4})(.*?)(?=\*)/
            );
            cf =
              cfL !== null && cfL.length > 0
                ? cfL[0].trimStart().trimEnd()
                : "";

            //COSTRUISCO I MODELS
            //Anagrafica
            var anagraficaPg = new anagraficaModel(
              0,
              0,
              isPg,
              cf,
              nome,
              cognome,
              genereId,
              bornLocation,
              bornDateD,
              "",
              "",
              "",
              "",
              "",
              null,
              null,
              "",
              null,
              "",
              "",
              "",
              "",
              null,
              null,
              "",
              null,
              "",
              "",
              "",
              "",
              false,
              false,
              1,
              new Date(),
              false,
              [],
              [],
              "",
              loggedUserId,
              null,
              [],
              null
            );
            var fascicolo = new fascicoloModel(
              0,
              tenantId,
              aziendaId,
              "",
              null,
              false,
              null,
              null,
              [],
              "",
              "",
              loggedUserId
            );

            //Verifico l'esistenza del CF
            const anagByCfResponse = await apiAnagByCf.fetch({
              tenantId: tenantId,
              aziendaId: aziendaId,
              cf: "%" + cf + "%",
              nome: "%" + nome + "%",
              cognome: "%" + cognome + "%",
            });
            if (anagByCfResponse.data.data.anagrafica.length > 0) {
              anagByCfResponse.data.data.anagrafica.forEach((item) => {
                anagraficaPg = new anagraficaModel(
                  item.Id,
                  item.ParentId,
                  item.IsPg,
                  item.Cf,
                  item.Nome,
                  item.Cognome,
                  item.GenereId,
                  item.NascitaLuogo,
                  item.NascitaData,
                  item.Professione,
                  item.Denominazione,
                  item.PIva,
                  item.ReaComune,
                  item.ReaN,
                  item.AddrPaeseId,
                  item.AddrProvinciaId,
                  item.AddrProvincia,
                  item.AddrComuneId,
                  item.AddrComune,
                  item.AddrIndirizzo,
                  item.AddrN,
                  item.AddrCap,
                  item.Addr2PaeseId,
                  item.Addr2ProvinciaId,
                  item.Addr2Provincia,
                  item.Addr2ComuneId,
                  item.Addr2Comune,
                  item.Addr2Indirizzo,
                  item.Addr2N,
                  item.Addr2Cap,
                  item.IsPep,
                  item.IsNotPepMoreThanYear,
                  item.VersionN,
                  item.VersionDate,
                  item.IsDeleted,
                  [],
                  [],
                  item.PepCarica,
                  item.CreatedBy,
                  item.ModifiedBy,
                  item.anagrafica_fascicolos,
                  item.WorkspaceId
                );

                const tipoAnagId1 = baseTipoAnagList.filter(
                  (x) => x.key === "tipo_anagrafica_1"
                )[0].id;
                const tipoAnagId2 = baseTipoAnagList.filter(
                  (x) => x.key === "tipo_anagrafica_2"
                )[0].id;
                const isCliente =
                  item.anagrafica_fascicolos.filter(
                    (x) =>
                      x.base_tipo_anagrafica.Id === tipoAnagId1 ||
                      x.base_tipo_anagrafica.Id === tipoAnagId2
                  ).length > 0;
                // var isCliente = false;
                // const tipoAnagResult = baseTipoAnagList.filter(x => x.id === item.base_tipo_anagrafica.Id);

                // if (tipoAnagResult.length > 0) {
                //     const tipoAnagItem = tipoAnagResult[0].key;
                //     isCliente = tipoAnagItem === "tipo_anagrafica_1" || tipoAnagItem === "tipo_anagrafica_2";
                // }

                if (isCliente) {
                  const fascAsCliente = item.anagrafica_fascicolos.filter(
                    (x) =>
                      x.base_tipo_anagrafica.Id === tipoAnagId1 ||
                      x.base_tipo_anagrafica.Id === tipoAnagId2
                  )[0];
                  fascicolo = new fascicoloModel(
                    fascAsCliente.fascicolo.Id,
                    tenantId,
                    aziendaId,
                    fascAsCliente.fascicolo.Name,
                    fascAsCliente.fascicolo.Date,
                    false,
                    null,
                    null,
                    [],
                    "",
                    "",
                    loggedUserId
                  );
                }
              });
            }

            if(anagraficaPg.cf && anagraficaPg.cf.length > 50 || anagraficaPg.PIva && anagraficaPg.PIva.length > 50){
              setImportResultList([]);
              enqueueSnackbar("Spiacente ma il file che si sta utilizzando per effettuare l'import dei dati non è compatibile con la procedura", { variant: "error" });
              setBackDropOpen(false);
            }

            arrAnagItems.push({
              anagrafica: anagraficaPg,
              fascicolo: fascicolo,
              fileName: fileName,
            });
          }
        }

        if (arrAnagItems.length <= 0) {
          setShowNoAnagFound(true);
        } else {
          handleNext();
          setImportResultList(arrAnagItems);
        }
      } else {
        enqueueSnackbar(
          "Formato file non supportato. Si prega di selezionare un file PDF.",
          { variant: "error" }
        );
      }
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "FascicoloNew",
        method: "handleUploadeFile",
        version: sharedDatas.getPropertyByName("version"),
      });
      setShowNoAnagFound(false);
      setImportResultList([]);
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
    }
  };
  async function encodeBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);

    return new Promise((resolve) => {
      reader.onload = async () => {
        var base64 = reader.result;
        var getText = await getItems(
          base64.replace("data:application/pdf;base64,", "")
        );

        fileBase64.push({
          name: file.name,
          encrypt: base64,
          text: getText,
        });
        resolve(fileBase64);
      };
    });
  }
  async function getContent(src) {
    const doc = await pdfjs.getDocument({ data: atob(src) }).promise;
    const numPages = doc.numPages;

    var text = [];
    for (var i = 1; i <= numPages; i++) {
      const page = await doc.getPage(i);
      text.push(await page.getTextContent());
    }

    return text;
  }
  async function getItems(src) {
    var text = "";
    const content = await getContent(src);

    content.forEach((item) => {
      item.items.forEach((item) => {
        text = text + " " + item.str;
      });
    });

    return text;
  }

  return (
    <Fragment>
      <VaporPage
        title={t("fascicolo.titoloAdd")}
        headerLeft={
          <Button
            variant="text"
            size="medium"
            color="primary"
            onClick={handleGoHome}
            sx={{ ml: 2 }}
          >
            {t("autovalutazione.autovalutazioneAdd.back")}
          </Button>
        }
      >
        <Loading open={backDropOpen} />

        <Stack
          direction="row"
          spacing={0}
          justifyContent="center"
          alignItems="center"
        >
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            sx={{ mt: 6, width: "500px" }}
          >
            <Step key="1">
              <StepLabel>{t("fascicolo.fascicoloNew.newSearch1")}</StepLabel>
            </Step>
            <Step key="2">
              <StepLabel>{t("fascicolo.fascicoloNew.verify")}</StepLabel>
            </Step>
          </Stepper>
        </Stack>

        {activeStep === 0 ? (
          <Stack
            direction="column"
            spacing={2}
            sx={{ p: 4, mt: 2 }}
            justifyContent="center"
            alignItems="center"
          >
            <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
              <Grid container spacing={2}>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>
                  <Grid item xs={12} sx={{ mb: 2 }}>
                    <FormGroup>
                      <div>
                        <FormControlLabel
                          control={
                            <Radio
                                checked={selectedValue === "PG"}
                                onChange={handleChange}
                                value="Perrsona Giuridica"
                                name="radio-buttons"
                                id="checkPg"
                                inputProps={{ "aria-label": "Persona giuridica" }}                              
                              />                                                                                
                          }
                          label="Persona giuridica o ditta individuale"
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              checked={selectedValue === "PF"}
                              onChange={handleChange}
                              value="Persona Fisica"
                              name="radio-buttons"
                              id="checkPf"
                              inputProps={{ "aria-label": "Persona fisica" }}                            
                            />                                                                              
                          }
                          label="Persona fisica"
                        />                                                 
                      </div>
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12} sx={{ mb: 2 }}>
                    <TextField
                      id="txtSearchCf"
                      label={t("anagrafica.ImportDesktop.codFisc")}
                      size="small"
                      onChange={handleChange}
                      inputProps={{ autocomplete: "nope" }}
                      required
                                            
                    />
                  </Grid>

                  {!isPG ? (
                    <Fragment>
                      <Grid item xs={12} sx={{ mb: 2 }}>
                        <TextField
                          id="txtSearchCognome"
                          label={t("impostazioni.utenti.form.surname")}
                          size="small"
                          onChange={handleChange}
                          inputProps={{ autocomplete: "nope" }}
                        />
                      </Grid>
                      <Grid item xs={12} sx={{ mb: 2 }}>
                        <TextField
                          id="txtSearchNome"
                          label={t("impostazioni.utenti.form.name")}
                          size="small"
                          onChange={handleChange}
                          inputProps={{ autocomplete: "nope" }}
                        />
                      </Grid>                      
                    </Fragment>
                  ) : (
                    <Grid item xs={12} sx={{ mb: 2 }}>
                      <TextField
                        id="txtSearchDenominazione"
                        label={t("anagrafica.ImportDesktop.denom")}
                        size="small"
                        onChange={handleChange}
                        inputProps={{ autocomplete: "nope" }}
                      />
                    </Grid>
                  )}                  

                  {isPG ? (
                    <Grid item xs={12} sx={{ mb: 2 }}>
                      <TextField
                        id="txtSearchPIva"
                        label={t("fascicolo.fascicoloNew.vatNumber")}
                        size="small"
                        onChange={handleChange}
                        inputProps={{ autocomplete: "nope" }}
                      />
                    </Grid>
                  ) : null}

                  <Grid item xs={12} sx={{ textAlign: "center" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSearchClick}
                      endIcon={
                        <SearchIcon sx={{ color: "#FFFFFF !important" }} />
                      }
                    >
                      {t("fascicolo.fascicoloAdd.cerca")}
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={4}></Grid>
              </Grid>
            </Stack>

            {showNoAnagFound ? (
              <Alert severity="error">
                {t("fascicolo.fascicoloNew.notFound")}
              </Alert>
            ) : null}
            <Divider orientation="horizontal" variant="standard" flexItem>
              {t("fascicolo.fascicoloNew.or")}
            </Divider>
            <VaporUploader
              buttonVariant="contained"
              draggable
              multiple
              inputFileProps={{ accept: "application/pdf" }}
              label={t("fascicolo.fascicoloNew.uploadFiles")}
              draggableText={t("fascicolo.fascicoloNew.dragFiles")}
              dropText={t("fascicolo.fascicoloNew.dropFiles")}
              buttonLabel={t("fascicolo.fascicoloNew.selectFiles")}
              onFileUpload={handleUploadeFile}
            />
          </Stack>
        ) : null}

        {activeStep === 1 ? (
          <Stack
            direction="column"
            spacing={2}
            sx={{ p: 4, mt: 2 }}
            justifyContent="center"
            alignItems="center"
          >
            <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
              {!anagFound &&
              !anagWSFound &&
              !fascicoloFound &&
              importResultList.length <= 0 ? (
                <Grid container spacing={2}>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={4}>
                    <Grid item xs={12} sx={{ mb: 2 }}>
                      <Alert variant="outlined" severity="warning">
                        {t("fascicolo.fascicoloNew.notFoundExistingRecord")}
                      </Alert>
                    </Grid>

                    <Grid item xs={12} sx={{ mb: 2 }}>
                    <FormGroup>
                      <div>
                        <FormControlLabel
                          control={
                            <Radio
                                checked={selectedValue === "PG"}
                                onChange={handleChange}
                                value="Perrsona Giuridica"
                                name="radio-buttons"
                                id="checkPg"
                                inputProps={{ "aria-label": "Persona giuridica" }}                              
                              />                                                                                
                          }
                          label="Persona giuridica o ditta individuale"
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              checked={selectedValue === "PF"}
                              onChange={handleChange}
                              value="Persona Fisica"
                              name="radio-buttons"
                              id="checkPf"
                              inputProps={{ "aria-label": "Persona fisica" }}                            
                            />                                                                              
                          }
                          label="Persona fisica"
                        />                                                 
                      </div>
                    </FormGroup>
                    </Grid>

                    <Grid item xs={12} sx={{ mb: 2 }}>
                      <TextField
                        id="txtCf"
                        label="Codice Fiscale"
                        size="small"
                        required
                        defaultValue={currentAnagrafica.cf}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{ autocomplete: "nope" }}
                        readOnly
                      />
                    </Grid>

                    {!isPG ? (
                      <Fragment>
                        <Grid item xs={12} sx={{ mb: 2 }}>
                          <TextField
                            id="txtCognome"
                            label="Cognome"
                            size="small"
                            required
                            defaultValue={currentAnagrafica.cognome}
                            onChange={handleChange}
                            inputProps={{ autocomplete: "nope" }}
                          />
                        </Grid>
                        <Grid item xs={12} sx={{ mb: 2 }}>
                          <TextField
                            id="txtNome"
                            label="Nome"
                            size="small"
                            required
                            defaultValue={currentAnagrafica.nome}
                            onChange={handleChange}
                            inputProps={{ autocomplete: "nope" }}
                          />
                        </Grid>
                      </Fragment>
                    ) : (
                      <Grid item xs={12} sx={{ mb: 2 }}>
                        <TextField
                          id="txtDenominazione"
                          label="Denominazione"
                          size="small"
                          required
                          defaultValue={currentAnagrafica.denominazione}
                          onChange={handleChange}
                          inputProps={{ autocomplete: "nope" }}
                        />
                      </Grid>
                    )}                    

                    {isPG ? (
                      <Grid item xs={12} sx={{ mb: 2 }}>
                        <TextField
                          id="txtPIva"
                          label="P. Iva"
                          size="small"
                          required={isPG}
                          defaultValue={currentAnagrafica.pIva}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          inputProps={{ autocomplete: "nope" }}
                        />
                      </Grid>
                    ) : null}

                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          handleCreateFascicolo(
                            currentAnagrafica,
                            files.length > 1,
                            ""
                          )
                        }
                        disabled={
                          isPG
                            ? currentAnagrafica.cf === "" ||
                              currentAnagrafica.pIva === "" ||
                              currentAnagrafica.denominazione === ""
                            : currentAnagrafica.cf === "" ||
                              currentAnagrafica.nome === "" ||
                              currentAnagrafica.cognome === ""
                        }
                      >
                        {t("fascicolo.fascicoloNew.creaFasicolo")}
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}></Grid>
                </Grid>
              ) : null}

              {anagFound && !fascicoloFound ? (
                <Grid container spacing={2}>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={4}>
                    <Grid item xs={12}>
                      <Alert
                        variant="outlined"
                        severity="success"
                        sx={{ mb: 3 }}
                      >
                        {t("fascicolo.fascicoloNew.matches")}
                      </Alert>
                    </Grid>
                    <Grid item xs={12}>
                      {anagResultList.map((item) => (
                        <ExtendedAccordion
                          key={uuidv4()}
                          sx={{ width: "100%" }}
                        >
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <ExtendedTypography
                              variant="titleSmall"
                              color="primary.main"
                              sx={{ width: "100% !important" }}
                            >
                              {item.cf} |{" "}
                              {item.isPg
                                ? item.denominazione
                                : item.nome + " " + item.cognome}
                            </ExtendedTypography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Stack direction="column" spacing={2}>
                              <ExtendedTypography
                                variant="body"
                                color="primary.main"
                                sx={{ width: "100% !important" }}
                              >
                                {item.isPg
                                  ? item.denominazione
                                  : item.nome + " " + item.cognome}
                                <br />
                                {item.isPg
                                  ? "C.F.: " +
                                    item.cf +
                                    " - P. Iva: " +
                                    item.pIva
                                  : "C.F.: " + item.cf}
                                <br />
                                {item.isPg
                                  ? "Iscritta al R.I. di " +
                                    item.reaComune +
                                    " - N.: " +
                                    item.reaN
                                  : null}
                                <br />
                                {item.isPg
                                  ? "Sede legale: " +
                                    item.addrIndirizzo +
                                    " " +
                                    item.addrN +
                                    " - " +
                                    item.addrCap +
                                    " " +
                                    item.addrComune +
                                    " (" +
                                    item.addrProvincia +
                                    ")"
                                  : "Indirizzo di residenza: " +
                                    item.addrIndirizzo +
                                    " " +
                                    item.addrN +
                                    " - " +
                                    item.addrCap +
                                    " " +
                                    item.addrComune +
                                    " (" +
                                    item.addrProvincia +
                                    ")"}
                              </ExtendedTypography>
                              <Button
                                variant="contained"
                                size="small"
                                color="primary"
                                onClick={() =>
                                  handleCreateFascicolo(
                                    item,
                                    files.length > 1,
                                    ""
                                  )
                                }
                              >
                                {t("fascicolo.fascicoloNew.creaFasicolo")}
                              </Button>
                            </Stack>
                          </AccordionDetails>
                        </ExtendedAccordion>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid item xs={4}></Grid>
                </Grid>
              ) : null}

              {anagFound && fascicoloFound ? (
                <Grid container spacing={2}>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={4}>
                    <Grid item xs={12}>
                      <Alert
                        variant="outlined"
                        severity="success"
                        sx={{ mb: 3 }}
                      >
                        {t("fascicolo.fascicoloNew.matchesFolder")}
                      </Alert>
                    </Grid>
                    <Grid item xs={12}>
                      {anagResultList.map((item) => (
                        <ExtendedAccordion
                          key={uuidv4()}
                          sx={{ width: "100%" }}
                        >
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <ExtendedTypography
                              variant="titleSmall"
                              color="primary.main"
                              sx={{ width: "100% !important" }}
                            >
                              {item.cf} |{" "}
                              {item.isPg
                                ? item.denominazione
                                : item.nome + " " + item.cognome}
                            </ExtendedTypography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Stack direction="column" spacing={2}>
                              <ExtendedTypography
                                variant="body"
                                color="primary.main"
                                sx={{ width: "100% !important" }}
                              >
                                {item.isPg
                                  ? item.denominazione
                                  : item.nome + " " + item.cognome}
                                <br />
                                {item.isPg
                                  ? "C.F.: " +
                                    item.cf +
                                    " - P. Iva: " +
                                    item.pIva
                                  : "C.F.: " + item.cf}
                                <br />
                                {item.isPg
                                  ? "Iscritta al R.I. di " +
                                    item.reaComune +
                                    " - N.: " +
                                    item.reaN
                                  : null}
                                <br />
                                {item.isPg
                                  ? "Sede legale: " +
                                    item.addrIndirizzo +
                                    " " +
                                    item.addrN +
                                    " - " +
                                    item.addrCap +
                                    " " +
                                    item.addrComune +
                                    " (" +
                                    item.addrProvincia +
                                    ")"
                                  : "Indirizzo di residenza: " +
                                    item.addrIndirizzo +
                                    " " +
                                    item.addrN +
                                    " - " +
                                    item.addrCap +
                                    " " +
                                    item.addrComune +
                                    " (" +
                                    item.addrProvincia +
                                    ")"}
                              </ExtendedTypography>
                              <Button
                                variant="contained"
                                size="small"
                                color="primary"
                                onClick={() => handleGoToFascicolo(-1)}
                              >
                                {t("fascicolo.fascicoloNew.goToFolder")}
                              </Button>
                            </Stack>
                          </AccordionDetails>
                        </ExtendedAccordion>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid item xs={4}></Grid>
                </Grid>
              ) : null}

              {anagWSFound ? (
                <Grid container spacing={2}>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={4}>
                    <Grid item xs={12}>
                      <Alert
                        variant="outlined"
                        severity="success"
                        sx={{ mb: 3 }}
                      >
                        {t("fascicolo.fascicoloNew.matches")}
                      </Alert>
                    </Grid>
                    <Grid item xs={12}>
                      {anagWSResultList.map((item) => (
                        <ExtendedAccordion
                          key={uuidv4()}
                          sx={{ width: "100%" }}
                        >
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <ExtendedTypography
                              variant="titleSmall"
                              color="primary.main"
                              sx={{ width: "100% !important" }}
                            >
                              {item.cf} |{" "}
                              {item.isPg
                                ? item.denominazione
                                : item.nome + " " + item.cognome}
                            </ExtendedTypography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Stack direction="column" spacing={2}>
                              <ExtendedTypography
                                variant="body"
                                color="primary.main"
                                sx={{ width: "100% !important" }}
                              >
                                {item.isPg
                                  ? item.denominazione
                                  : item.nome + " " + item.cognome}
                                <br />
                                {item.isPg
                                  ? "C.F.: " +
                                    item.cf +
                                    " - P. Iva: " +
                                    item.pIva
                                  : "C.F.: " + item.cf}
                                <br />
                                {item.isPg
                                  ? "Iscritta al R.I. di " +
                                    item.reaComune +
                                    " - N.: " +
                                    item.reaN
                                  : null}
                                <br />
                                {item.isPg
                                  ? "Sede legale: " +
                                    item.addrIndirizzo +
                                    " " +
                                    item.addrN +
                                    " - " +
                                    item.addrCap +
                                    " " +
                                    item.addrComune +
                                    " (" +
                                    item.addrProvincia +
                                    ")"
                                  : "Indirizzo di residenza: " +
                                    item.addrIndirizzo +
                                    " " +
                                    item.addrN +
                                    " - " +
                                    item.addrCap +
                                    " " +
                                    item.addrComune +
                                    " (" +
                                    item.addrProvincia +
                                    ")"}
                              </ExtendedTypography>
                              <Button
                                variant="contained"
                                size="small"
                                color="primary"
                                onClick={() =>
                                  handleCreateFascicolo(
                                    item,
                                    files.length > 1,
                                    ""
                                  )
                                }
                              >
                                {t("fascicolo.fascicoloNew.creaFasicolo")}
                              </Button>
                            </Stack>
                          </AccordionDetails>
                        </ExtendedAccordion>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid item xs={4}></Grid>
                </Grid>
              ) : null}

              {importResultList.length > 0 ? (
                <Grid container spacing={2}>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={4}>
                    <Grid item xs={12}>
                      {importResultList.map((item) => (
                        <ExtendedAccordion
                          key={uuidv4()}
                          sx={{ width: "100%" }}
                        >
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <ExtendedTypography
                              variant="titleSmall"
                              color="primary.main"
                              sx={{ width: "100% !important" }}
                            >
                              {item.anagrafica.cf} |{" "}
                              {item.anagrafica.isPg
                                ? item.anagrafica.denominazione
                                : item.anagrafica.nome +
                                  " " +
                                  item.anagrafica.cognome}
                            </ExtendedTypography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Stack direction="column" spacing={2}>
                              <ExtendedTypography
                                variant="body"
                                color="primary.main"
                                sx={{ width: "100% !important" }}
                              >
                                {item.anagrafica.isPg
                                  ? item.anagrafica.denominazione
                                  : item.anagrafica.nome +
                                    " " +
                                    item.anagrafica.cognome}
                                <br />
                                {item.anagrafica.isPg
                                  ? "C.F.: " +
                                    item.anagrafica.cf +
                                    " - P. Iva: " +
                                    item.anagrafica.pIva
                                  : "C.F.: " + item.anagrafica.cf}
                                <br />
                                {!item.anagrafica.isPg
                                  ? "Nato a " +
                                    item.anagrafica.nascitaLuogo +
                                    " il " +
                                    new Date(
                                      item.anagrafica.nascitaData
                                    ).toLocaleDateString()
                                  : ""}
                                <br />
                                {item.anagrafica.isPg
                                  ? "Iscritta al R.I. di " +
                                    item.anagrafica.reaComune +
                                    " - N.: " +
                                    item.anagrafica.reaN
                                  : null}
                                <br />
                                {item.anagrafica.isPg
                                  ? "Sede legale: " +
                                    item.anagrafica.addrIndirizzo +
                                    " " +
                                    item.anagrafica.addrN +
                                    " - " +
                                    item.anagrafica.addrCap +
                                    " " +
                                    item.anagrafica.addrComune +
                                    " (" +
                                    item.anagrafica.addrProvincia +
                                    ")"
                                  : "Indirizzo di residenza: " +
                                    item.anagrafica.addrIndirizzo +
                                    " " +
                                    item.anagrafica.addrN +
                                    " - " +
                                    item.anagrafica.addrCap +
                                    " " +
                                    item.anagrafica.addrComune +
                                    " (" +
                                    item.anagrafica.addrProvincia +
                                    ")"}
                              </ExtendedTypography>
                              {item.fascicolo.id > 0 ? (
                                <Button
                                  variant="contained"
                                  size="small"
                                  color="primary"
                                  onClick={() =>
                                    handleGoToFascicolo(item.fascicolo.id, true)
                                  }
                                >
                                  {t("fascicolo.fascicoloNew.goToFolder")}
                                </Button>
                              ) : (
                                <Button
                                  variant="contained"
                                  size="small"
                                  color="primary"
                                  onClick={() =>
                                    handleCreateFascicolo(
                                      item.anagrafica,
                                      files.length > 1,
                                      item.fileName
                                    )
                                  }
                                >
                                  {t("fascicolo.fascicoloNew.creaFasicolo")}
                                </Button>
                              )}
                            </Stack>
                          </AccordionDetails>
                        </ExtendedAccordion>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid item xs={4}></Grid>
                </Grid>
              ) : null}
            </Stack>
          </Stack>
        ) : null}
      </VaporPage>

      {activeStep == 1 ? (
        <VaporToolbar
          variant="regular"
          size="medium"
          withoutAppBar={false}
          contentRight={
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={handleNewSearch}
              >
                {t("fascicolo.fascicoloNew.newSearch")}
              </Button>
            </Stack>
          }
        ></VaporToolbar>
      ) : null}
    </Fragment>
  );
};
