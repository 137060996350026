import { NavContent } from "./NavContent";
import { AutovalutazioneHome } from "./AutovalutazioneHome";
import { AutovalutazioneAdd } from "./AutovalutazioneAdd";
import { pathAutoValutazioneHome, pathAutoValutazioneAdd, anchorOrigin, autoHideDuration, maxSnack } from "../../businessLogic/constants";
import { SnackbarProvider } from "notistack";

export const autovalutazione = () => [
  {
    target: "$ONE_LAYOUT_NAV_CONTENT",
    handler: {
      component: NavContent,
      props: {
        basePath: pathAutoValutazioneHome
      }
    }
  },
  {
    target: "$ONE_LAYOUT_ROUTE",
    handler: {
      exact: true,
      path: pathAutoValutazioneHome,
      element: <SnackbarProvider anchorOrigin={anchorOrigin} autoHideDuration={autoHideDuration} maxSnack={maxSnack}><AutovalutazioneHome basePath={pathAutoValutazioneHome} /></SnackbarProvider>
    }
  },
  {
    target: "$ONE_LAYOUT_ROUTE",
    handler: {
      exact: true,
      path: pathAutoValutazioneAdd,
      element: <SnackbarProvider anchorOrigin={anchorOrigin} autoHideDuration={autoHideDuration} maxSnack={maxSnack}><AutovalutazioneAdd basePath={pathAutoValutazioneHome} /></SnackbarProvider>
    }
  }
];
