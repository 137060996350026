import { NavContent } from "./NavContent";
import { AziendaHome } from "./AziendaHome";
import { AziendaAdd } from "./AziendaAdd";
import { pathAziendaHome, pathAziendaAdd, anchorOrigin, autoHideDuration, maxSnack } from "../../businessLogic/constants";
import { SnackbarProvider } from "notistack";

export const azienda = () => [
  {
    target: "$ONE_LAYOUT_ROUTE",
    handler: {
      exact: true,
      path: pathAziendaHome,
      element: <SnackbarProvider anchorOrigin={anchorOrigin} autoHideDuration={autoHideDuration} maxSnack={maxSnack}><AziendaHome basePath={pathAziendaHome} /></SnackbarProvider>
    }
  },
  {
    target: "$ONE_LAYOUT_ROUTE",
    handler: {
      exact: true,
      path: pathAziendaAdd,
      element: <SnackbarProvider anchorOrigin={anchorOrigin} autoHideDuration={autoHideDuration} maxSnack={maxSnack}><AziendaAdd basePath={pathAziendaHome} /></SnackbarProvider>
    }
  }
];
