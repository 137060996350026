import { Link } from "react-router-dom";
import { useTranslation } from "@onefront/react-sdk";

//Vapor Components
import List from "@vapor/react-material/List";
import ListItem from "@vapor/react-material/ListItem";
import ListItemIcon from "@vapor/react-material/ListItemIcon";
import ListItemText from "@vapor/react-material/ListItemText";
import Box from "@vapor/react-material/Box";
import Tooltip from "@vapor/react-material/Tooltip";

//Icons
import { Inventory as InventoryIcon } from "@mui/icons-material";

export const NavContent = ({ basePath }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ width: 250 }} role="presentation">
      <List sx={{ pb: 0 }}>
        <ListItem
          classes="menuListItemStyle"
          button
          component={Link}
          to={basePath}
        >
          <ListItemIcon>
            <Tooltip title={t("nav.fascicoli")} placement="right" arrow>
              <InventoryIcon />
            </Tooltip>
          </ListItemIcon>
          <ListItemText primary={t("nav.fascicoli")} />
        </ListItem>
      </List>
    </Box>
  );
};
