import { NavContent } from "./NavContent";
import { FascicoloHome } from "./FascicoloHome";
import { FascicoloNew } from "./FascicoloNew";
import { FascicoloAdd } from "./FascicoloAdd";
import { pathFascicoloHome, pathFascicoloNew, pathFascicoloAdd, anchorOrigin, autoHideDuration, maxSnack } from "../../businessLogic/constants";
import { SnackbarProvider } from "notistack";

export const fascicolo = () => [
  {
    target: "$ONE_LAYOUT_NAV_CONTENT",
    handler: {
      component: NavContent,
      props: {
        basePath: pathFascicoloHome
      }
    }
  },
  {
    target: "$ONE_LAYOUT_ROUTE",
    handler: {
      exact: true,
      path: pathFascicoloHome,
      element: <SnackbarProvider anchorOrigin={anchorOrigin} autoHideDuration={autoHideDuration} maxSnack={maxSnack}><FascicoloHome basePath={pathFascicoloHome} /></SnackbarProvider>
    }
  },
  {
    target: "$ONE_LAYOUT_ROUTE",
    handler: {
      exact: true,
      path: pathFascicoloNew,
      element: <SnackbarProvider anchorOrigin={anchorOrigin} autoHideDuration={autoHideDuration} maxSnack={maxSnack}><FascicoloNew basePath={pathFascicoloNew} /></SnackbarProvider>
    }
  },
  {
    target: "$ONE_LAYOUT_ROUTE",
    handler: {
      exact: true,
      path: pathFascicoloAdd,
      element: <SnackbarProvider anchorOrigin={anchorOrigin} autoHideDuration={autoHideDuration} maxSnack={maxSnack}><FascicoloAdd basePath={pathFascicoloHome} /></SnackbarProvider>
    }
  }
];
