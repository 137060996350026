import { NavContent } from "./NavContent";
import { TabellaBaseHome } from "./tabellaBase/TabellaBaseHome";
import { UtenteHome } from "./utente/UtenteHome";
import { AuditHome } from "./audit/AuditHome";
import { ReleaseNotesHome } from "./releaseNotes/ReleaseNotesHome";
import { pathImpAuditHome, pathImpTabBaseHome, pathImpUtenteHome,pathReleaseNotesHome, anchorOrigin, autoHideDuration, maxSnack } from "../../businessLogic/constants";
import { SnackbarProvider } from "notistack";

export const impostazione = () => [
  {
    target: "$ONE_LAYOUT_NAV_CONTENT",
    handler: {
      component: NavContent,
      props: {
        basePathTab: pathImpTabBaseHome, basePathUte: pathImpUtenteHome, basePathAud: pathImpAuditHome, basePathRel : pathReleaseNotesHome
      }
    }
  },
  {
    target: "$ONE_LAYOUT_ROUTE",
    handler: {
      exact: true,
      path: pathImpTabBaseHome,
      element: <SnackbarProvider anchorOrigin={anchorOrigin} autoHideDuration={autoHideDuration} maxSnack={maxSnack}><TabellaBaseHome basePath={pathImpTabBaseHome} /></SnackbarProvider>
    }
  },
  {
    target: "$ONE_LAYOUT_ROUTE",
    handler: {
      exact: true,
      path: pathImpUtenteHome,
      element: <SnackbarProvider anchorOrigin={anchorOrigin} autoHideDuration={autoHideDuration} maxSnack={maxSnack}><UtenteHome basePath={pathImpUtenteHome} /></SnackbarProvider>
    }
  },
  {
    target: "$ONE_LAYOUT_ROUTE",
    handler: {
      exact: true,
      path: pathImpAuditHome,
      element: <SnackbarProvider anchorOrigin={anchorOrigin} autoHideDuration={autoHideDuration} maxSnack={maxSnack}><AuditHome basePath={pathImpAuditHome} /></SnackbarProvider>
    }
  },
  {
    target: "$ONE_LAYOUT_ROUTE",
    handler: {
      exact: true,
      path: pathReleaseNotesHome,
      element: <SnackbarProvider anchorOrigin={anchorOrigin} autoHideDuration={autoHideDuration} maxSnack={maxSnack}><ReleaseNotesHome basePath={pathReleaseNotesHome} /></SnackbarProvider>
    }
  }
];
