import { NavContent } from "./NavContent";
import { RischioHome } from "./RischioHome";
import { pathRischioHome, anchorOrigin, autoHideDuration, maxSnack } from "../../businessLogic/constants";
import { SnackbarProvider } from "notistack";

export const rischio = () => [
  {
    target: "$ONE_LAYOUT_NAV_CONTENT",
    handler: {
      component: NavContent,
      props: {
        basePath: pathRischioHome
      }
    }
  },
  {
    target: "$ONE_LAYOUT_ROUTE",
    handler: {
      exact: true,
      path: pathRischioHome,
      element: <SnackbarProvider anchorOrigin={anchorOrigin} autoHideDuration={autoHideDuration} maxSnack={maxSnack}><RischioHome basePath={pathRischioHome} /></SnackbarProvider>
    }
  }
];
