import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "@onefront/react-sdk";

//Vapor Components
import Stack from "@vapor/react-material/Stack";
import Box from "@vapor/react-material/Box";
import Button from "@vapor/react-material/Button";
import DataGrid from "@vapor/react-x-data-grid/DataGrid"
import Modal from "@vapor/react-material/Modal";
import Divider from "@vapor/react-material/Divider";
import ExtendedTypography from "@vapor/react-extended/ExtendedTypography";

//Style
import { modalStyleLarge } from "../businessLogic/styles";

export const ModalSearchResultList = ({ show, onClose, onOk,anagToShowList }) => { 
    const { t } = useTranslation();
    const [dataRows,setDataRows] = useState([])     
    const [selectedItemId,setSelectedItemId] = useState([])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect (()=> {        
        setDataRows(anagToShowList)
    })
    //Actions
    const handleModalClose = (e) => {
        onClose(e);
    };
    const handleModalOk = (e,selectedItem) => {
        onOk(e,selectedItem);
    };
    const handleSelectedRow = (selItem,e) => {
        setSelectedItemId(selItem[0])
    };

    return (
        <Fragment>
        <Modal
            key="searchResultModal"
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            open={show}
            onClose={handleModalClose}
            onBackdropClick={handleModalClose}
        >
            <Box sx={modalStyleLarge}>
                <Stack
                    direction="column"
                    spacing={0}
                    justifyContent="left"
                    alignItems="left"
                >
                    <ExtendedTypography
                        variant="titleSmall"
                        color="primary.main"
                        sx={{ p: 2, pl: 3 }}
                    >
                        Rislutati della ricerca
                    </ExtendedTypography>
                    <Divider
                        orientation="horizontal"
                        flexItem                       
                    />                            
                </Stack>

            
                {dataRows.length > 0 &&            
                    <DataGrid 
                        columns={[{field: "Id", hideable: true, minWidth: 200},{field: "Nome", minWidth: 200},{field: "Cognome", minWidth: 50},{field: "Cf", headerName:"Codice Fiscale", minWidth: 200}]}
                        rows={dataRows}
                        getRowId={(row) => row.Id}
                        autoHeight={true}   
                        pageSizeOptions={[10, 15, 20]}                    
                        pagination
                        disableSelectionOnClick
                        onRowSelectionModelChange={(row,event) => handleSelectedRow(row,event)}
                        initialState={{
                        columns: {
                            columnVisibilityModel: {
                            Id: false,
                            },
                        },
                        pagination: {
                            paginationModel: {
                            pageSize: 25,
                            },
                        },
                        }}
                    />               
                }

                <Stack
                    direction="row"
                    spacing={1}
                    sx={{ p: 1, mt: 1, bgcolor: "#F2F5F8" }}
                    justifyContent="right"
                    alignItems="right"
                >
                    <Button variant="outlined" size="small" onClick={handleModalClose}>
                        {t("actions.annulla")}
                    </Button>
                    <Button variant="contained" size="small" onClick={(evt) => handleModalOk(evt,selectedItemId)}>
                        Seleziona
                    </Button>
            </Stack>
            </Box>
        </Modal>
        </Fragment>
    );
};
