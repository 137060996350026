import React, { useState, useEffect, lazy } from "react";
import sharedDatas from "../../../businessLogic/sharedDatas";
import { useNavigate } from "react-router-dom";

//oneFront SDK
import {
  useSnackbar,
  useQuery,
  useMutation,
  useTranslation,
} from "@onefront/react-sdk";

import VaporPage from "@vapor/react-custom/VaporPage";

//Vapor Components
import Grid from "@vapor/react-material/Grid";
import Stack from "@vapor/react-material/Stack";
import Button from "@vapor/react-material/Button";
import IconButton from "@vapor/react-material/IconButton";
import FormControl from "@vapor/react-material/FormControl";
import InputLabel from "@vapor/react-material/InputLabel";
import Select from "@vapor/react-material/Select";
import MenuItem from "@vapor/react-material/MenuItem";
import Divider from "@vapor/react-material/Divider";

import {
  LicenseInfo,
  DataGridPro,
  GridToolbar,
  itIT,
} from "@mui/x-data-grid-pro";

//Icons
import {
  AddOutlined as AddIcon,
  DeleteOutlineOutlined as DeleteIcon,
  ModeEditOutlineOutlined as EditIcon,
  ArrowForward as DetailIcon,
} from "@mui/icons-material";

//Custom components
import { Loading } from "../../../components/Loading";
import { NotAuth } from "../../../components/NotAuth";
import { NoData } from "../../../components/NoData";
import { TabellaBaseForm } from "../../../components/tabellaBase/TabellaBaseForm";
import { ModalDelete } from "../../../components/ModalDelete";

//Constants, Api and Data Models
import {
  bsaSettingsTabellaBase,
  pathImpTabBaseHome,
} from "../../../businessLogic/constants";
import { getTabelleBase } from "../../../businessLogic/bl";
import {
  userPermissionModel,
  baseGenereModel,
  baseMezzoPagamentoModel,
  baseNaturaPrestazioneModel,
  baseNaturaRapportoModel,
  baseOrigineFondiModel,
  baseScopoPrestazioneModel,
  baseTipoDocumentoModel,
  baseTipoIdentificazioneModel,
  baseTipoPersonalitaModel,
} from "../../../businessLogic/models";

import {
  query_base_genere,
  query_base_mezzo_pagamento,
  query_base_natura_prestazione,
  query_base_natura_rapporto,
  query_base_origine_fondi,
  query_base_scopo_prestazione,
  query_base_tipo_documento,
  query_base_tipo_identificazione,
  query_base_tipo_personalita,
  query_base_genere_inAnagrafica,
  query_base_tipo_documento_system,
  query_is_base_tipo_documento_used,
  query_anagrafiche_byTenant,
} from "../../../businessLogic/query";

import {
  mutation_add_log,
  mutation_add_audit,
  mutation_add_lastActivity_byUserId,
  mutation_delete_lastActivity,
  mutation_delete_base_genere,
  mutation_delete_base_mezzo_pagamento,
  mutation_delete_base_natura_prestazione,
  mutation_delete_base_natura_rapporto,
  mutation_delete_base_origine_fondi,
  mutation_delete_base_scopo_prestazione,
  mutation_delete_base_tipo_documento,
  mutation_delete_base_tipo_identificazione,
  mutation_delete_base_tipo_personalita,
} from "../../../businessLogic/mutation";

//Other components
import { v4 as uuidv4 } from "uuid";

export const TabellaBaseHome = ({ basePath }) => {
  const tenantId = sharedDatas.getPropertyByName("tenantId");
  const aziendaId = sharedDatas.getPropertyByName("aziendaId");
  const loggedUserId = sharedDatas.getPropertyByName("userId");
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  //Query
  const apiBaseGenere = useQuery(
    query_base_genere,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseGenereInAnagrafica = useQuery(
    query_base_genere_inAnagrafica,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseMezzoPagamento = useQuery(
    query_base_mezzo_pagamento,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseNaturaPrestazione = useQuery(
    query_base_natura_prestazione,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseNaturaRapporto = useQuery(
    query_base_natura_rapporto,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseOrigineFondi = useQuery(
    query_base_origine_fondi,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseScopoPrestazione = useQuery(
    query_base_scopo_prestazione,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseTipoDocumento = useQuery(
    query_base_tipo_documento,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseTipoIdentificazione = useQuery(
    query_base_tipo_identificazione,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseTipoPersonalita = useQuery(
    query_base_tipo_personalita,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseAllTipoDocumento = useQuery(
    query_base_tipo_documento_system,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiAnagraficheByTenant = useQuery(
    query_anagrafiche_byTenant,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );

  //Mutation
  const apiAddLog = useMutation(mutation_add_log, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddAudit = useMutation(mutation_add_audit, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddLastActivity = useMutation(mutation_add_lastActivity_byUserId, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiDeleteLastActivity = useMutation(mutation_delete_lastActivity, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiDeleteBaseGenere = useMutation(mutation_delete_base_genere, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiDeleteBaseMezzoPagamento = useMutation(
    mutation_delete_base_mezzo_pagamento,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiDeleteBaseNaturaPrestazione = useMutation(
    mutation_delete_base_natura_prestazione,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiDeleteBaseNaturaRapporto = useMutation(
    mutation_delete_base_natura_rapporto,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiDeleteBaseOrigineFondi = useMutation(
    mutation_delete_base_origine_fondi,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiDeleteBaseScopoPrestazione = useMutation(
    mutation_delete_base_scopo_prestazione,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiDeleteBaseTipoDocumento = useMutation(
    mutation_delete_base_tipo_documento,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiDeleteBaseTipoIdentificazione = useMutation(
    mutation_delete_base_tipo_identificazione,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiDeleteBaseTipoPersonalita = useMutation(
    mutation_delete_base_tipo_personalita,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );

  const [backDropOpen, setBackDropOpen] = useState(true); //Loader utilizzato durante il caricamento
  const [isDataLoaded, setIsDataLoaded] = useState(false); //Indica lo stato di caricamento del result Api

  const [isTenantEnabled, setIsTenantEnabled] = useState(false);
  const [userPermissions, setUserPermissions] = useState(
    new userPermissionModel(
      0,
      aziendaId,
      bsaSettingsTabellaBase,
      false,
      false,
      false,
      false
    )
  );
  const [tabelleList, setTabelleList] = useState([]); //Contiene il result completo

  const [showAddModal, setShowAddModal] = useState(false); //Modal per l'aggiunta/modifica di un record
  const [showDeleteModal, setShowDeleteModal] = useState(false); //Modal per la conferma di eliminazione di un record
  const [idToPerform, setIdToPerform] = useState(0); //Contiene l'id del record da eliminare
  const [allBaseTipiDocumento, setAllBaseTipiDocumento] = useState([]);

  useEffect(() => {
    loadDatas();
  }, [isDataLoaded]);
  async function loadDatas() {
    try {
      if (
        sharedDatas.getPropertyByName("tenantId") === "" ||
        sharedDatas.getPropertyByName("tenantModel") === null ||
        sharedDatas.getPropertyByName("aziendaId") === 0 ||
        sharedDatas.getPropertyByName("aziendaModelList") === null ||
        sharedDatas.getPropertyByName("userId") === "" ||
        sharedDatas.getPropertyByName("userModel") === null
      ) {
        navigate("/");
      } else {
        setBackDropOpen(true);

        if (!isDataLoaded) {
          //Last Activity
          await apiDeleteLastActivity.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            userId: loggedUserId,
          });
          await apiAddLastActivity.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            path: pathImpTabBaseHome,
            entityId: "",
            entityName: "",
          });

          /**************************************/
          //Carico le informazioni relative al Tenant
          const tenantInfos = sharedDatas.getPropertyByName("tenantModel");
          const isEnabled =
            !tenantInfos.isReadOnly &&
            !tenantInfos.isDisabled &&
            !tenantInfos.isDeleted;
          setIsTenantEnabled(isEnabled);

          /**************************************/
          //Carico i permessi relativi all'utente
          setUserPermissions(
            sharedDatas
              .getPropertyByName("userPermissions")
              .filter((x) => x.area === bsaSettingsTabellaBase)[0]
          );

          /**************************************/
          //Carico le Tabelle di base
          setTabelleList(getTabelleBase());
        }
      }
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "TabellaBaseHome",
        method: "loadDatas",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
      setIsDataLoaded(true);
    }
  }

  //Controls
  const [tabella, setTabella] = useState(""); //Contiene il value della tabella selezionata
  const [tabellaItems, setTabellaItems] = useState([]); //Contiene i records della tabella selezionata
  const [tabellaColumns, setTabellaColumns] = useState([]); //Contiene le rows della tabella selezionata
  const [tabellaRows, setTabellaRows] = useState([]); //Contiene le rows della tabella selezionata

  const handleDdlTabellaChange = async (event) => {
    try {
      setBackDropOpen(true);

      const selectedValue = event.target.value;
      setTabella(selectedValue);
      setTabellaItems([]);
      setTabellaColumns([]);
      setTabellaRows([]);
      setShowAddModal(false);

      if (selectedValue !== "") {
        //In base alla tabella selezionata recupero i records
        await loadTabellaDatas(selectedValue);
      } else {
        setTabella("");
        setTabellaItems([]);
        setTabellaRows([]);
        setTabellaColumns([]);
      }
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "TabellaBaseHome",
        method: "handleDdlTabellaChange",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
    }
  };
  const loadTabellaDatas = async (tableName) => {
    try {
      if (tableName === "base_genere") {
        setTabellaColumns([
          { field: "id", headerName: "Id", flex: 0.2, type: "number" },
          {
            field: "value",
            headerName: t("impostazioni.tabellaBase.form.value"),
            flex: 1,
            editable: false,
          },
          {
            field: "actions",
            headerName: "",
            flex: 0.2,
            filterable: false,
            renderCell: (params) => renderDetailsButton(params.row),
          },
        ]);

        const tbResponse = await apiBaseGenere.fetch({ tenantId: tenantId });
        const arrItems = [];
        for (let i = 0; i < tbResponse.data.data.base_genere.length; i++) {
          const item = tbResponse.data.data.base_genere[i];
          const itemModel = new baseGenereModel(
            item.Id,
            item.Key,
            item.Value,
            item.Order,
            item.IsSystem
          );
          arrItems.push(itemModel);
        }
        setTabellaItems(arrItems);
        setTabellaRows(arrItems);
      } else if (tableName === "base_mezzo_pagamento") {
        setTabellaColumns([
          { field: "id", headerName: "Id", flex: 0.2, type: "number" },
          {
            field: "value",
            headerName: t("impostazioni.tabellaBase.form.value"),
            flex: 1,
            editable: false,
          },
          {
            field: "actions",
            headerName: "",
            flex: 0.2,
            filterable: false,
            renderCell: (params) => renderDetailsButton(params.row),
          },
        ]);

        const tbResponse = await apiBaseMezzoPagamento.fetch({
          tenantId: tenantId,
        });
        const arrItems = [];
        for (
          let i = 0;
          i < tbResponse.data.data.base_mezzo_pagamento.length;
          i++
        ) {
          const item = tbResponse.data.data.base_mezzo_pagamento[i];
          const itemModel = new baseMezzoPagamentoModel(
            item.Id,
            item.Key,
            item.Value,
            item.Order,
            item.IsSystem
          );
          arrItems.push(itemModel);
        }
        setTabellaItems(arrItems);
        setTabellaRows(arrItems);
      } else if (tableName === "base_natura_prestazione") {
        setTabellaColumns([
          { field: "id", headerName: "Id", flex: 0.2, type: "number" },
          {
            field: "value",
            headerName: t("impostazioni.tabellaBase.form.value"),
            flex: 1,
            editable: false,
          },
          {
            field: "actions",
            headerName: "",
            flex: 0.2,
            filterable: false,
            renderCell: (params) => renderDetailsButton(params.row),
          },
        ]);

        const tbResponse = await apiBaseNaturaPrestazione.fetch({
          tenantId: tenantId,
        });
        const arrItems = [];
        for (
          let i = 0;
          i < tbResponse.data.data.base_natura_prestazione.length;
          i++
        ) {
          const item = tbResponse.data.data.base_natura_prestazione[i];
          const itemModel = new baseNaturaPrestazioneModel(
            item.Id,
            item.Key,
            item.Value,
            item.Order,
            item.IsSystem
          );
          arrItems.push(itemModel);
        }
        setTabellaItems(arrItems);
        setTabellaRows(arrItems);
      } else if (tableName === "base_natura_rapporto") {
        setTabellaColumns([
          { field: "id", headerName: "Id", flex: 0.2, type: "number" },
          {
            field: "value",
            headerName: t("impostazioni.tabellaBase.form.value"),
            flex: 1,
            editable: false,
          },
          {
            field: "actions",
            headerName: "",
            flex: 0.2,
            filterable: false,
            renderCell: (params) => renderDetailsButton(params.row),
          },
        ]);

        const tbResponse = await apiBaseNaturaRapporto.fetch({
          tenantId: tenantId,
        });
        const arrItems = [];
        for (
          let i = 0;
          i < tbResponse.data.data.base_natura_rapporto.length;
          i++
        ) {
          const item = tbResponse.data.data.base_natura_rapporto[i];
          const itemModel = new baseNaturaRapportoModel(
            item.Id,
            item.Key,
            item.Value,
            item.Order,
            item.IsSystem
          );
          arrItems.push(itemModel);
        }
        setTabellaItems(arrItems);
        setTabellaRows(arrItems);
      } else if (tableName === "base_origine_fondi") {
        setTabellaColumns([
          { field: "id", headerName: "Id", flex: 0.2, type: "number" },
          {
            field: "value",
            headerName: t("impostazioni.tabellaBase.form.value"),
            flex: 1,
            editable: false,
          },
          {
            field: "actions",
            headerName: "",
            flex: 0.2,
            filterable: false,
            renderCell: (params) => renderDetailsButton(params.row),
          },
        ]);

        const tbResponse = await apiBaseOrigineFondi.fetch({
          tenantId: tenantId,
        });
        const arrItems = [];
        for (
          let i = 0;
          i < tbResponse.data.data.base_origine_fondi.length;
          i++
        ) {
          const item = tbResponse.data.data.base_origine_fondi[i];
          const itemModel = new baseOrigineFondiModel(
            item.Id,
            item.Key,
            item.Value,
            item.Order,
            item.IsSystem
          );
          arrItems.push(itemModel);
        }
        setTabellaItems(arrItems);
        setTabellaRows(arrItems);
      } else if (tableName === "base_scopo_prestazione") {
        setTabellaColumns([
          { field: "id", headerName: "Id", flex: 0.2, type: "number" },
          {
            field: "value",
            headerName: t("impostazioni.tabellaBase.form.value"),
            flex: 1,
            editable: false,
          },
          {
            field: "actions",
            headerName: "",
            flex: 0.2,
            filterable: false,
            renderCell: (params) => renderDetailsButton(params.row),
          },
        ]);

        const tbResponse = await apiBaseScopoPrestazione.fetch({
          tenantId: tenantId,
        });
        const arrItems = [];
        for (
          let i = 0;
          i < tbResponse.data.data.base_scopo_prestazione.length;
          i++
        ) {
          const item = tbResponse.data.data.base_scopo_prestazione[i];
          const itemModel = new baseScopoPrestazioneModel(
            item.Id,
            item.Key,
            item.Value,
            item.Order,
            item.IsSystem
          );
          arrItems.push(itemModel);
        }
        setTabellaItems(arrItems);
        setTabellaRows(arrItems);
      } else if (tableName === "base_tipo_documento") {
        setTabellaColumns([
          { field: "id", headerName: "Id", flex: 0.2, type: "number" },
          {
            field: "value",
            headerName: t("impostazioni.tabellaBase.form.value"),
            flex: 1,
            editable: false,
          },
          {
            field: "actions",
            headerName: "",
            flex: 0.2,
            filterable: false,
            renderCell: (params) => renderDetailsButton(params.row),
          },
        ]);

        const tbResponse = await apiBaseTipoDocumento.fetch({
          tenantId: tenantId,
        });
        const arrItems = [];
        for (
          let i = 0;
          i < tbResponse.data.data.base_tipo_documento.length;
          i++
        ) {
          const item = tbResponse.data.data.base_tipo_documento[i];
          const itemModel = new baseTipoDocumentoModel(
            item.Id,
            item.Key,
            item.Value,
            item.Order,
            item.IsSystem
          );
          arrItems.push(itemModel);
        }
        setTabellaItems(arrItems);
        setTabellaRows(arrItems);

        const allDocumentType = await apiBaseAllTipoDocumento.fetch();
        setAllBaseTipiDocumento(allDocumentType.data.data.base_tipo_documento);
      } else if (tableName === "base_tipo_identificazione") {
        setTabellaColumns([
          { field: "id", headerName: "Id", flex: 0.2, type: "number" },
          {
            field: "value",
            headerName: t("impostazioni.tabellaBase.form.value"),
            flex: 1,
            editable: false,
          },
          {
            field: "actions",
            headerName: "",
            flex: 0.2,
            filterable: false,
            renderCell: (params) => renderDetailsButton(params.row),
          },
        ]);

        const tbResponse = await apiBaseTipoIdentificazione.fetch({
          tenantId: tenantId,
        });
        const arrItems = [];
        for (
          let i = 0;
          i < tbResponse.data.data.base_tipo_identificazione.length;
          i++
        ) {
          const item = tbResponse.data.data.base_tipo_identificazione[i];
          const itemModel = new baseTipoIdentificazioneModel(
            item.Id,
            item.Key,
            item.Value,
            item.Order,
            item.IsSystem
          );
          arrItems.push(itemModel);
        }
        setTabellaItems(arrItems);
        setTabellaRows(arrItems);
      } else if (tableName === "base_tipo_personalita") {
        setTabellaColumns([
          { field: "id", headerName: "Id", flex: 0.2, type: "number" },
          {
            field: "value",
            headerName: t("impostazioni.tabellaBase.form.value"),
            flex: 1,
            editable: false,
          },
          {
            field: "actions",
            headerName: "",
            flex: 0.2,
            filterable: false,
            renderCell: (params) => renderDetailsButton(params.row),
          },
        ]);

        const tbResponse = await apiBaseTipoPersonalita.fetch({
          tenantId: tenantId,
        });
        const arrItems = [];
        for (
          let i = 0;
          i < tbResponse.data.data.base_tipo_personalita.length;
          i++
        ) {
          const item = tbResponse.data.data.base_tipo_personalita[i];
          const itemModel = new baseTipoPersonalitaModel(
            item.Id,
            item.Key,
            item.Value,
            item.Order,
            item.IsSystem
          );
          arrItems.push(itemModel);
        }
        setTabellaItems(arrItems);
        setTabellaRows(arrItems);
      }
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "TabellaBaseHome",
        method: "loadTabellaDatas",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    }
  };

  //Grid Button Detail
  const renderDetailsButton = (item) => {
    return (
      <Stack direction='row' spacing={0}>
        {isTenantEnabled && userPermissions.canDelete && !item.isSystem ? (
          <IconButton
            color='error'
            onClick={(e) => handleGridBtnDeleteClick(e, item)}
          >
            <DeleteIcon />
          </IconButton>
        ) : null}
        {isTenantEnabled && userPermissions.canUpdate && !item.isSystem ? (
          <IconButton
            color='primary'
            onClick={(e) => handleGridBtnEditClick(e, item)}
          >
            <EditIcon />
          </IconButton>
        ) : null}
        {userPermissions.canRead ? (
          <IconButton
            color='primary'
            onClick={(e) => handleGridBtnEditClick(e, item)}
          >
            <DetailIcon />
          </IconButton>
        ) : null}
      </Stack>
    );
  };
  const handleGridBtnEditClick = (event, item) => {
    event.stopPropagation();
    setIdToPerform(item.id);
    setShowAddModal(true);
  };
  const handleGridBtnDeleteClick = (event, item) => {
    event.stopPropagation();
    setIdToPerform(item.id);
    setShowDeleteModal(true);
  };

  //Actions
  const handleBtnAddClick = async () => {
    setIdToPerform(0);
    setShowAddModal(true);
  };
  const handleCloseModalAdd = () => {
    setShowAddModal(false);
  };
  const handleModalAddItemAdded = () => {
    setIdToPerform(0);
    setShowAddModal(false);
    loadTabellaDatas(tabella);
  };

  //Modal Delete
  const handleModalDeleteClose = () => {
    setIdToPerform(0);
    setShowDeleteModal(false);
  };
  const handleModalDeleteOkClick = async () => {
    try {
      setBackDropOpen(true);

      if (idToPerform > 0) {
        if (tabella === "base_genere") {
          //Verifico se è utilizzato in un'anagrafica, in caso positivo lo blocco
          let isInUse = false;
          const baseGenereInAnagIdsResp = await apiBaseGenereInAnagrafica.fetch(
            { tenantId: tenantId }
          );
          if (baseGenereInAnagIdsResp.data.data.anagrafica.length > 0) {
            isInUse =
              baseGenereInAnagIdsResp.data.data.anagrafica.filter(
                (x) => x.GenereId === idToPerform
              ).length > 0;
          }

          if (!isInUse) {
            const tbResponse = await apiDeleteBaseGenere.fetch({
              tenantId: tenantId,
              id: parseInt(idToPerform),
            });
            if (tbResponse.data.errors !== undefined) {
              enqueueSnackbar(t("error.generale"), { variant: "error" });
            } else {
              //Aggiungo la Audit per tracciare l'azione
              const itemById = JSON.stringify(
                tabellaItems.filter(
                  (item) => item.id === parseInt(idToPerform)
                )[0]
              );
              apiAddAudit.fetch({
                tenantId: tenantId,
                aziendaId: aziendaId,
                area: bsaSettingsTabellaBase,
                operation: "Delete",
                entityType: "base_genere",
                entityId: idToPerform.toString(),
                oldValues: itemById,
                newValues: "",
              });
              enqueueSnackbar(t("message.success"), { variant: "success" });
            }
          } else {
            enqueueSnackbar(
              "Non è possibile eliminare questo record perchè attualmente in uso.",
              { variant: "error" }
            );
          }
        } else if (tabella === "base_mezzo_pagamento") {
          const tbResponse = await apiDeleteBaseMezzoPagamento.fetch({
            tenantId: tenantId,
            id: parseInt(idToPerform),
          });
          if (tbResponse.data.errors !== undefined) {
            enqueueSnackbar(t("error.generale"), { variant: "error" });
          } else {
            //Aggiungo la Audit per tracciare l'azione
            const itemById = JSON.stringify(
              tabellaItems.filter(
                (item) => item.id === parseInt(idToPerform)
              )[0]
            );
            apiAddAudit.fetch({
              tenantId: tenantId,
              aziendaId: aziendaId,
              area: bsaSettingsTabellaBase,
              operation: "Delete",
              entityType: "base_mezzo_pagamento",
              entityId: idToPerform.toString(),
              oldValues: itemById,
              newValues: "",
            });
            enqueueSnackbar(t("message.success"), { variant: "success" });
          }
        } else if (tabella === "base_natura_prestazione") {
          const tbResponse = await apiDeleteBaseNaturaPrestazione.fetch({
            tenantId: tenantId,
            id: parseInt(idToPerform),
          });
          if (tbResponse.data.errors !== undefined) {
            enqueueSnackbar(t("error.generale"), { variant: "error" });
          } else {
            //Aggiungo la Audit per tracciare l'azione
            const itemById = JSON.stringify(
              tabellaItems.filter(
                (item) => item.id === parseInt(idToPerform)
              )[0]
            );
            apiAddAudit.fetch({
              tenantId: tenantId,
              aziendaId: aziendaId,
              area: bsaSettingsTabellaBase,
              operation: "Delete",
              entityType: "base_natura_prestazione",
              entityId: idToPerform.toString(),
              oldValues: itemById,
              newValues: "",
            });
            enqueueSnackbar(t("message.success"), { variant: "success" });
          }
        } else if (tabella === "base_natura_rapporto") {
          const tbResponse = await apiDeleteBaseNaturaRapporto.fetch({
            tenantId: tenantId,
            id: parseInt(idToPerform),
          });
          if (tbResponse.data.errors !== undefined) {
            enqueueSnackbar(t("error.generale"), { variant: "error" });
          } else {
            //Aggiungo la Audit per tracciare l'azione
            const itemById = JSON.stringify(
              tabellaItems.filter(
                (item) => item.id === parseInt(idToPerform)
              )[0]
            );
            apiAddAudit.fetch({
              tenantId: tenantId,
              aziendaId: aziendaId,
              area: bsaSettingsTabellaBase,
              operation: "Delete",
              entityType: "base_natura_rapporto",
              entityId: idToPerform.toString(),
              oldValues: itemById,
              newValues: "",
            });
            enqueueSnackbar(t("message.success"), { variant: "success" });
          }
        } else if (tabella === "base_origine_fondi") {
          const tbResponse = await apiDeleteBaseOrigineFondi.fetch({
            tenantId: tenantId,
            id: parseInt(idToPerform),
          });
          if (tbResponse.data.errors !== undefined) {
            enqueueSnackbar(t("error.generale"), { variant: "error" });
          } else {
            //Aggiungo la Audit per tracciare l'azione
            const itemById = JSON.stringify(
              tabellaItems.filter(
                (item) => item.id === parseInt(idToPerform)
              )[0]
            );
            apiAddAudit.fetch({
              tenantId: tenantId,
              aziendaId: aziendaId,
              area: bsaSettingsTabellaBase,
              operation: "Delete",
              entityType: "base_origine_fondi",
              entityId: idToPerform.toString(),
              oldValues: itemById,
              newValues: "",
            });
            enqueueSnackbar(t("message.success"), { variant: "success" });
          }
        } else if (tabella === "base_scopo_prestazione") {
          const tbResponse = await apiDeleteBaseScopoPrestazione.fetch({
            tenantId: tenantId,
            id: parseInt(idToPerform),
          });
          if (tbResponse.data.errors !== undefined) {
            enqueueSnackbar(t("error.generale"), { variant: "error" });
          } else {
            //Aggiungo la Audit per tracciare l'azione
            const itemById = JSON.stringify(
              tabellaItems.filter(
                (item) => item.id === parseInt(idToPerform)
              )[0]
            );
            apiAddAudit.fetch({
              tenantId: tenantId,
              aziendaId: aziendaId,
              area: bsaSettingsTabellaBase,
              operation: "Delete",
              entityType: "base_scopo_prestazione",
              entityId: idToPerform.toString(),
              oldValues: itemById,
              newValues: "",
            });
            enqueueSnackbar(t("message.success"), { variant: "success" });
          }
        } else if (tabella === "base_tipo_documento") {
          const anagraifcheTenantResponse = await apiAnagraficheByTenant.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            userId: loggedUserId,
          });

          var allIdents = [];
          var anagrafiche = anagraifcheTenantResponse.data.data.anagrafica;
          var idents = anagrafiche.map(
            (anag) => anag.anagrafica_identificaziones
          );
          idents.forEach((identArr) => {
            identArr.forEach((ident) => {
              allIdents.push(ident);
            });
          });
          var docTypeIdents = allIdents.map(ident=> ident.DocTipoId)
          if(docTypeIdents.includes(idToPerform)){
            enqueueSnackbar("Il tipo di documento è in uso, impossibile eliminarlo", {variant: "error"})
            return;
          }

          const tbResponse = await apiDeleteBaseTipoDocumento.fetch({
            tenantId: tenantId,
            id: parseInt(idToPerform),
          });
          if (tbResponse.data.errors !== undefined) {
            enqueueSnackbar(t("error.generale"), { variant: "error" });
          } else {
            //Aggiungo la Audit per tracciare l'azione
            const itemById = JSON.stringify(
              tabellaItems.filter(
                (item) => item.id === parseInt(idToPerform)
              )[0]
            );
            apiAddAudit.fetch({
              tenantId: tenantId,
              aziendaId: aziendaId,
              area: bsaSettingsTabellaBase,
              operation: "Delete",
              entityType: "base_tipo_documento",
              entityId: idToPerform.toString(),
              oldValues: itemById,
              newValues: "",
            });
            enqueueSnackbar(t("message.success"), { variant: "success" });
          }
        } else if (tabella === "base_tipo_identificazione") {
          const tbResponse = await apiDeleteBaseTipoIdentificazione.fetch({
            tenantId: tenantId,
            id: parseInt(idToPerform),
          });
          if (tbResponse.data.errors !== undefined) {
            enqueueSnackbar(t("error.generale"), { variant: "error" });
          } else {
            //Aggiungo la Audit per tracciare l'azione
            const itemById = JSON.stringify(
              tabellaItems.filter(
                (item) => item.id === parseInt(idToPerform)
              )[0]
            );
            apiAddAudit.fetch({
              tenantId: tenantId,
              aziendaId: aziendaId,
              area: bsaSettingsTabellaBase,
              operation: "Delete",
              entityType: "base_tipo_identificazione",
              entityId: idToPerform.toString(),
              oldValues: itemById,
              newValues: "",
            });
            enqueueSnackbar(t("message.success"), { variant: "success" });
          }
        } else if (tabella === "base_tipo_personalita") {
          const tbResponse = await apiDeleteBaseTipoPersonalita.fetch({
            tenantId: tenantId,
            id: parseInt(idToPerform),
          });
          if (tbResponse.data.errors !== undefined) {
            enqueueSnackbar(t("error.generale"), { variant: "error" });
          } else {
            //Aggiungo la Audit per tracciare l'azione
            const itemById = JSON.stringify(
              tabellaItems.filter(
                (item) => item.id === parseInt(idToPerform)
              )[0]
            );
            apiAddAudit.fetch({
              tenantId: tenantId,
              aziendaId: aziendaId,
              area: bsaSettingsTabellaBase,
              operation: "Delete",
              entityType: "base_tipo_personalita",
              entityId: idToPerform.toString(),
              oldValues: itemById,
              newValues: "",
            });
            enqueueSnackbar(t("message.success"), { variant: "success" });
          }
        }
      }
    } catch (e) {
      if (e.message.includes("Foreign key violation.")) {
        enqueueSnackbar(
          "Non è possibile eliminare questo record perchè attualmente in uso.",
          { variant: "error" }
        );
      } else {
        enqueueSnackbar(t("error.generale"), { variant: "error" });
      }

      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "TabellaBaseHome",
        method: "handleModalDeleteOkClick",
        version: sharedDatas.getPropertyByName("version"),
      });
    } finally {
      setIdToPerform(0);
      setShowDeleteModal(false);
      loadTabellaDatas(tabella);
      setBackDropOpen(false);
    }
  };

  return (
    <VaporPage
      title={t("nav.tabelleBase")}
      headerRight={
        userPermissions.canCreate ? (
          <Button
            variant='text'
            size='medium'
            endIcon={<AddIcon />}
            color='primary'
            onClick={handleBtnAddClick}
            disabled={
              isTenantEnabled && userPermissions.canCreate && tabella === ""
            }
            sx={{ mr: 2 }}
          >
            {t("actions.nuovo")}
          </Button>
        ) : null
      }
    >
      <Loading open={backDropOpen} />

      {isDataLoaded && !userPermissions.canRead ? <NotAuth /> : null}

      {showAddModal && tabella !== "" ? (
        <TabellaBaseForm
          show={showAddModal}
          tabella={tabella}
          nItems={tabellaItems.length}
          itemId={idToPerform}
          onClose={handleCloseModalAdd}
          onAdded={handleModalAddItemAdded}
          baseTipoDocumento={
            allBaseTipiDocumento.length > 0 ? allBaseTipiDocumento : null
          }
        />
      ) : null}

      {showDeleteModal && idToPerform > 0 ? (
        <ModalDelete
          show={showDeleteModal}
          onClose={handleModalDeleteClose}
          onOk={handleModalDeleteOkClick}
        ></ModalDelete>
      ) : null}

      {isDataLoaded && userPermissions.canRead ? (
        <VaporPage.Section>
          <Grid
            container
            visibility={!userPermissions.canRead ? "hidden" : "block"}
          >
            <Grid item xs={12}>
              <Stack
                direction='row'
                spacing={2}
                divider={<Divider orientation='vertical' flexItem />}
              >
                <Grid item xs={3}>
                  <FormControl size='small' fullWidth>
                    <InputLabel id='ddlTabellaLabel' size='small'>
                      {t("impostazioni.tabellaBase.titolo")}
                    </InputLabel>
                    <Select
                      id='ddlTabella'
                      labelId='ddlTabellaLabel'
                      value={tabella}
                      label={t("impostazioni.tabellaBase.titolo")}
                      onChange={handleDdlTabellaChange}
                    >
                      <MenuItem value=''>
                        {t("component.select.seleziona")}
                      </MenuItem>

                      {tabelleList !== null
                        ? tabelleList.map((item) => (
                            <MenuItem
                              id={item.id}
                              key={uuidv4()}
                              value={item.id}
                            >
                              {item.name}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={9} sx={{ width: "100%" }}>
                  {tabellaRows.length > 0 ? (
                    <DataGridPro
                      rows={tabellaRows}
                      columns={tabellaColumns}
                      slots={{ toolbar: GridToolbar }}
                      autoHeight={true}
                      pageSizeOptions={[25, 50, 100]}
                      localeText={
                        itIT.components.MuiDataGrid.defaultProps.localeText
                      }
                      pagination
                      disableRowSelectionOnClick
                      onRowClick={(item, e) =>
                        handleGridBtnEditClick(e, item.row)
                      }
                      initialState={{
                        columns: {
                          columnVisibilityModel: {
                            id: false,
                          },
                        },
                        pagination: {
                          paginationModel: {
                            pageSize: 25,
                          },
                        },
                      }}
                      slotProps={{
                        toolbar: {
                          printOptions: { disableToolbarButton: true },
                        },
                      }}
                    />
                  ) : (
                    <NoData />
                  )}
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </VaporPage.Section>
      ) : null}
    </VaporPage>
  );
};
