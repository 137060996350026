import {ExportHome} from "./ExportHome"
import { pathExportDatiHome, anchorOrigin, autoHideDuration, maxSnack } from "../../businessLogic/constants";
import { SnackbarProvider } from "notistack";

export const exportDati = () => [
    {
        target: "$ONE_LAYOUT_ROUTE",
        handler: {
            exact: true,
            path: pathExportDatiHome,
            element: <SnackbarProvider anchorOrigin={anchorOrigin} autoHideDuration={autoHideDuration} maxSnack={maxSnack}><ExportHome basePath={pathExportDatiHome} /></SnackbarProvider>
        }
    }
];
