import { NavContent } from "./NavContent";
import { SegnalazioneHome } from "./SegnalazioneHome";
import { pathSegnalazioneHome, anchorOrigin, autoHideDuration, maxSnack } from "../../businessLogic/constants";
import { SnackbarProvider } from "notistack";

export const segnalazione = () => [
  {
    target: "$ONE_LAYOUT_NAV_CONTENT",
    handler: {
      component: NavContent,
      props: {
        basePath: pathSegnalazioneHome
      }
    }
  },
  {
    target: "$ONE_LAYOUT_ROUTE",
    handler: {
      exact: true,
      path: pathSegnalazioneHome,
      element: <SnackbarProvider anchorOrigin={anchorOrigin} autoHideDuration={autoHideDuration} maxSnack={maxSnack}><SegnalazioneHome basePath={pathSegnalazioneHome} /></SnackbarProvider>
    }
  }
];
