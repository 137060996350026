import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "@onefront/react-sdk";

import Grid from "@vapor/react-material/Grid";
import Stack from "@vapor/react-material/Stack";
import Button from "@vapor/react-material/Button";

import ExtendedTypography from "@vapor/react-extended/ExtendedTypography";

export const NotAuth = () => {
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={4}></Grid>
      <Grid item xs={4}>
        <Stack direction="column" justifyContent="center" alignItems="center">
          <ExtendedTypography variant="titleSmall" color="primary.main">
            {t("error.accessDenied")}
          </ExtendedTypography>
          <ExtendedTypography variant="p" sx={{ mt: 0.5 }}>
            {t("error.accessDeniedContact")}
          </ExtendedTypography>
          <Button component={Link} to={"/"} variant="contained" sx={{ mt: 2 }}>
            {t("error.accessDeniedBacktoHome")}
          </Button>
        </Stack>
      </Grid>
      <Grid item xs={4}></Grid>
    </Grid>
  );
};
