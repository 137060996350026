import React, { useState, useEffect, Fragment } from "react";
import sharedDatas from "../../businessLogic/sharedDatas";
import { useNavigate } from "react-router-dom";

//oneFront SDK
import {
  useSnackbar,
  useQuery,
  useMutation,
  useGet,
  useTranslation,
} from "@onefront/react-sdk";

import VaporPage from "@vapor/react-custom/VaporPage";
import VaporToolbar from "@vapor/react-custom/VaporToolbar";

//Vapor Components
import Grid from "@vapor/react-material/Grid";
import Stack from "@vapor/react-material/Stack";
import FormControl from "@vapor/react-material/FormControl";
import InputLabel from "@vapor/react-material/InputLabel";
import Select from "@vapor/react-material/Select";
import MenuItem from "@vapor/react-material/MenuItem";
import Divider from "@vapor/react-material/Divider";
import Button from "@vapor/react-material/Button";
import Card from "@vapor/react-material/Card";
import CardContent from "@vapor/react-material/CardContent";
import Table from "@vapor/react-material/Table";
import TableBody from "@vapor/react-material/TableBody";
import TableCell from "@vapor/react-material/TableCell";
import TableContainer from "@vapor/react-material/TableContainer";
import TableHead from "@vapor/react-material/TableHead";
import TableRow from "@vapor/react-material/TableRow";
import Paper from "@vapor/react-material/Paper";
import IconButton from "@vapor/react-material/IconButton";

import ExtendedTypography from "@vapor/react-extended/ExtendedTypography";
import ExtendedTabs from "@vapor/react-extended/ExtendedTabs";
import ExtendedTab from "@vapor/react-extended/ExtendedTab";

//Icons
import {
  LocalPrintshopOutlined as PrintIcon,
  ArrowForward as ArrowForwardIcon,
  PictureAsPdf as PictureAsPdfIcon,
} from "@mui/icons-material";

//Custom components
import { Loading } from "../../components/Loading";
import { NotAuth } from "../../components/NotAuth";

//Constants, Api and Data Models
import { bsaNormativa, pathNormativaHome } from "../../businessLogic/constants";
import {
  userPermissionModel,
  normativaModel,
} from "../../businessLogic/models";
import { query_normativa } from "../../businessLogic/query";
import {
  mutation_add_log,
  mutation_add_lastActivity_byUserId,
  mutation_delete_lastActivity,
  mutation_get_document,
} from "../../businessLogic/mutation";

//Other Components
import { v4 as uuidv4 } from "uuid";
import download from "downloadjs";
import normativaPdf from "../../resources/TS_DLgs_231_2007.pdf";

export const NormativaHome = ({ basePath }) => {
  // const loggedUserId = useTokenData("sub");
  // const tenantId = getLocalStorageSelectedTenant();
  const tenantId = sharedDatas.getPropertyByName("tenantId");
  const aziendaId = sharedDatas.getPropertyByName("aziendaId");
  const loggedUserId = sharedDatas.getPropertyByName("userId");
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  //Query
  //const apiUserPermissions = useQuery(query_user_permission, {}, { lazy: true, graphqlEndpoint : "aml:hasura:api://v1/graphql" });
  const apiNormativa = useQuery(
    query_normativa,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );

  //Mutation
  const apiAddLog = useMutation(mutation_add_log, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddLastActivity = useMutation(mutation_add_lastActivity_byUserId, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiDeleteLastActivity = useMutation(mutation_delete_lastActivity, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiGetJsReportDocument = useMutation(mutation_get_document, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });

  const [backDropOpen, setBackDropOpen] = useState(true); //Loader utilizzato durante il caricamento
  const [isDataLoaded, setIsDataLoaded] = useState(false); //Indica lo stato di caricamento del result Api

  const [userPermissions, setUserPermissions] = useState(
    new userPermissionModel(
      0,
      aziendaId,
      bsaNormativa,
      false,
      false,
      false,
      false
    )
  );

  const [normativaList, setNormativaList] = useState([]); //Contiene il result completo
  const [normativaTitoliList, setNormativaTitoliList] = useState([]); //Array contenente solo i titoli
  const [normativaCapiList, setNormativaCapiList] = useState([]); //Array contenente solo i capi
  const [normativaSezioniList, setNormativaSezioniList] = useState([]); //Array contenente solo le sezioni
  const [normativaArticoliList, setNormativaArticoliList] = useState([]); //Array contenente solo gli articoli

  //EuroConference
  const [euroConfArr, setEuroConfArrArr] = useState([]);

  const tsApiAntiRic = useGet("aml:ts-anti-ric:api://", { lazy: true });

  useEffect(() => {
    loadDatas();
  }, [isDataLoaded]);
  async function loadDatas() {
    try {
      if (
        sharedDatas.getPropertyByName("tenantId") === "" ||
        sharedDatas.getPropertyByName("tenantModel") === null ||
        sharedDatas.getPropertyByName("aziendaId") === 0 ||
        sharedDatas.getPropertyByName("aziendaModelList") === null ||
        sharedDatas.getPropertyByName("userId") === "" ||
        sharedDatas.getPropertyByName("userModel") === null
      ) {
        navigate("/");
      } else {
        setBackDropOpen(true);

        if (!isDataLoaded) {
          //Last Activity
          await apiDeleteLastActivity.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            userId: loggedUserId,
          });
          await apiAddLastActivity.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            path: pathNormativaHome,
            entityId: "",
            entityName: "",
          });

          /**************************************/
          //Carico i permessi relativi all'utente
          setUserPermissions(
            sharedDatas
              .getPropertyByName("userPermissions")
              .filter((x) => x.area === bsaNormativa)[0]
          );

          // const userPermsResponse = await apiUserPermissions.fetch({ userId: loggedUserId, area: bsaNormativa });
          // const userPerms = userPermsResponse.data.data.user_permission.length > 0 ? userPermsResponse.data.data.user_permission[0] : null;
          // if (userPerms != null) {
          //   setUserPermissions(new userPermissionModel(userPerms.Id, bsaNormativa, userPerms.CanRead, userPerms.CanCreate, userPerms.CanUpdate, userPerms.CanDelete));
          // }

          /**************************************/
          //Carico la normativa
          const normativaResponse = await apiNormativa.fetch();
          const arrItems = [];
          normativaResponse.data.data.normativa.map((item) =>
            arrItems.push(
              new normativaModel(
                item.Id,
                item.ParentId,
                item.Title,
                item.Subtitle,
                item.Description,
                item.TitoloN,
                item.CapoN,
                item.SezioneN,
                item.ArticoloN,
                item.OrderN
              )
            )
          );
          setNormativaList(arrItems);

          /**************************************/
          //Carico i link ad EuroConference
          const ecArr = [];
          ecArr.push({
            id: "11123",
            type: "DisciplinaGenerale",
            title: "Aspetti definitori",
          });
          ecArr.push({
            id: "11124",
            type: "DisciplinaGenerale",
            title: "I soggetti obbligati",
          });
          ecArr.push({
            id: "11125",
            type: "DisciplinaGenerale",
            title: "Profili sanzionatori",
          });
          ecArr.push({
            id: "11126",
            type: "DisciplinaGenerale",
            title: "Criteri per l’applicazione delle sanzioni",
          });
          ecArr.push({
            id: "12047",
            type: "DisciplinaGenerale",
            title: "Le novità del D.Lgs. 125/2019",
          });
          ecArr.push({
            id: "12101",
            type: "DisciplinaGenerale",
            title: "Normativa",
          });
          ecArr.push({
            id: "12102",
            type: "DisciplinaGenerale",
            title: "Prassi",
          });

          ecArr.push({
            id: "17235",
            type: "AdeguataVerifica",
            title: "Obblighi di adeguata verifica: disciplina generale",
          });
          ecArr.push({
            id: "17236",
            type: "AdeguataVerifica",
            title:
              "Antiriciclaggio e approccio basato sul rischio. La valutazione del rischio",
          });
          ecArr.push({
            id: "17237",
            type: "AdeguataVerifica",
            title:
              "Adeguata verifica: identificazione e verifica identità cliente e titolare effettivo",
          });
          ecArr.push({
            id: "17238",
            type: "AdeguataVerifica",
            title: "Il titolare effettivo nella disciplina antiriciclaggio",
          });
          ecArr.push({
            id: "17239",
            type: "AdeguataVerifica",
            title:
              "Adeguata verifica clientela: informazioni su scopo e natura del rapporto",
          });
          ecArr.push({
            id: "17240",
            type: "AdeguataVerifica",
            title: "Adeguata verifica della clientela: il controllo costante",
          });
          ecArr.push({
            id: "17241",
            type: "AdeguataVerifica",
            title: "Gli obblighi di adeguata verifica semplificata",
          });
          ecArr.push({
            id: "17242",
            type: "AdeguataVerifica",
            title: "Gli obblighi di adeguata verifica rafforzata",
          });
          ecArr.push({
            id: "17243",
            type: "AdeguataVerifica",
            title:
              "Le persone politicamente esposte nell’ambito della disciplina antiriciclaggio",
          });
          ecArr.push({
            id: "17244",
            type: "AdeguataVerifica",
            title: "Adeguata verifica da parte di terzi",
          });
          ecArr.push({
            id: "17245",
            type: "AdeguataVerifica",
            title:
              "Comunicazione dei dati del titolare effettivo al Registro delle imprese",
          });
          ecArr.push({
            id: "17246",
            type: "AdeguataVerifica",
            title: "Casi risolti - Parte I",
          });
          ecArr.push({
            id: "17703",
            type: "AdeguataVerifica",
            title: "Casi risolti - Parte II",
          });
          ecArr.push({
            id: "17248",
            type: "AdeguataVerifica",
            title: "Normativa",
          });
          ecArr.push({
            id: "17249",
            type: "AdeguataVerifica",
            title: "Prassi",
          });
          ecArr.push({
            id: "17247",
            type: "AdeguataVerifica",
            title: "Giurisprudenza",
          });

          ecArr.push({
            id: "17270",
            type: "ObblighiConservazione",
            title: "Obblighi di conservazione",
          });
          ecArr.push({
            id: "21946",
            type: "ObblighiConservazione",
            title: "Conservazione cartacea e conservazione informatica",
          });
          ecArr.push({
            id: "21947",
            type: "ObblighiConservazione",
            title:
              "Obblighi di conservazione antiriciclaggio. Quali documenti conservare",
          });
          ecArr.push({
            id: "17272",
            type: "ObblighiConservazione",
            title: "Casi",
          });
          ecArr.push({
            id: "17273",
            type: "ObblighiConservazione",
            title: "Normativa",
          });
          ecArr.push({
            id: "17271",
            type: "ObblighiConservazione",
            title: "Prassi",
          });

          ecArr.push({
            id: "17290",
            type: "ObblighiSegnalazione",
            title: "Segnalazione delle operazioni sospette (SOS)",
          });
          ecArr.push({
            id: "17291",
            type: "ObblighiSegnalazione",
            title:
              "Covid-19: fattori di rischio di riciclaggio e di finanziamento del terrorismo",
          });
          ecArr.push({
            id: "17292",
            type: "ObblighiSegnalazione",
            title: "Le comunicazioni oggettive",
          });
          ecArr.push({
            id: "17293",
            type: "ObblighiSegnalazione",
            title: "Casi - Parte II",
          });
          ecArr.push({
            id: "17294",
            type: "ObblighiSegnalazione",
            title: "Normativa - Parte II",
          });
          ecArr.push({
            id: "17295",
            type: "ObblighiSegnalazione",
            title: "Prassi - Parte II",
          });

          ecArr.push({
            id: "12115",
            type: "CircolazioneContante",
            title: "Disciplina generale",
          });
          ecArr.push({
            id: "12116",
            type: "CircolazioneContante",
            title:
              "Violazione norme sul contante: obbligo comunicazione al Mef",
          });
          ecArr.push({
            id: "12117",
            type: "CircolazioneContante",
            title: "Divieto di pagamento delle retribuzioni in contanti",
          });
          ecArr.push({
            id: "12118",
            type: "CircolazioneContante",
            title: "Profili sanzionatori",
          });
          ecArr.push({
            id: "12119",
            type: "CircolazioneContante",
            title:
              "Obbligo pos, credito d’imposta per le commissioni e altri incentivi all’uso di strumenti di pagamento elettronici",
          });
          ecArr.push({
            id: "12120",
            type: "CircolazioneContante",
            title: "Turisti stranieri: deroga ai limiti del contante",
          });
          ecArr.push({
            id: "12111",
            type: "CircolazioneContante",
            title: "Casi",
          });
          ecArr.push({
            id: "12113",
            type: "CircolazioneContante",
            title: "Normativa",
          });
          ecArr.push({
            id: "12114",
            type: "CircolazioneContante",
            title: "Prassi",
          });
          ecArr.push({
            id: "12112",
            type: "CircolazioneContante",
            title: "Giurisprudenza",
          });

          ecArr.push({
            id: "17256",
            type: "AutovalutazioneRischio",
            title: "Disciplina generale",
          });
          ecArr.push({
            id: "17257",
            type: "AutovalutazioneRischio",
            title: "Il rischio inerente",
          });
          ecArr.push({
            id: "17258",
            type: "AutovalutazioneRischio",
            title: "La vulnerabilità",
          });
          ecArr.push({
            id: "17259",
            type: "AutovalutazioneRischio",
            title: "Obblighi di formazione",
          });
          ecArr.push({
            id: "17260",
            type: "AutovalutazioneRischio",
            title: "Organizzazione dello studio",
          });
          ecArr.push({
            id: "17261",
            type: "AutovalutazioneRischio",
            title:
              "Antiriciclaggio e autovalutazione del rischio: determinazione del rischio residuo",
          });
          ecArr.push({
            id: "17265",
            type: "AutovalutazioneRischio",
            title:
              "Autovalutazione del rischio (artt. 15 e 16 D.Lgs. 231/2007)",
          });
          ecArr.push({
            id: "17267",
            type: "AutovalutazioneRischio",
            title: "Piano di formazione",
          });
          ecArr.push({
            id: "17268",
            type: "AutovalutazioneRischio",
            title: "Fac-simile verbale di riunione formativa",
          });
          ecArr.push({
            id: "17262",
            type: "AutovalutazioneRischio",
            title: "Casi",
          });
          ecArr.push({
            id: "17263",
            type: "AutovalutazioneRischio",
            title: "Normativa",
          });
          ecArr.push({
            id: "17264",
            type: "AutovalutazioneRischio",
            title: "Prassi",
          });
          setEuroConfArrArr(ecArr);

          //Dall'array appena creato, estraggo solo i Capi da assegnare alla dropdown specifica
          var titoli = arrItems.filter((item) => item.titoloN > 0);
          setNormativaTitoliList(titoli);
        }
      }
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "NormativaHome",
        method: "loadDatas",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
      setIsDataLoaded(true);
    }
  }

  //Controls
  const [titolo, setTitolo] = useState(""); //Contiene il value del titolo selezionato
  const [capo, setCapo] = useState(""); //Contiene il value del capo selezionato
  const [sezione, setSezione] = useState(""); //Contiene il value della sezione selezionata
  const [articolo, setArticolo] = useState(""); //Contiene il value dell'articolo selezionato

  const handleDdlTitoloChange = (event) => {
    try {
      const selectedValue = event.target.value;
      setTitolo(selectedValue);
      setCapo("");
      setSezione("");
      setArticolo("");
      setArtArticoloContent("");
      setArtCapo("");
      setArtSezione("");
      setArtTitolo("");

      setNormativaCapiList([]);
      setNormativaSezioniList([]);
      setNormativaArticoliList([]);

      var capi = [];
      var sezioni = [];
      var articoli = [];
      if (selectedValue !== "") {
        capi = normativaList.filter(
          (item) => item.parentId === selectedValue && item.capoN > 0
        );
        sezioni = normativaList.filter(
          (item) => item.parentId === selectedValue && item.sezioneN > 0
        );
        articoli = normativaList.filter(
          (item) => item.parentId === selectedValue
        );
      }
      setNormativaCapiList(capi);
      setNormativaSezioniList(sezioni);
      setNormativaArticoliList(articoli);
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "NormativaHome",
        method: "handleDdlTitoloChange",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    }
  };
  const handleDdlCapoChange = (event) => {
    try {
      const selectedValue = event.target.value;
      setCapo(selectedValue);
      setSezione("");
      setArticolo("");
      setArtArticoloContent("");

      setNormativaSezioniList([]);
      setNormativaArticoliList([]);

      var sezioni = [];
      if (selectedValue !== "") {
        sezioni = normativaList.filter(
          (item) => item.parentId === selectedValue && item.sezioneN > 0
        );
      }
      setNormativaSezioniList(sezioni);

      var articoli = [];
      if (selectedValue !== "") {
        articoli = normativaList.filter(
          (item) => item.parentId === selectedValue && item.articoloN > 0
        );
      }
      setNormativaArticoliList(articoli);
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "NormativaHome",
        method: "handleDdlCapoChange",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    }
  };
  const handleDdlSezioneChange = (event) => {
    try {
      const selectedValue = event.target.value;
      setSezione(selectedValue);
      setArticolo("");
      setArtArticoloContent("");

      setNormativaArticoliList([]);

      var articoli = [];
      if (selectedValue !== "") {
        articoli = normativaList.filter(
          (item) => item.parentId === selectedValue && item.articoloN > 0
        );
      }
      setNormativaArticoliList(articoli);
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "NormativaHome",
        method: "handleDdlSezioneChange",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    }
  };

  //Dettaglio Articolo
  const [artTitolo, setArtTitolo] = useState(""); //Contiene il contenuto testuale del titolo selezionato
  const [artCapo, setArtCapo] = useState(""); //Contiene il contenuto testuale del capo selezionato
  const [artSezione, setArtSezione] = useState(""); //Contiene il contenuto testuale della sezione selezionata
  const [artArticolo, setArtArticolo] = useState(""); //Contiene il contenuto testuale del titolo dell'articolo selezionato
  const [artArticoloContent, setArtArticoloContent] = useState(""); //Contiene il contenuto testuale dell'articolo selezionato

  //Actions
  const handleDdlArticoloChange = (event) => {
    try {
      const selectedValue = event.target.value;
      setArticolo(selectedValue);

      var articolo = normativaArticoliList.filter(
        (item) => item.id === selectedValue
      );
      var sezione = normativaSezioniList.filter(
        (item) => item.id === articolo[0].parentId
      );
      var capo = new normativaModel();
      var titolo = new normativaModel();

      if (sezione.length <= 0) {
        capo = normativaCapiList.filter(
          (item) => item.id === articolo[0].parentId
        );

        if (capo.length > 0) {
          titolo = normativaTitoliList.filter(
            (item) => item.id === capo[0].parentId
          );
        } else {
          titolo = normativaTitoliList.filter(
            (item) => item.id === articolo[0].parentId
          );
        }
      } else {
        setArtSezione(sezione[0].title);
        capo = normativaCapiList.filter(
          (item) => item.id === sezione[0].parentId
        );
        titolo = normativaTitoliList.filter(
          (item) => item.id === capo[0].parentId
        );
      }

      setArtArticolo(articolo[0].title + ": " + articolo[0].subTitle);
      setArtArticoloContent(articolo[0].description);

      if (capo.length > 0) {
        setArtCapo(capo[0].title + ": " + capo[0].subTitle);
      }

      setArtTitolo(titolo[0].title + ": " + titolo[0].subTitle);
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "NormativaHome",
        method: "handleDdlArticoloChange",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    }
  };

  //Print
  const handlePrintReportClick = async () => {
    try {
      setBackDropOpen(true);

      var reportTypeName = "normativa";
      const reportFileName = "normativa";

      const body = {
        template: {
          name: reportTypeName,
        },
        data: {
          titolo: artTitolo,
          capo: artCapo,
          sezione: artSezione,
          articolo: {
            title: artArticolo,
            content: artArticoloContent,
          },
        },
      };

      const docResponse = await apiGetJsReportDocument.fetch({
        data: JSON.stringify(body),
      });
      download(
        atob(docResponse.data.data.getJsReportDocument.value),
        reportFileName + ".pdf"
      );
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "NormativaHome",
        method: "handlePrintReportClick",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
    }
  };

  //EuroConference
  const handleEuroConfClick = async (itemId) => {
    try {
      setBackDropOpen(true);

      const jsonToEnc = {
        id: itemId,
        search: "",
        userfullname: sharedDatas.getPropertyByName("userModel").username,
        context: "TS420",
        from: "TS Antiriciclaggio",
        userid: loggedUserId,
        teamsystemid: sharedDatas.getPropertyByName("userModel").email,
      };

      const cryptedContentResp = await tsApiAntiRic.fetch({
        url:
          "aml:ts-anti-ric:api://euroconf/getEncParam?valueToEnc=" +
          JSON.stringify(jsonToEnc),
      });

      window.open(
        "https://portale.ecevolution.it/ssoEvolution?encParam=" +
          cryptedContentResp.data,
        "_blank",
        "noopener,noreferrer"
      );
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "NormativaHome",
        method: "handleEuroConfClick",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
    }
  };

  //Tabs
  const [selectedTab, setSelectedTab] = useState("tabNormativa");
  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleNormativaPdfClick = (id) => {
    window.open(normativaPdf, "_blank", "noopener,noreferrer");
  };

  return (
    <Fragment>
      <VaporPage title={t("nav.normativa")}>
        <Loading open={backDropOpen} />

        {isDataLoaded && !userPermissions.canRead ? <NotAuth /> : null}

        {isDataLoaded && userPermissions.canRead && normativaList.length > 0 ? (
          <VaporPage.Section>
            <ExtendedTabs
              variant="standard"
              size="small"
              value={selectedTab}
              onChange={handleChangeTab}
              sx={{ mb: 2 }}
            >
              <ExtendedTab value="tabNormativa" label={"nav.normativa"} />
              <ExtendedTab value="tabEC" label="EuroConference" />
            </ExtendedTabs>

            <Grid
              container
              visibility={!userPermissions.canRead ? "hidden" : "block"}
              spacing={2}
            >
              {selectedTab === "tabNormativa" ? (
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    spacing={2}
                    divider={<Divider orientation="vertical" flexItem />}
                  >
                    <Grid item xs={3} sx={{ pb: 2 }}>
                      <FormControl size="small" fullWidth>
                        <InputLabel id="ddlTitoloLabel" size="small">
                          {t("normativa.titolo")}
                        </InputLabel>
                        <Select
                          id="ddlTitolo"
                          labelId="ddlTitoloLabel"
                          value={titolo}
                          label={t("normativa.titolo")}
                          onChange={handleDdlTitoloChange}
                        >
                          <MenuItem value="">
                            {t("component.select.seleziona")}
                          </MenuItem>

                          {normativaTitoliList !== null
                            ? normativaTitoliList.map((item) => (
                                <MenuItem
                                  id={item.id}
                                  key={item.id}
                                  value={item.id}
                                >
                                  {item.title}: {item.subTitle}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                      <FormControl
                        size="small"
                        fullWidth
                        sx={{ mt: 3 }}
                        disabled={normativaCapiList.length <= 0}
                      >
                        <InputLabel id="ddlCapoLabel" size="small">
                          {t("normativa.capo")}
                        </InputLabel>
                        <Select
                          id="ddlCapo"
                          labelId="ddlCapoLabel"
                          value={capo}
                          label={t("normativa.capo")}
                          onChange={handleDdlCapoChange}
                        >
                          <MenuItem value="">
                            {t("component.select.seleziona")}
                          </MenuItem>

                          {normativaCapiList !== null
                            ? normativaCapiList.map((item) => (
                                <MenuItem
                                  id={item.id}
                                  key={item.id}
                                  value={item.id}
                                >
                                  {item.title}: {item.subTitle}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                      <FormControl
                        size="small"
                        fullWidth
                        sx={{ mt: 3 }}
                        disabled={normativaSezioniList.length <= 0}
                      >
                        <InputLabel id="ddlSezioneLabel" size="small">
                          {t("normativa.sezione")}
                        </InputLabel>
                        <Select
                          id="ddlSezione"
                          labelId="ddlSezioneLabel"
                          value={sezione}
                          label={t("normativa.sezione")}
                          onChange={handleDdlSezioneChange}
                        >
                          <MenuItem value="">
                            {t("component.select.seleziona")}
                          </MenuItem>

                          {normativaSezioniList !== null
                            ? normativaSezioniList.map((item) => (
                                <MenuItem
                                  id={item.id}
                                  key={item.id}
                                  value={item.id}
                                >
                                  {item.title}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                      <FormControl
                        size="small"
                        fullWidth
                        sx={{ mt: 3 }}
                        disabled={normativaArticoliList.length <= 0}
                      >
                        <InputLabel id="ddlArticoloLabel" size="small">
                          {t("normativa.articolo")}
                        </InputLabel>
                        <Select
                          id="ddlArticolo"
                          labelId="ddlArticoloLabel"
                          value={articolo}
                          label={t("normativa.articolo")}
                          onChange={handleDdlArticoloChange}
                        >
                          <MenuItem value="">
                            {t("component.select.seleziona")}
                          </MenuItem>

                          {normativaArticoliList !== null
                            ? normativaArticoliList.map((item) => (
                                <MenuItem
                                  id={item.id}
                                  key={item.id}
                                  value={item.id}
                                >
                                  {item.title}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={9} sx={{ pb: 2 }}>
                      {artTitolo !== "" &&
                      artArticolo !== "" &&
                      artArticoloContent !== "" ? (
                        <Grid item xs={12}>
                          <ExtendedTypography
                            variant="subtitle2"
                            sx={{ fontWeight: "bold" }}
                          >
                            {artTitolo}
                          </ExtendedTypography>
                          {artCapo !== "" ? (
                            <ExtendedTypography
                              variant="subtitle2"
                              sx={{ fontStyle: "oblique" }}
                            >
                              {artCapo}
                            </ExtendedTypography>
                          ) : null}
                          {artSezione !== "" ? (
                            <ExtendedTypography variant="subtitle2">
                              {artSezione}
                            </ExtendedTypography>
                          ) : null}
                          <Divider variant="standard" sx={{ mt: 2, mb: 2 }} />
                          <ExtendedTypography
                            variant="titleSmall"
                            color="primary.main"
                            sx={{ mt: 2 }}
                          >
                            {artArticolo}
                          </ExtendedTypography>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: artArticoloContent,
                            }}
                          ></div>
                        </Grid>
                      ) : (
                        <ExtendedTypography variant="p">
                          {t("normativa.error.noArticleSelected")}
                        </ExtendedTypography>
                      )}
                    </Grid>
                  </Stack>
                </Grid>
              ) : null}

              {selectedTab === "tabEC" ? (
                <Fragment>
                  <Grid item xs={4}>
                    <Card sx={{ width: "100%", height: "450px" }}>
                      <CardContent>
                        <Stack
                          direction="column"
                          justifyContent="left"
                          alignItems="left"
                        >
                          <ExtendedTypography
                            variant="titleSmall"
                            color="primary.main"
                          >
                            {t("normativa.normativaHome.discGen")}
                          </ExtendedTypography>

                          <TableContainer
                            component={Paper}
                            sx={{ maxHeight: 400, paddingTop: 2 }}
                          >
                            <Table size="small" stickyHeader>
                              <TableBody>
                                {euroConfArr
                                  .filter(
                                    (x) => x.type === "DisciplinaGenerale"
                                  )
                                  .map((item) => (
                                    <TableRow
                                      key={uuidv4()}
                                      sx={{
                                        "& > *": { borderBottom: "unset" },
                                        cursor: "pointer",
                                      }}
                                      hover
                                      onClick={() =>
                                        handleEuroConfClick(item.id)
                                      }
                                    >
                                      <TableCell>{item.title}</TableCell>
                                      <TableCell align="right">
                                        <IconButton
                                          color="primary"
                                          onClick={() =>
                                            handleEuroConfClick(item.id)
                                          }
                                        >
                                          <ArrowForwardIcon />
                                        </IconButton>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Stack>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card sx={{ width: "100%", height: "450px" }}>
                      <CardContent>
                        <Stack
                          direction="column"
                          justifyContent="left"
                          alignItems="left"
                        >
                          <ExtendedTypography
                            variant="titleSmall"
                            color="primary.main"
                          >
                            {t("normativa.normativaHome.vercli")}
                          </ExtendedTypography>

                          <TableContainer
                            component={Paper}
                            sx={{ maxHeight: 400, paddingTop: 2 }}
                          >
                            <Table size="small" stickyHeader>
                              <TableBody>
                                {euroConfArr
                                  .filter((x) => x.type === "AdeguataVerifica")
                                  .map((item) => (
                                    <TableRow
                                      key={uuidv4()}
                                      sx={{
                                        "& > *": { borderBottom: "unset" },
                                        cursor: "pointer",
                                      }}
                                      hover
                                      onClick={() =>
                                        handleEuroConfClick(item.id)
                                      }
                                    >
                                      <TableCell>{item.title}</TableCell>
                                      <TableCell align="right">
                                        <IconButton
                                          color="primary"
                                          onClick={() =>
                                            handleEuroConfClick(item.id)
                                          }
                                        >
                                          <ArrowForwardIcon />
                                        </IconButton>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Stack>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card sx={{ width: "100%", height: "450px" }}>
                      <CardContent>
                        <Stack
                          direction="column"
                          justifyContent="left"
                          alignItems="left"
                        >
                          <ExtendedTypography
                            variant="titleSmall"
                            color="primary.main"
                          >
                            {t("normativa.normativaHome.obbCons")}
                          </ExtendedTypography>

                          <TableContainer
                            component={Paper}
                            sx={{ maxHeight: 400, paddingTop: 2 }}
                          >
                            <Table size="small" stickyHeader>
                              <TableBody>
                                {euroConfArr
                                  .filter(
                                    (x) => x.type === "ObblighiConservazione"
                                  )
                                  .map((item) => (
                                    <TableRow
                                      key={uuidv4()}
                                      sx={{
                                        "& > *": { borderBottom: "unset" },
                                        cursor: "pointer",
                                      }}
                                      hover
                                      onClick={() =>
                                        handleEuroConfClick(item.id)
                                      }
                                    >
                                      <TableCell>{item.title}</TableCell>
                                      <TableCell align="right">
                                        <IconButton
                                          color="primary"
                                          onClick={() =>
                                            handleEuroConfClick(item.id)
                                          }
                                        >
                                          <ArrowForwardIcon />
                                        </IconButton>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Stack>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card sx={{ width: "100%", height: "450px" }}>
                      <CardContent>
                        <Stack
                          direction="column"
                          justifyContent="left"
                          alignItems="left"
                        >
                          <ExtendedTypography
                            variant="titleSmall"
                            color="primary.main"
                          >
                            {t("normativa.normativaHome.obbSegn")}
                          </ExtendedTypography>

                          <TableContainer
                            component={Paper}
                            sx={{ maxHeight: 400, paddingTop: 2 }}
                          >
                            <Table size="small" stickyHeader>
                              <TableBody>
                                {euroConfArr
                                  .filter(
                                    (x) => x.type === "ObblighiSegnalazione"
                                  )
                                  .map((item) => (
                                    <TableRow
                                      key={uuidv4()}
                                      sx={{
                                        "& > *": { borderBottom: "unset" },
                                        cursor: "pointer",
                                      }}
                                      hover
                                      onClick={() =>
                                        handleEuroConfClick(item.id)
                                      }
                                    >
                                      <TableCell>{item.title}</TableCell>
                                      <TableCell align="right">
                                        <IconButton
                                          color="primary"
                                          onClick={() =>
                                            handleEuroConfClick(item.id)
                                          }
                                        >
                                          <ArrowForwardIcon />
                                        </IconButton>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Stack>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card sx={{ width: "100%", height: "450px" }}>
                      <CardContent>
                        <Stack
                          direction="column"
                          justifyContent="left"
                          alignItems="left"
                        >
                          <ExtendedTypography
                            variant="titleSmall"
                            color="primary.main"
                          >
                            {t("normativa.normativaHome.circCont")}
                          </ExtendedTypography>

                          <TableContainer
                            component={Paper}
                            sx={{ maxHeight: 400, paddingTop: 2 }}
                          >
                            <Table size="small" stickyHeader>
                              <TableBody>
                                {euroConfArr
                                  .filter(
                                    (x) => x.type === "CircolazioneContante"
                                  )
                                  .map((item) => (
                                    <TableRow
                                      key={uuidv4()}
                                      sx={{
                                        "& > *": { borderBottom: "unset" },
                                        cursor: "pointer",
                                      }}
                                      hover
                                      onClick={() =>
                                        handleEuroConfClick(item.id)
                                      }
                                    >
                                      <TableCell>{item.title}</TableCell>
                                      <TableCell align="right">
                                        <IconButton
                                          color="primary"
                                          onClick={() =>
                                            handleEuroConfClick(item.id)
                                          }
                                        >
                                          <ArrowForwardIcon />
                                        </IconButton>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Stack>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card sx={{ width: "100%", height: "450px" }}>
                      <CardContent>
                        <Stack
                          direction="column"
                          justifyContent="left"
                          alignItems="left"
                        >
                          <ExtendedTypography
                            variant="titleSmall"
                            color="primary.main"
                          >
                            {t("normativa.normativaHome.autRisk")}
                          </ExtendedTypography>

                          <TableContainer
                            component={Paper}
                            sx={{ maxHeight: 400, paddingTop: 2 }}
                          >
                            <Table size="small" stickyHeader>
                              <TableBody>
                                {euroConfArr
                                  .filter(
                                    (x) => x.type === "AutovalutazioneRischio"
                                  )
                                  .map((item) => (
                                    <TableRow
                                      key={uuidv4()}
                                      sx={{
                                        "& > *": { borderBottom: "unset" },
                                        cursor: "pointer",
                                      }}
                                      hover
                                      onClick={() =>
                                        handleEuroConfClick(item.id)
                                      }
                                    >
                                      <TableCell>{item.title}</TableCell>
                                      <TableCell align="right">
                                        <IconButton
                                          color="primary"
                                          onClick={() =>
                                            handleEuroConfClick(item.id)
                                          }
                                        >
                                          <ArrowForwardIcon />
                                        </IconButton>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Stack>
                      </CardContent>
                    </Card>
                  </Grid>
                </Fragment>
              ) : null}
            </Grid>
          </VaporPage.Section>
        ) : null}
      </VaporPage>
      {selectedTab === "tabNormativa" ? (
        <VaporToolbar
          variant="regular"
          size="medium"
          withoutAppBar={false}
          contentRight={
            <Stack direction="row" spacing={2}>
              <Button
                variant="outlined"
                color="secondary"
                size="medium"
                endIcon={<PictureAsPdfIcon />}
                onClick={handleNormativaPdfClick}
              >
                {t("normativa.normativaHome.readNorm")}
              </Button>
              <Button
                variant="contained"
                size="medium"
                endIcon={<PrintIcon />}
                onClick={handlePrintReportClick}
                disabled={
                  artTitolo === "" &&
                  artCapo === "" &&
                  artArticolo === "" &&
                  artArticoloContent === ""
                }
              >
                {t("actions.stampa")}
              </Button>
            </Stack>
          }
        ></VaporToolbar>
      ) : null}
    </Fragment>
  );
};
