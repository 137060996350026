import React, { Fragment, useState } from "react";
import { useTranslation } from "@onefront/react-sdk";

//Vapor Components
import Stack from "@vapor/react-material/Stack";
import Box from "@vapor/react-material/Box";
import Button from "@vapor/react-material/Button";
import Modal from "@vapor/react-material/Modal";
import Divider from "@vapor/react-material/Divider";
import TextField from "@vapor/react-material/TextField";
import ExtendedTypography from "@vapor/react-extended/ExtendedTypography";

//Other components
import { v4 as uuidv4 } from "uuid";

//Style
import { modalStyle } from "../businessLogic/styles";

export const ModalDelete = ({ show,strongDelete, onClose, onOk }) => {
  const { t } = useTranslation();
  const [strongDelString] = useState((Math.random() + 1).toString(36).substring(3));
  const [isConfirmDisabled,setIsConfirmDisabled] = useState(strongDelete)

  //Actions
  const handleModalClose = (e) => {
    onClose(e);
  };
  const handleModalOk = (e) => {
    onOk(e);
  };

  //Handler
  const handleChange = (event, value) => {
      const { id } = event.target;           
      if (id === "txtConfirmDel") {
        if(event.target.value === strongDelString){
          setIsConfirmDisabled(false)
        } else {
          setIsConfirmDisabled(true)
        }
      }
  } 

  return (
    <Fragment>
      <Modal
        key="deleteModal"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        open={show}
        onClose={handleModalClose}
        onBackdropClick={handleModalClose}
      >
        <Box sx={modalStyle}>
          <Stack
            direction="column"
            spacing={0}
            justifyContent="left"
            alignItems="left"
          >
            <ExtendedTypography
              variant="titleSmall"
              color="primary.main"
              sx={{ p: 2, pl: 3 }}
            >
              {t("title.deleteConfirm")}
            </ExtendedTypography>
            <Divider
              orientation="horizontal"
              flexItem
              sx={{ bgcolor: "#D82829 !important" }}
            />            
            <ExtendedTypography variant="subtitle2" sx={{ p: 2, pl: 3, pt: 3 }}>
              {t("modal.deleteDesc")}
            </ExtendedTypography>
          </Stack>

          {isConfirmDisabled ? 
          (<Stack sx={{ p: 2, pl: 3, pt: 1 }}>
            <ExtendedTypography variant="subtitle2">
              Per confermare l'eliminazione digita : <b>{strongDelString}</b>
            </ExtendedTypography>
            <TextField
              id="txtConfirmDel"
              key={uuidv4()}                           
              size="small"
              fullWidth
              required              
              onChange={handleChange}
              inputProps={{ autocomplete: "nope" }}
            />
          </Stack>
          ) : null }
          <Stack
            direction="row"
            spacing={1}
            sx={{ p: 1, mt: 1, bgcolor: "#F2F5F8" }}
            justifyContent="right"
            alignItems="right"
          >
            <Button variant="outlined" size="small" onClick={handleModalClose}>
              {t("actions.annulla")}
            </Button>
            <Button
              variant="contained"
              size="small"
              color="error"
              onClick={handleModalOk}
              disabled = {isConfirmDisabled}
            >
              {t("actions.positiveDelete")}
            </Button>
          </Stack>
        </Box>
      </Modal>
    </Fragment>
  );
};
