import React from "react";

//Vapor Components
import CircularProgress from "@vapor/react-material/CircularProgress";

//MUI Components
import { Backdrop } from "@mui/material";

export const Loading = ({ open }) => {
  return (
    <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
      <CircularProgress />
    </Backdrop>
  );
};
