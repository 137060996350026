import { Link } from "react-router-dom";
import { useTranslation } from "@onefront/react-sdk";

//Vapor Components
import List from "@vapor/react-material/List";
import ListItem from "@vapor/react-material/ListItem";
import ListItemIcon from "@vapor/react-material/ListItemIcon";
import ListItemText from "@vapor/react-material/ListItemText";
import Box from "@vapor/react-material/Box";
import Tooltip from "@vapor/react-material/Tooltip";
import Divider from "@vapor/react-material/Divider";

//Icons
import {
  ViewListOutlined as TabelleBaseIcon,
  GroupOutlined as AnagraficheIcon,
  ArticleOutlined as LogIcon,
  QuestionMark as QuestionMarkIcon,
  NewReleases as ReleaseIcon
} from "@mui/icons-material";

export const NavContent = ({ basePathTab, basePathUte, basePathAud, basePathRel }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ width: 250 }} role="presentation">
      <Divider />
      <List>
        <ListItem button component={Link} to={basePathTab}>
          <ListItemIcon>
            <Tooltip title={t("nav.tabelleBase")} placement="right" arrow>
              <TabelleBaseIcon />
            </Tooltip>
          </ListItemIcon>
          <ListItemText primary={t("nav.tabelleBase")} />
        </ListItem>
        <ListItem button component={Link} to={basePathUte} sx={{ mt: 0.7 }}>
          <ListItemIcon>
            <Tooltip title={t("nav.utenti")} placement="right" arrow>
              <AnagraficheIcon />
            </Tooltip>
          </ListItemIcon>
          <ListItemText primary={t("nav.utenti")} />
        </ListItem>
        <ListItem button component={Link} to={basePathAud} sx={{ mt: 0.7 }}>
          <ListItemIcon>
            <Tooltip title={t("nav.audit")} placement="right" arrow>
              <LogIcon />
            </Tooltip>
          </ListItemIcon>
          <ListItemText primary={t("nav.audit")} />
        </ListItem>
        <ListItem button component={Link} to={basePathRel} sx={{ mt: 0.7 }}>
          <ListItemIcon>
            <Tooltip title="Note di rilascio" placement="right" arrow>
              <ReleaseIcon />
            </Tooltip>
          </ListItemIcon>
          <ListItemText primary="Note di rilascio" />
        </ListItem>
      </List>
    </Box>
  );
};
